import React,{useState, useEffect} from "react";
import "./selectedPageView.css"
import { Header,Footer } from "../shared-components";
import ProductCard from "../shared-components/productCard/productCard";
import {useParams} from "react-router-dom"
import {carouselProductList, cartItemsCount,pagesList,categoryData} from "../../utils/apiUrlsSetup"
import Loader from "../shared-components/loader/loader";
import CategoryHeaderList from "../shared-components/categoryHeaderList/categoryHeaderList";

const SelectedPageView = () => {
    const lang = localStorage.getItem('language')??"en"
    
    const pincode = localStorage.getItem("pincode") || "";
    const {id} = useParams()
    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {} ;

    const [productsList, setProductsList] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const [badgeCount, setBadgeCount] = useState(0)
    const [badgePrice, setBadgePrice] = useState(0)

    useEffect(()=>{
        if(id){
            getCarouselProductList()
            window.scrollTo(0, 0)
        }
    },[])

    const getCartItemsCount = async() =>{
        const res = await cartItemsCount({})
        if(res.ErrorCode === 0){
            setBadgeCount(res?.Data?.total_count)
            setBadgePrice(res?.Data?.total_price)      
        }
    }

    const getCarouselProductList = async() =>{
        setShowLoader(true)
        const formData = new FormData()
        formData.append("slug", id)
        formData.append("language", lang)
        formData.append("pincode", pincode)
        const data = await pagesList(formData)
        if(data.ErrorCode === 0){

            setShowLoader(false)
            setProductsList(data.Response.List)
        }
    }
    return(
        <>
            {showLoader ? <Loader right="50%" bottom="47%" /> : null}
            <Header userDetails={userDetails} badgeCount={badgeCount} />

            <div className="container px-3 mb-5 pb-3 mb-lg-0 pb-lg-0">
                <div className="row m-0 pageview px-2 px-lg-0">
                    <div className="col-0 col-lg-1 col-sm-12"></div>
                    <div className="col-0 col-lg-10 col-sm-12 px-1 py-4 p-lg-4 mb-4 rounded">
                    {productsList ? 
                        <div className={`row m-0 d-flex ps-1 ms-1 `}>
                            <h1 className="text-dark fw-bold text-center mb-3 page-title">{productsList.name}</h1>
                            <div dangerouslySetInnerHTML={{ __html: productsList.content }} class="font_paragraph "/>
                        </div>

                        :
                        <div className="row m-0 d-flex justify-content-center align-items-center empty_data_row">
                            <p className="font_500 text-secondary text-center p-0">No Page Found</p>
                        </div>
                    }
                    </div>
                    <div className="col-0 col-lg-1 col-sm-12"></div>
                </div>
                <Footer />
            </div>
            <style jsx>
            {`
            
            .pageview{
                margin-top: 180px !important;
                box
            }

            `}
        </style>
        </>
    )
}

export default SelectedPageView ;
