import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom"
import { Header,        
    ProductSlider
} from "../shared-components";
import { useNavigate } from "react-router-dom";
import UseOutsideClick from "../shared-components/detectOutsideClick/useOutSideClick"
import "./productCategoryWise.css"
import Footer from "../shared-components/footer/footer";
import productImage from "../../assests/product_img.png";
import {
    getSubCategory,
    categoryData,
    categoryProductList,
    cartItemsCount,
    tempCartItemsCount,
    getTrendingProducts
} from "../../utils/apiUrlsSetup";
import Loader from "../shared-components/loader/loader";
import LeftSubCategories from "../shared-components/leftSubCategories/LeftSubCategories"
import CategoryHeader from "../shared-components/categoryHeader/categoryHeader";
import ProductCard from "../shared-components/productCard/productCard";
import ProductDescriptionBox from "../shared-components/productDescriptionBox/productDescriptionBox"

const ProductCategoryWise = () => {
    
    const [similarItems, setSimilarItems] = useState([])
    const [trendingItems, setTrendingItems] = useState([])
    const { id,Id } = useParams();
    const navigate = useNavigate() ;
    const ref = useRef();
    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {}
    const authToken = localStorage.getItem("authToken") || "" ;
    const [cartSession, setCartSession] = useState( localStorage.getItem("cart-session") || "" )


    const [catProductList, setCatProductList] = useState([]);
    const [catBanner, setCatBanner] = useState("");
    const [subCategories, setSubCategories] = useState([]);
    const [categoryListData, setCategoryListData] = useState([]);
    const [activeSubCategory, setActiveSubCategory] = useState();
    const [moreData, setMoreData] = useState([]);
    const [subHeaderData, setSubHeaderData] = useState([]);
    const [openSelect, setOpenSelect] = useState(false)

    const [categoryId, setCategoryId] = useState()
    const [badgeCount, setBadgeCount] = useState(0)
    const [badgePrice, setBadgePrice] = useState(0)
    const [showLoader, setShowLoader] = useState(false)
    const pincode = localStorage.getItem("pincode") || '' ;

    UseOutsideClick(ref, () => {
        if (openSelect) {
            setOpenSelect(false)
        }
    });

    useEffect(() => {
        getCategoryData()
        window.scrollTo(0,0)
    }, [])

    useEffect(() => {
        if (categoryId) {
            getSubCategoryData(categoryId)
        }
    }, [categoryId])

    useEffect(() => {
    //    setCategoryId(Number(id));
    setCategoryId(id);
    }, [])

    const getCartItemsCount = async() =>{
        if(authToken)
        {
            const res = await cartItemsCount({})
            if(res.ErrorCode === 0){
                
                // getCategoryProductList(subCatId)
                setBadgeCount(res?.Data?.total_count)
                setBadgePrice(res?.Data?.total_price)
            }

        }
        else if(cartSession)
        {
            const res = await tempCartItemsCount({})
            if(res.ErrorCode === 0){
                setBadgeCount(res?.Data?.total_count)
                setBadgePrice(res?.Data?.total_price)
            }
              }





    }

    const getCategoryProductList =  async(id) => {
        setShowLoader(true)
        navigate(`/product-category/${categoryId}/${id}`)
        const obj = {
            category_id: id,
        }
        obj.pincode = pincode

        if(!authToken){
            obj.pincode = pincode
        } 

        let response = await categoryProductList(obj)
        setShowLoader(false)
        if (response && response.ErrorCode === 0) {
            setCatProductList(response.ItemResponse.category_products)
            setCatBanner(response.ItemResponse.category_banner)            
        }
        getTrendingFeaturedProducts(obj)
        getSimilarProducts(obj)
    }


    const getSubCategoryData = async (categoryId) => {
        let obj = {
            parent_id: categoryId
        }
        const response = await getSubCategory(obj);
        if (response && response.ErrorCode === 0) {
            setSubCategories(response.Response);
            // setActiveSubCategory(isFinite(Id) ? Id : (response.Response.length && response.Response[0].id))
            // getCategoryProductList(isFinite(Id) ? Id : (response.Response.length && response.Response[0].id))
            
            
            // setActiveSubCategory(response.Response.length && response.Response[0].id)
            // getCategoryProductList(response.Response.length && response.Response[0].id)
            
            
            // console.log(localStorage.getItem('subCategory'));
            // console.log(response.Response.length && response.Response[0].category_slug);

            let catNo = Id!=':id' ? Id : (response.Response.length && response.Response[0].category_slug)
            if(response.Response.find(p => p?.category_slug== Id) ===undefined)
            {
                catNo = (response.Response.length && response.Response[0].category_slug)
            }
            //catNo = Id!=':id' && response.Response.filter((p)=> p?.category_slug!= Id) ? (response.Response.length && response.Response[0].category_slug) : Id
            getCategoryProductList(catNo);
            setActiveSubCategory(catNo);
          

        }
    }

    const getCategoryData = async () => {
        let response = await categoryData({"pincode":pincode})
        if ( response && response.ErrorCode === 0) {
            setCategoryListData(response.Response.category)
        }
        const copyCategoryListData = [...response.Response.category]
        const moreData = copyCategoryListData.splice(99, 100)
        setSubHeaderData(copyCategoryListData)
        setMoreData(moreData)
    }

    const updateCategoryProductList = (subCategoryId) => {
        setActiveSubCategory(subCategoryId);
        getCategoryProductList(subCategoryId);
        localStorage.setItem('subCategory', subCategoryId)
    }

    const getSimilarProducts = async (copyData) => {
        setShowLoader(true)
    
        const obj = {
          category_id : copyData.category_id,
          pincode : pincode
        }
    
        let response = await categoryProductList(obj)
        setShowLoader(false)
        if (response && response.ErrorCode === 0) {
            setSimilarItems(
              [
                {
                  type : "product_carousel",
                  carousel_name: "You may also like",
                  items : response.ItemResponse.category_products.filter((p)=> p?.product_id!= id)
                }
              ]
            )
        }
      }

      const getTrendingFeaturedProducts = async (copyData) => {
        setShowLoader(true)
    
        const obj = {
        //   pincode : pincode
        }
    
        let response = await getTrendingProducts(obj)
        setShowLoader(false)
        if (response && response.ErrorCode === 0) {
            setTrendingItems(
              [
                {
                  type : "product_carousel",
                  carousel_name: "Trending Products",
                  items : response?.Response
                }
              ]
            )
        }
      }
    
    return <>
        {showLoader ? <Loader bottom="50%" right="37%" /> : ''}
        <Header 
            noShadow
            noHeaderCategory
            userDetails={userDetails}
            badgeCount={badgeCount}
            badgePrice={badgePrice}
        />
        <CategoryHeader
            categoryHeaderData={subHeaderData}
            moreData={moreData}
            setCategoryId={setCategoryId}
            categoryId={categoryId}
        />


        <div className="row m-0 product_cat_wise_main">
            <div className="col-lg-1 col-0"></div>
            <div className="col-12 col-lg-10 border border-0 px-3 p-lg-0">
                <div className=" row m-0 mt-3">
                    <div className="col-12 col-lg-3 side_bar_scroller p-0">
                        <LeftSubCategories
                            activeSubCategory={activeSubCategory}
                            subCategories={subCategories}
                            updateCategoryProductList={updateCategoryProductList}
                        />

                    </div>
                    <div className="col-12 col-lg-9 p-0">
                        {catBanner!=""?
                        
                            <div className="row m-0">
                                <img
                                    src={catBanner ? catBanner : ""}
                                    alt="product"
                                    loading="lazy"
                                    className="block w-full cat-banner"
                                    />
                            </div>
                        :null
                        }
                        <div className="cat_container_main row m-0">
                            <ProductCard 
                                catProductList={catProductList}
                                setCatProductList={setCatProductList}
                                getCartItemsCount={getCartItemsCount} 
                            />
                        </div>
                    </div>
                </div>

                <div className="row m-0 my-2">
                <ProductSlider 
                listData={trendingItems} 
                setListData={setTrendingItems}  
                updateListData={getTrendingFeaturedProducts} 
                getCartItemsCount={getCartItemsCount}
                />
                </div>
                <div className="row m-0 my-2">
                <ProductSlider 
                listData={similarItems} 
                setListData={setSimilarItems}  
                updateListData={getSimilarProducts} 
                getCartItemsCount={getCartItemsCount}
                />
                </div>
                


            </div>
        </div>
        <div class="containers__Divider-sc-l8pmj8-1 bzZIsM"></div>
        <Footer />
        {/* <div className="px-4 mb-5 pb-2">
        </div> */}
    </>
}

export default ProductCategoryWise