import React from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useTranslation } from "react-i18next";
const EditQuantityButton = ({ decrement, increment, productData, inputValue, setInputValue, disableQtyButton }) => {
    // console.log(disableQtyButton,  "disableQtyButton")
    const { t, i18n } = useTranslation();
    return (
        <>
            <button
                type="button"
                className="bg_green w-8/12 h-10 d-flex justify-content-between align-items-center text-white font-semibold py-1 px-3 card_add_btn rounded-lg"
                >
                <span
                    className="pe-1"
                    onClick={() => { decrement(productData) }} 
                >
                    &minus;
                </span>

                <Popup
                    className="search-popup"
                    trigger={
                        <button className="text-primary  cursor-pointer text-white text-lg font_basic">
                            {productData ? productData.quantity : 0}
                        </button>
                    }
                    modal
                >
                    {(close) => (
                        <div className="modal d-flex position-relative w-100">
                            <div className="border-b mx-2 py-1 position-absolute font-bold text-base border-black flex justify-between items-center w-100 px-3 font_basic">
                                {t("EntertheQuantityhere")}{" "}
                                <button
                                    className="close cursor-pointer  text-2xl"
                                    onClick={close}
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="content mx-2 my-2">
                                <div className="mt-4">
                                    <input
                                        min="0"
                                        max={productData.current_stock}
                                        type="number"
                                        className="phone font_paragraph popup_quantity_input border bg-white rounded-full h-10 mb-2 mt-4 shadow-primary placeholder-black placeholder-opacity-40 text-xs focus:outline-none p-4"
                                        placeholder="Quantity"
                                        value={inputValue}
                                        onChange={(e) =>
                                            setInputValue(Number(e?.target?.value))
                                        }
                                    />

                                </div>

                                <button
                                    className=" rounded-full bg-primary text-md h-10 mt-4 mb-1 text-white w-full font_basic"
                                    onClick={() => {
                                        if (inputValue) {
                                            // console.log(productData)
                                            increment(productData);
                                            setInputValue('')
                                        }
                                        close();

                                    }

                                    }
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    )}
                </Popup>

                <span
                    className="ps-1"
                    onClick={() => { increment(productData) }}
                >
                    &#10011;
                </span>
            </button>
        </>
    )
}

export default EditQuantityButton;