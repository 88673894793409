import React from "react";
import Header from "../shared-components/header/header"
import Footer from "../shared-components/footer/footer"
import { useTranslation } from "react-i18next";
const PrivacyPolicy = () => {
    const { t, i18n } = useTranslation();
    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};

    return <>
        <Header
            userDetails={userDetails}
        />
        <div className="row m-0 privacy_policy_page">
            <div className="col-0 col-md-1"></div>
            <div className="col-12 col-md-10">
                <div class=" my-0">
                    <p ><strong>{t("PRIVACYPOLICY")} </strong></p>
                    <p>
                      {t("PP1")}</p><p>
                       {t("PP2")}</p><p>
                        &nbsp;
                    </p>
                    <p>
                        <strong>{t("Note")}:</strong>
                    </p>
                    <p>
                        &nbsp;
                    </p>
                    <p>
                       {t("PP3")}
                    </p>
                    <p>
                       {t("PP4")}
                    </p>
                    <ol className="p-0">
                        <li>
                            <strong>{t("PP5")}</strong>
                        </li></ol><p>
                       {t("PP6")}
                    </p>
                    <p>
                       {t("PP7")}
                    </p>
                    <p>
                       {t("PP8")}
                    </p>
                    <p>
                        {t("PP9")}
                    </p>
                    <p>
                    {t("PP10")}
                    </p>
                    <p>
                       {t("PP11")}
                    </p>
                    <p>
                       {t("PP12")}
                    </p>
                    <p>
                       {t("PP13")}
                    </p>
                    <p>
                       {t("PP14")}
                    </p>
                    <p>
                    {t("PP15")}
                    </p>
                    <p>
                       {t("PP16")}
                    </p>
                    <ol aria-start="2" className="p-0">
                        <li>
                            <strong> {t("PP17")}</strong>
                        </li></ol><p>
                       {t("PP18")}
                    </p>
                    <p>
                    {t("PP19")}
                    </p>
                    <p>
                    {t("PP20")}
                    </p>
                    <p>
                    {t("PP21")}
                       
                    </p>
                    <p>
                    {t("Cookies")}
                       
                    </p>
                    <p>
                        &nbsp;
                    </p>
                    <p>
                    {t("PP22")}
                       
                    </p>
                    <ol aria-start="3" className="p-0">
                        <li>
                            <strong>{t("PP23")}</strong>
                        </li></ol><p>
                        {t("PP24")}
                       
                    </p>
                    <p>
                    {t("PP25")}    
                    </p>
                    <p>
                    {t("PP26")}
                       
                    </p>
                    <p>
                    {t("PP27")}
                       
                    </p>
                    <ol aria-start="4" className="p-0">
                        <li>
                            <strong> {t("PP28")}</strong>
                        </li></ol><p>
                        {t("PP29")}
                       
                    </p>
                    <ol aria-start="5" className="p-0">
                        <li>
                            <strong>{t("PP30")}</strong>
                        </li></ol><p>
                        {t("PP31")}
                       
                    </p>
                    <ol aria-start="6" className="p-0">
                        <li>
                            <strong> {t("PP32")}</strong>
                        </li></ol><p>
                        {t("PP33")}
                       </p>
                    <p>
                    {t("PP34")}
                       
                    </p>
                    <ol aria-start="7" className="p-0">
                        <li>
                            <strong>   {t("PP35")}</strong>
                        </li></ol><p>
                        {t("PP36")}
                       
                    </p>
                    <ol aria-start="8" className="p-0">
                        <li>
                            <strong>  {t("PP37")}</strong>
                        </li></ol><p>
                        {t("PP38")}
                       
                    </p>
                    <p>
                    {t("PP39")}
                       
                    </p>
                    <ol aria-start="9" className="p-0">
                        <li>
                            <strong>  {t("PP40")}</strong>
                        </li></ol><p>
                        {t("PP41")}
                       
                    </p>
                </div>
            </div>
            <div className="col-0 col-md-1"></div>
        </div>
        <Footer />

        <style jsx>
            {`
        
        .privacy_policy_page{
            margin-top: 120px !important;
        }

        `}
        </style>
    </>

}

export default PrivacyPolicy;