 import React, { useEffect } from 'react';

const GoogleMap = () => {
  useEffect(() => {
    
    loadGoogleMap();
  }, []);

  const loadGoogleMap = () => {
    const map = new window.google.maps.Map(document.getElementById('map'), {
        center:  { lat: -9.1597343, lng: 13.8285594},
      zoom: 14,
    });

    const marker = new window.google.maps.Marker({
      position:  { lat: -9.1597343, lng: 13.8285594}, 
      map: map,
      title: "Centro De Logistica E Distribuicao, KM-27, Estrada de Catete,Viana Luanda-Angola.",
    });
  };

  return <div id="map" style={{ height: '400px', width: '100%' }}></div>;
};

export default GoogleMap;
