// // import React from "react";
// // import enflag from './assests/EN.png';
// // import mail from './assests/email.png'
// // import { useTranslation } from "react-i18next";

// // const LanguageSelector = () => {

// //   const { t, i18n } = useTranslation();
// // const lang = localStorage.getItem('language')??"en"

// //   const changeLanguage = (event) => {
// //       i18n.changeLanguage(event.target.value);
// //       localStorage.setItem('language',event.target.value)
// //       window.location.reload()
// //   };
// //   // const flagStyles = {
// //   //   backgroundImage: `url(${enflag})`,
// //   //   backgroundSize: "contain",
// //   //   backgroundRepeat: "no-repeat",
// //   // };

// //   // const flagStylesPt = {
// //   //   backgroundImage: `url(${mail})`,
// //   //   backgroundSize: "contain",
// //   //   backgroundRepeat: "no-repeat",
// //   // };
 

// //   return (
// //     <div className="language-picker">
// //       <form className="language-picker__form">
// //         <select
// //           onChange={changeLanguage}
// //           value={lang}
// //           className="language-picker__dropdown"
// //           style={{
// //             height: "60px",
// //             width: "120px",}}
// //         >
// //           <option
// //     value="en"
// //     className="flag-option en"
   
// //     data-content="EN"
 
// //   >
// //       EN
// //   </option>
// //   {/* <i class="bi bi-flag-fill"></i>  */}
// //   <img src={enflag} alt="flag"/>
// //   <option
// //     value="pt-BR"
// //     className="flag-option pt"
// //     data-content="PT"
// //   >
// //     Pt
// //   </option>
// //   <img src={enflag} alt="flag"/>
// // </select>

// //       </form>
      
// //     </div>
// //   );
  
// // };

// // <style jsx>{`
// // .flag-option {
// //   padding-left: 30px;
// //   background-repeat: no-repeat;
// //   background-position: 10px 50%;
// // }

// // .flag-option.en::before {
// //   content: '';
// //   display: inline-block;
// //   width: 20px;
// //   height: 20px;
// //   background-image: url('./assests/EN.png'); // Adjust the path to your EN flag image
// //   background-size: contain;
// //   margin-right: 0px;
// // }

// // .flag-option.pt::before {
// //   content: '';
// //   display: inline-block;
// //   width: 20px;
// //   height: 20px;
// //   background-image: url('./assests/email.png'); 
// //   background-size: contain;
// //   margin-right: 0px;
// // }

// // `}</style>

// // export default LanguageSelector;
// import React from "react";
// import Select from "react-select";
// import { useTranslation } from "react-i18next";
// import enflag from './assests/EN.png';

// const options = [
//   { value: "en", label: "en", icon: enflag },
//   { value: "pt-BR", label: "pt-br", icon: enflag },
// ];

// const LanguageSelector = () => {
//   const { t, i18n } = useTranslation();
//   const lang = localStorage.getItem('language') ?? "en";

//   const changeLanguage = (selectedOption) => {
//     i18n.changeLanguage(selectedOption.value);
//     localStorage.setItem('language', selectedOption.value);
//   };

//   const customStyles = {
//     control: (provided) => ({
//       ...provided,
//       width: 72, 
//       marginLeft:"0px"
      
//     }),
//     option: (provided, state) => ({
//       ...provided,
//       background: `url(${state.data.icon}) no-repeat 5px center`,
//       backgroundSize: "20px 20px",
//       paddingLeft: "32px", 
//     }),
//     singleValue: (provided) => ({
//       ...provided,
//       display: "flex",
//       alignItems: "center",
//     }),
//   };

//   return (
//     <div className="language-picker">
//       <Select
//         options={options}
//         value={options.find((option) => option.value === lang)}
//         onChange={changeLanguage}
//         styles={customStyles}
//       />
//     </div>
//   );
// };

// export default LanguageSelector;

// third method with react -select



// import React, { useState, useRef } from "react";
// import Select from "react-select";
// import Modal from "react-modal";
// import { MdOutlineLanguage } from "react-icons/md";
// import { useTranslation } from "react-i18next";
// import enflag from './assests/EN.png';

// const options = [
//   { value: "en", label: "English", icon: enflag },
//   { value: "pt-BR", label: "Portuguese", icon: enflag },
// ];

// const LanguageSelector = () => {
//   const { t, i18n } = useTranslation();
//   const lang = localStorage.getItem('language') ?? "en";
//   const buttonRef = useRef(null);

//   const [modalIsOpen, setModalIsOpen] = useState(false);

//   const openModal = () => {
//     setModalIsOpen(true);
//   };

//   const closeModal = () => {
//     setModalIsOpen(false);
//   };

//   const changeLanguage = (selectedOption) => {
//     i18n.changeLanguage(selectedOption.value);
//     localStorage.setItem('language', selectedOption.value);
//     closeModal();
//   };

//   const customStyles = {
//     content: {
//       width: '200px',
//       height: '150px',
//       top: 'calc(40% - 75px)', // Adjust this value to move the modal up or down
//       left: buttonRef.current ? buttonRef.current.getBoundingClientRect().left + window.scrollX : 'auto',
//       zIndex: 9999, // Set a higher zIndex
//     },
//     control: (provided) => ({
//       ...provided,
//       width: '100%',
//     }),
//     option: (provided, state) => ({
//       ...provided,
//       background: `url(${state.data.icon}) no-repeat 5px center`,
//       backgroundSize: "20px 20px",
//       paddingLeft: "32px",
//       display: 'flex',
//       alignItems: 'center',
//     }),
//     singleValue: (provided) => ({
//       ...provided,
//       display: "flex",
//       alignItems: "center",
//     }),
//   };

//   return (
//     <div className="language-picker">
//       <button ref={buttonRef} onClick={openModal}>
//         <MdOutlineLanguage />
//       </button>
//       <Modal
//         isOpen={modalIsOpen}
//         onRequestClose={closeModal}
//         contentLabel="Language Selector"
//         style={customStyles}
//       >
//         <h5>Select Language</h5>
//         <Select
//           options={options.map((option) => ({
//             ...option,
//             label: `${option.label} (${t(option.value)})`,
//           }))}
//           value={options.find((option) => option.value === lang)}
//           onChange={changeLanguage}
//           styles={customStyles}
//         />
//         <button onClick={closeModal}>Close</button>
//       </Modal>
//     </div>
//   );
// };

// export default LanguageSelector;
// import React, { useState, useRef } from "react";
// import Modal from "react-modal";
// import { MdOutlineLanguage } from "react-icons/md";
// import { useTranslation } from "react-i18next";
// import enflag from './assests/EN.png';
// import ptflag from './assests/portuguese.png';

// const languages = [
//   { value: "en", label: "English", icon: enflag },
//   { value: "pt-BR", label: "Portuguese", icon: ptflag},
// ];

// const LanguageSelector = () => {
//   const { t, i18n } = useTranslation();
//   const lang = localStorage.getItem('language') ?? "en";
//   const buttonRef = useRef(null);

//   const [modalIsOpen, setModalIsOpen] = useState(false);

//   const openModal = () => {
//     setModalIsOpen(true);
//   };

//   const closeModal = () => {
//     setModalIsOpen(false);
//   };

//   const changeLanguage = (selectedLanguage) => {
//     i18n.changeLanguage(selectedLanguage.value);
//     localStorage.setItem('language', selectedLanguage.value);
//     closeModal();
//   };

//   return (
//     <div className="language-picker">
//       <button ref={buttonRef} onClick={openModal}>
//         <MdOutlineLanguage />
//       </button>
//       <Modal
//         isOpen={modalIsOpen}
//         onRequestClose={closeModal}
//         contentLabel="Language Selector"
//         style={{
//           content: {
//             width: '200px',
//             height: '150px',
//             top: 'calc(45% - 75px)',
//             left: buttonRef.current ? buttonRef.current.getBoundingClientRect().left + window.scrollX : 'auto',
//             zIndex: 9999,
//           },
//         }}
//       >
//         <h5>Select Language</h5>
//         <div className="language-options">
//           {languages.map((language) => (
//             <div
//               key={language.value}
//               className={`language-option ${lang === language.value ? 'selected' : ''}`}
//               onClick={() => changeLanguage(language)}
//             >
//               <img src={language.icon} alt={language.label} className="flag-icon" />
//               <span className="language-name">{`${language.label} (${t(language.value)})`}</span>
//             </div>
//           ))}
//         </div>
//         <button onClick={closeModal}>Close</button>
//       </Modal>
//       <style jsx>
//             {`
//         .language-options {
//           display: flex;
//           flex-direction: column;
//         }
        
//         .language-option {
//           display: flex;
//           align-items: center;
//           margin-bottom: 10px;
//           cursor: pointer;
//         }
        
//         .flag-icon {
//           width: 20px; 
//           height: auto;
//           margin-right: 10px;
//         }
        
//         .language-name {
//           font-size: 94%; 
//         }
        
       

//         `}
//         </style>
//     </div>
//   );
// };
// export default LanguageSelector;

import React, { useState, useRef } from "react";
import Modal from "react-modal";
import { MdOutlineLanguage } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import enflag from './assests/EN.png';
import ptflag from './assests/portuguese.png';


const languages = [
  { value: "en", label: "English", icon: enflag },
  { value: "pt-BR", label: "Portuguese", icon: ptflag },
];

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem('language') ?? "en";
  const selectedLanguageFlag = localStorage.getItem('langFlag') ?? enflag;
  const buttonRef = useRef(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [flag, selectedflag]=useState("");
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const changeLanguage = (selectedLanguage) => {
    i18n.changeLanguage(selectedLanguage.value);
    localStorage.setItem('language', selectedLanguage.value);
    localStorage.setItem('langFlag', selectedLanguage.icon);
    selectedflag(selectedLanguage.value);
    closeModal();
    window.location.reload()
  };
  return (
    <div className="language-picker">
      <div className="globe">
      <button ref={buttonRef} onClick={openModal} className="language-button">
        <MdOutlineLanguage  className="globe-icon"/>
        <span className="language-name">
        <img src={selectedLanguageFlag} alt={lang} className="flag-icon" />
        </span> 
        {/* <span className="language-name">{lang}</span>  */}
      </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        portalClassName="language-modal-portal"
        contentLabel="Language Selector"
        style={{
          content: {
            width: '250px',
            height: '135px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // zIndex: 9999,
          },
        }}
      >
         <button onClick={closeModal} className="close"><MdClose /></button>
        <h5>Select Language</h5>
    
        <div className="language-options">
          {languages.map((language) => (
            <div
              key={language.value}
              className={`language-option ${lang === language.value ? 'selected' : ''}`}
              onClick={() => changeLanguage(language)}
            >
              <img src={language.icon} alt={language.label} className="flag-icon1" />
              <span className="language-name">{`${language.label} (${t(language.value)})`}</span>
            </div>
          ))}
        </div>
       
      </Modal>
      <style jsx>
        {`
          .language-options {
            display: flex;
            font-size:300px;
            flex-direction: column;
            // padding-top:2px;
            // padding-bottom:2px;
          }
          .language-modal-portal {
            position:relative;
            z-index: 9999 !important;
          }
          .close{
              float:right;
              }
              .language-option:hover{
                background-color:#eee;
              }
             
          .language-option {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            cursor: pointer;
          }

          .flag-icon {
            width: 20px;
            height: auto;

            margin-right: 10px;
          }
          .flag-icon1 {
            width: 20px;
            height: auto;
            margin-right: 10px;
          }
          .language-name {
            font-size: 14px;
            float:left;
            display:inline;
            padding:1.5px 0 0 5px;
          }
          
          
         .globe-icon{
              margin-left: 6px;
              display: inline;
              align-items: center;
              float:left;
          }
          @media (max-width: 600px) {
            .language-picker Modal {
              content: {
                top: '5%',
                transform: 'translate(-50%, -50%)',
              }
              
            }
            .language-button{
              display:flex;
              margin-bottom:4px;
              margin-top:90px;
              height:10px;
            }
            .globe-icon{
              margin-left: 1px;
              display: none;
              align-items: center;
              float:left;
              width:22px;
              margin-bottom:38px;
          }
          .flag-icon {
            width: 25px;
            height: auto;
            // margin-bottom:50px;
            position:relative;
            bottom :23px;
            margin-right: 8px;
           display:inline;
          }
          .flag-icon1 {
            width: 20px;
            height: auto;
            margin-right: 10px;
           display:inline;
          }
          }
          @media (max-width: 900px) {
            .language-picker Modal {
              content: {
                top: '2%',
              }
            }
          }
        
        `}
      </style>
    </div>
  );
};

export default LanguageSelector;
