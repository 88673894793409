import React,{useState, useEffect} from 'react';
import Carousel from 'react-multi-carousel';
import "./singleSlider.css"
import {fetchSliderImages} from '../../../utils/apiUrlsSetup'
import Default from "../../../assests/slide_img.jpg"
const SingleSlider = () => {

    const authToken = localStorage.getItem("authToken") || "" ;
    const pincode = localStorage.getItem("pincode") || "";

    const [storeInstruction, setStoreInstruction] = useState([]);
    const [sliderImages, setSliderImages] = useState([])

    useEffect(()=>{
        if(authToken){
            getSliderImages()
        }
        else
        {
            getSliderImages()
        }
    },[])

    const getSliderImages = async() =>{
        const data = await fetchSliderImages({"banner_location":"slider","pincode":pincode})
            if(data.ErrorCode === 0){
                let imgs = [] ; 
                if(authToken){
                    setStoreInstruction(data?.Response?.delivery_instruction);
                    // data?.Response?.store_banner
                    data?.Response?.home_banner.map((item, index) => {
                        window.navigator.userAgent.toLowerCase().includes("mobi")
                        ? imgs.push({ url: item["mobile_banner"],hyperlink: item["hyperlink"] })
                        : imgs.push({ url: item["desktop_banner"],hyperlink: item["hyperlink"] });
                    });
                    setSliderImages(imgs);
                }
                else{
                    data.Response.home_banner.map((value, index)=>{
                        window.navigator.userAgent.toLowerCase().includes("mobi")
                        ? imgs.push({ url: value["mobile_banner"],hyperlink: value["hyperlink"] })
                        : imgs.push({ url: value["desktop_banner"],hyperlink: value["hyperlink"] });   
                    })
                    setSliderImages(imgs)
                }
            }
        
        }
    const responsive = {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024
            },
            items: 1
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 0
            },
            items: 1
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 464
            },
            items: 1
        }
    }

    return (
        <>
            <div className={`singleSlider ${sliderImages.length && "mt-5 mb-2" }`}>
                <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlay
                    autoPlaySpeed={4000}
                    centerMode={false}
                    className=""
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={responsive}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >
                    {sliderImages && sliderImages ? sliderImages.map((image, index)=>{
                        return <a href={image.hyperlink}><img
                        src={image.url ? image.url : Default}
                        key={index}
                    /></a>
                    }) : null}
                    
                </Carousel>
            </div>
        </>
    )
}

export default SingleSlider;