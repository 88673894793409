import React from "react";
import Header from "../shared-components/header/header"
import Footer from "../shared-components/footer/footer"
import { useTranslation } from "react-i18next";
const AboutUs = () => {
    
    const { t, i18n } = useTranslation();
    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {} ;

    return <>
        <Header
            userDetails={userDetails}
        />
        <div className="row m-0 about_us_page">
            <div className="col-0 col-md-1"></div>
            <div className="col-12 col-md-10">

        <div class="">
            <h1 class="font-bold text-2xl mb-10">
               {t("about")} <span class="text-primary">{t("abt")}</span>
            </h1>
            <p>
            {t("abt1")}  
            </p>
            <p class="mt-5 mb-8">
            {t("abt2")} 
            </p>
            <h1 class="font-bold text-2xl mb-10"> {t("abt3")} <span class="text-primary"> {t("abt4")}</span></h1>
            <p> {t("abt5")}
            </p>
            <p class="mt-5 mb-8">
            {t("abt6")}  
            </p>
            <h1 class="font-bold text-2xl mb-10"> {t("abt7")} <span class="text-primary"> {t("abt8")}</span></h1>
            <p> {t("abt9")}
            </p>
            <p class="mt-5 mb-8">
            {t("abt10")} 
            </p>
            <ul class="list-disc">
                <li>
                    <strong> {t("abt11")}
                        <span class="text-primary font-bold">
                        {t("abt12")}
                        </span>
                    </strong>
                </li>
                <li>
                    <strong>
                        39+
                        <span class="text-primary font-bold">
                        {t("abt13")} 
                        </span>
                    </strong>
                </li>
                <li>
                    <strong>
                        500+
                        <span class="text-primary font-bold">
                        {t("abt14")} 
                        </span>
                    </strong>
                </li>
            </ul>
            <h1 class="font-bold text-2xl mb-10 mt-10">
            {t("abt15")}  
                <span class="text-primary">
                {t("abt16")}  
                </span>
            </h1>
            <ul class="list-disc">
                <li>
                    <strong>
                    {t("abt17")} 
                    </strong>
                </li>
            </ul>
            <p> {t("abt18")}
            </p>
            <ul class="list-disc mt-4">
                <li>
                    <strong> {t("abt19")}</strong></li></ul><p> {t("abt20")}</p><ul class="list-disc mt-4"><li><strong> {t("abt21")}</strong></li></ul><p> {t("abt22")}  </p><ul class="list-disc mt-4"><li><strong> {t("abt23")}</strong></li></ul><p> {t("abt24")}</p><ul class="list-disc mt-4"><li><strong> {t("abt25")} &amp;  {t("abt26")}</strong></li></ul><p> {t("abt27")}</p></div>

            </div>
            <div className="col-0 col-md-1"></div>
        </div>
            <Footer />
    <style jsx>
        {`
        
        .about_us_page{
            margin-top: 120px !important;
            box
        }

        `}
    </style>
    </>
}

export default AboutUs;