import React from "react";
import Header from "../shared-components/header/header"
import Footer from "../shared-components/footer/footer"
import { useTranslation } from "react-i18next";

const ShippingPolicy = () => {
    const { t, i18n } = useTranslation("");
    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};

    return <>
        <Header
            userDetails={userDetails}
        />
        <div className="row m-0 mt-5 shipping_page">
            <div className="col-0 col-md-1"></div>
            <div className="col-12 col-md-10">
                <div class="">
                    <p>
                        <strong>{t("Shipping Policy")}</strong>
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>{t("shippingcharges")}</strong>
                    </p>
                    <p>
                        {t("nodel")}
                    </p>
                    <p>
                        <strong>{t("expectedtime")}</strong>
                    </p>
                    <p>
                     {t("sp1")}
                    </p>
                    <p>
                       {t("sp2")}
                    </p>
                    <p>
                        <strong> {t("sp3")}</strong>
                    </p>
                    <p>
                      {t("sp4")}
                    </p>
                    <p>
                        <strong> {t("sp5")}</strong>
                    </p>
                    <p>
                   {t("sp6")}
                    </p></div>
            </div>
            <div className="col-0 col-md-1"></div>
        </div>
        <Footer />

        <style jsx>
            {`
        
        .shipping_page{
            margin-top: 120px !important;
        }
        `}
        </style>
    </>

}

export default ShippingPolicy;