import Header from "../../components/shared-components/header/header"
import { Footer } from "../shared-components"
import "./addressPage.css"
import { useNavigate } from "react-router-dom"
import React, { useState, useEffect, useRef } from "react"
import AddressModal from "../shared-components/address/addressModal"
import { userAddressList, deleteUserAddress, editUserAddress } from "../../utils/apiUrlsSetup"
import UseOutsideClick from "../shared-components/detectOutsideClick/useOutSideClick"
import { toast } from "react-toastify"
import CommonSideTabs from "../shared-components/commonSideTabs/commonSideTabs"
import { useTranslation } from "react-i18next";
const AddressPage = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate() 
    const ref = useRef()
    const loginNumber = localStorage.getItem("loginNumber");
    let userDetails= JSON.parse(localStorage.getItem("userDetails")) || {} ;
    const authToken = localStorage.getItem("authToken") || "" ;

    useEffect(() => {
        window.scrollTo(0, 0);
        getUserAddressList()
    }, [])

    useEffect(()=>{
        if(authToken){
            userDetails= JSON.parse(localStorage.getItem("userDetails")) || {} ;
        }
    },[userDetails])


    const [openAddAddressModal, setOpenAddAddressModal] = useState(false)
    const [addressList, setAddressList] = useState([])
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    const [oldAddressData, setOldAddressData] = useState({})

    const [deleteId, setDeletedId] = useState("");

    const deleteAddressPopup = (index, value) => {
        setDeletedId(value.id);
        if (addressList[index].id === value.id) {
            setShowDeletePopup(true)
        }
    }

    UseOutsideClick(ref, () => {
        if (showDeletePopup) {
            setShowDeletePopup(false)
        }
    })

    const getUserAddressList = async () => {
        const response = await userAddressList({})
        if (response.ErrorCode === 0) {
            setAddressList(response.Response)
        }
    }

    const handleEditAddress = async(value)=>{
        setOldAddressData(value)
        setOpenAddAddressModal(true)
    }

    const deleteAddress = async (id) => {
        const obj = { address_id: id }
        const response = await deleteUserAddress(obj);
        if (response.ErrorCode === 0) {
            toast.success(response.Response)
            getUserAddressList()
        }
    }

    return (
        <>
            <div className="address_page">
                <Header 
                    noShadow 
                    userDetails={userDetails}
                />
                <div className="row m-0 px-3 p-lg-0">
                    <div className="col-2 p-0"></div>
                    <div className="col-12 col-lg-8 address_page_inner p-0">
                        <div className="row m-0">
                            <div className="col-3 p-0 left_addresss_bar d-none d-md-block">
                                <CommonSideTabs 
                                    activeTab={"address"} 
                                    userDetails={userDetails}
                                />
                            </div>
                            {addressList ?
                                <div className="col-12 col-md-9 d-flex">
                                    <div className="right_address_container w-100 p-md-3">
                                        <div className="address_list my-3 border">
                                            <div
                                                className="add_address_bar cursor-pointer d-flex align-items-center py-2 px-3 border-bottom"
                                                onClick={() => { setOpenAddAddressModal(true) }}
                                            >
                                                <i className="fa-regular fa-square-plus me-3 fs-5 font_500 text_green"></i>
                                                <p className="m-0 text_green fs-6 text-secondary font_basic">{t("AddNewAddress")}</p>
                                            </div>
                                            {(addressList || []).map((value, index) => {
                                                return <div className={`address p-3 ${index === addressList.length - 1 ? "" : "border-bottom"}`} key={index}>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                    <span className="d-flex">
                                                        <i className="fa-solid fa-location-dot me-3 fs-5 text_green"></i>
                                                        <p className="m-0 mb-1 font_basic">{value.address_type}</p>
                                                    </span>
                                                    <span className="text-success font_13 font_family_common">
                                                        {value.is_default && value.is_default === 1 ? "Default" : null}
                                                    </span>
                                                    </div>
                                                    <div className="full_address ms-4 ps-1 d-flex justify-content-between">
                                                        <p className="m-0 text-secondary pe-md-4 lh-sm font_paragraph">
                                                            {value.name}{" - "}{value.landmark}{" "}{value.address}{" "}{value.city ? value.city : ''}{" "}{value.pincode}{" India."}
                                                        </p>
                                                        <div className="full_address_func position-relative">
                                                            <span 
                                                                className="cursor-pointer mx-3 text-secondary font_13 font_family_common"
                                                                onClick={()=>{handleEditAddress(value)}}
                                                            >
                                                               {t("Edit")}
                                                            </span>
                                                           { addressList.length>1  &&<span
                                                                className="cursor-pointer  mx-2 font_13 font_family_common"
                                                                onClick={() => { deleteAddressPopup(index, value) }}
                                                            >
                                                                {t("Delete")}
                                                            </span>}
                                                            {showDeletePopup && deleteId === value.id ?
                                                                <div className="delete_popup p-3 bg-white shadow rounded position-absolute" ref={ref}>
                                                                    <p className="m-0 text-secondary fs-6">Are you sure want to delete this address?</p>
                                                                    <div className="delete_popup_footer d-flex justify-content-between">
                                                                        <button
                                                                            type="button"
                                                                            className="text-white bg_green px-3 my-2 rounded py-2"
                                                                            onClick={() => { deleteAddress(value.id) }}
                                                                        >
                                                                            {t("Delete")}
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className=" px-3 py-2 my-2 rounded border text-secondary"
                                                                            onClick={() => { setShowDeletePopup(false) }}
                                                                        >
                                                                            {t("Cancel")}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="col-9 d-flex justify-content-center align-items-center">
                                    <div className="right_address_container defalut">
                                        <img src="" alt="" />
                                        <p className="m-0 font_500 font_16 text-center">{t("address1")}</p>
                                        <p className="m-0 fs-6 text-secondary">{t("address2")}</p>
                                        <button
                                            type="button"
                                            className="d-block mx-auto my-2 add_address_btn"
                                            onClick={() => { setOpenAddAddressModal(true) }}
                                        >
                                          {t("AddNewAddress")}  
                                        </button>
                                    </div>
                                </div>}
                        </div>
                    </div>
                    <div className="col-2 p-0"></div>
                </div>

                <AddressModal clearUserAddress={() => setOldAddressData({})} getUserAddressList={getUserAddressList} openAddAddressModal={openAddAddressModal} setOpenAddAddressModal={setOpenAddAddressModal} oldAddressData={oldAddressData} />
                <div className="px-3">
                <Footer />
                </div>
            </div>
        </>
    )
}

export default AddressPage