import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nBackend from "i18next-http-backend";

i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    lng: localStorage.getItem('language')??"en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      "en": {
        translation: {
          Account:"Account",
          My :"My", 
          Save:" Save",
          MobileNo:" Mobile No.",
          Profile:"Profile",
          Orders:" Orders",
          Delete:"Delete",
          Wishlist:" Wishlist",
          SavedAddress:" Saved Address",
          OrderDetails:"Order Details",
          OrderID:"Order ID",
          Addresses:"Addresses",
          addressadd:"Click here to set pincode/address",
          address1:"You have no saved addresses",
          address2:"Tell us where you want your orders delivered",
          AddNewAddress:" Add New Address",
          didfind:" Didn't find your product click to suggest.",
          DeliveryCharges:"Delivery Charges",
          Discount:"Discount",
          Edit:" Edit",
          title: "Angocart",
          Title:"Title",
          contactUs: "Contact Us",
          searchPlaceholder: "Search Products by Name",
          about: "About",
          home: "Home",
          Office:"Office",
          usefulLink:"Useful Links",
          Categories:"Categories",
          SeeAll:"See All",
          DownloadApp:"Download App",
          Subscribe:"Subscribe to our channel and get the latest updates.",
          SelectDeliveryAddress:"Select Delivery Address",
          DeliveredOrders:"Delivered Orders",
          Subscribebtn:" Subscribe",
          scam:"Don't worry, we don't spam.",
          mail:"Enter your email",
           cart:"My Cart",
           delivery:"Delivery",
           Cancel:"Cancel",
           Items:"Items",
           EmptyCart:"Empty Cart",
           ADD:"ADD",
           OpenOrders:" Open Orders",
           needhelp:"Need help with your order ?",
           AddtoWishlist:"Add to Wishlist",
           Inclusiveofalltaxes:"Inclusive of all taxes",
           ProductFeatures:" Product Features",
           ProductSpecifications:"Product Specifications",
           ProductDescription:" Product Description",
           ProductDetails:"Product Details",
           buynow:" BUY NOW ",
           CartIsEmpty:" Cart Is Empty",
           Promocode:"Promo code / coupon",
           pleaseselect:"Please select items to place your order.",
           promoadded:"Promo code added",
           Coupon:"Coupon",
           Change:" Change",
           Proceed:" Proceed",
           Continue:"Continue",
           Name:" Name",
           ASSOCIATED:" OUR ASSOCIATED PARTNERS",
           Login:"Login",
           LogOut:"Log Out",
           Number:" Phone Number Verification",
           Enterlogin :"Enter your phone number to Login/Sign up",
           otp:"Enter 4 digit code sent to your phone",
           Next:"Next",
           var1:" By continuing you agree to our",
           var2:"terms of service",
           var3:"and",
           var4:"privacy policy",
           Resend:"Resend",
           off:"off",
           WhyAngocart :" Why Choose Angocart" ,
            h1:"Lightning-Fast Delivery",
            description1:"Get any product delivered to your doorstep with our fast delivery partners .",
            h2:"Lowest Prices & Best Offers",
          description2: "Genuine quality products at the most economical prices along with offers and reward points.",
          h3:"Extensive Range",
          description3:"Choose products from different kitchen appliance categories in a user-friendly interface.",
          h4: "Hassle-Free Return/Refund",
          description4: "In case of any issue, easy return or refund for a pleasant after-purchase experience.",
          Youmayalsolike:"You may also like",
          TrendingProducts:"Trending Products",
          GSTNo:"GST No.",
           wish1:"There is no product in Wishlist.",
           Thanks:"Thanks for visiting",
           confirm:"Please confirm if you want to",
           Send:" Send",
           Brand:"Brand",
           Quantity:" Quantity",
           Productname:"Product name",
           ViewCart:" View Cart",
           cashod:"Cash On Delivery",
           tpa:"You can pay in cash or by TPA",
           tamount:"Total Amount:",
           Mr:"Mr",
           Mrs:"Mrs",
           Miss:"Miss",
           add1:"Flat / House / Office No",
           add2:"Street / Society / Office Name",
           Pincode:"Pincode",
           Other:"  Other",
            newad:"Add New Delivery Address",
             newad3:"Location permissions is not provided",
             myloc:" Detect My Location",
             mypin:"Enter Delivery Pincode",
             per:" Location permissions is not provided",
             order1:"Order Summary",
             expectingdelivery:"Expecting Delivery at",
             rr:" Return & Replacement",
             CancelOrder:" Cancel Order",
             PaymentType:"Payment Type",
             OrderedAt:"Ordered At",
             DeliverTo:"Deliver To",
            newad2:"Please enter the accurate address, it will help us to serve you better",
            newad4:" Add Address",
            detect:" Detect My Location",
            itemorder:" Item in this order",
            Downloadsummary:"Download summary",
            qty:"qty",
            BillDetails:"Bill Details",
            BillTotal:"Bill Total",
            subtotal:"Sub Total (incl. taxes)",
            support:"Support is always available",
            chatsupport:"Chat with support executive",
           headtext:"Didn't find what you are looking for? Please suggest the product.",
           issuerelated:"About any issues related to your order",
           whycancel:"Why do you want to cancel this order?",
           ShippingPolicy:"Shipping Policy",
           shippingcharges:"What are the shipping charges?",
           query:" For any query please fill the below form.",
           phone:"Phone",
           writequery:"Write your Query",
           Submit:"Submit",
           thanksforcontacting:"Thank you for contacting us!",
           entername:"Enter your name",
           entermail:"Enter your email",
           enterphone:"Enter your phone",
           assist:"Someone will assist you shortly",
           expectedtime:"What is the estimated delivery time?",
           sp1:"Delivery is done within 3 hours on the same day for orders that are done before 3 pm.",
           sp2:" Orders after 3 pm are delivered the next day by 11 am.",
           sp3:"How will the delivery be done?",
           sp4:"  We process all deliveries through our own fleet of riders. In some cases, where your pin code is a little outside our primary delivery zone, we use the support of our delivery partners from Dunzo, Borzo, Porter etc.",
           sp5:"How are items packaged?",
           suggest:"Suggest something & we'll look into it.",
           suggest1:" Suggest a product",
           findproduct:"didn't find what you were looking for?",
           removecart:"Remove Cart Items ?",
           No:"No",
           Yes:"Yes",
           defaultaddress:"Make it default address.",
           cart1:"Your cart contains items from previous address. Do you want to discard the selection and use this address ?",
           sp6:"  All items are in their natural packaging. Items are sent to you in an organic, recycled paper bag that is able to withstand both, the temperature as well as the weight of the products.",
           contacttext:"AngoCart is Angola’s largest and most convenient hyper local delivery company which enables customers to order Grocery, Hygiene & Personal Cares, Beverages, Baby Care & many more products that customers need every day, directly via their mobile or web browser.",
          nodelivery:"We are not delivering in provided location.",
          ohno:"Oh No!",
          EACH:"EACH",
          Sizes:"Sizes",
          PRIVACYPOLICY:"PRIVACY POLICY",
          PP1:"  We value the trust you place in us. That`s why we insist upon the highest standards for secure transactions and customer information privacy. Please read the following statement to learn about our information gathering and dissemination practices.",
          PP2:" Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically.",
          PP3:" By visiting this Website you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree please do not use or access our Website.",
          Note:"Note",
          PP4:" By mere use of the Website, you expressly consent to our use and disclosure of your personal information in accordance with this Privacy Policy. This Privacy Policy is incorporated into and subject to the Terms of Use.",
          PP5:" COLLECTION OF PERSONALLY IDENTIFIABLE INFORMATION AND OTHER INFORMATION",
          PP6:" When you use our Website, we collect and store your personal information which is provided by you from time to time. Our primary goal in doing so is to provide you a safe, efficient, smooth and customized experience. This allows us to provide services and features that most likely meet your needs, and to customize our Website to make your experience safer and easier. More importantly, while doing so we collect personal information from you that we consider necessary for achieving this purpose.",
          PP7:" In general, you can browse the Website without telling us who you are or revealing any personal information about yourself. Once you give us your personal information, you are not anonymous to us. Where possible, we indicate which fields are required and which fields are optional. You always have the option to not provide information by choosing not to use a particular service or feature on the Website. We may automatically track certain information about you based upon your behaviour on our Website. We use this information to do internal research on our users`'` demographics, interests, and behaviour to better understand, protect and serve our users. This information is compiled and analysed on an aggregated basis. This information may include the URL that you just came from (whether this URL is on our Website or not), which URL you next go to (whether this URL is on our Website or not), your computer browser information, and your IP address.",
          PP8:" We use data collection devices such as cookies on certain pages of the Website to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety. Cookies are small files placed on your hard drive that assist us in providing our services. We offer certain features that are only available through the use of a cookie.",
          PP9:" We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. Most cookies are session cookiesmeaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline our cookies if your browser permits, although in that case you may not be able to use certain features on the Website and you may be required to re-enter your password more frequently during a session.",
          PP10:" We also use the location to give you a better delivery services through the hyperlocal delivery",
          PP11:" Additionally, you may encounter cookies or other similar devices on certain pages of the Website that are placed by third parties. We do not control the use of cookies by third parties.",
          PP12:" If you choose to buy on the Website, we collect information about your buying behaviour.",
          PP13:" If you transact with us, we collect some additional information, such as a billing address, a credit / debit card number and a credit / debit card expiration date and/ or other payment instrument details and tracking information from cheques or money orders.",
          PP14:" If you choose to post messages on our message boards, chat rooms or other message areas or leave feedback, we will collect that information you provide to us. We retain this information as necessary to resolve disputes, provide customer support and troubleshoot problems as permitted by law.",
         PP15:" If you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on the Website, we may collect such information into a file specific to you.",
          PP16:" We collect personally identifiable information (email address, name, phone number, credit card / debit card / other payment instrument details, etc.) from you when you set up a free account with us. While you can browse some sections of our Website without being a registered member, certain activities (such as placing an order) do require registration. We do use your contact information to send you offers based on your previous orders and your interests.",
          PP17:"USE OF DEMOGRAPHIC / PROFILE DATA / YOUR INFORMATION",
          PP19:" In our efforts to continually improve our product and service offerings, we collect and analyse demographic and profile data about our users`'` activity on our Website.",
          PP18:" We use personal information to provide the services you request. To the extent we use your personal information to market to you, we will provide you the ability to opt-out of such uses. We use your personal information to resolve disputes; troubleshoot problems; help promote a safe service; collect money; measure consumer interest in our products and services, inform you about online and offline offers, products, services, and updates; customize your experience; detect and protect us against error, fraud and other criminal activity; enforce our terms and conditions; and as otherwise described to you at the time of collection.",
          PP20:" We identify and use your IP address to help diagnose problems with our server, and to administer our Website. Your IP address is also used to help identify you and to gather broad demographic information.",
          PP21:" We will occasionally ask you to complete optional online surveys. These surveys may ask you for contact information and demographic information (like zip code, age, or income level). We use this data to tailor your experience at our Website, providing you with content that we think you might be interested in and to display content according to your preferences.",
          PP22:" A cookie is a small piece of information stored by a web server on a web browser so it can be later read back from that browser. Cookies are useful for enabling the browser to remember information specific to a given user. We place both permanent and temporary cookies in your computer's hard drive. The cookies do not contain any of your personally identifiable information.",
          PP23:" SHARING OF PERSONAL INFORMATION",
          PP24:" We may share personal information with our other corporate entities and affiliates. These entities and affiliates may market to you as a result of such sharing unless you explicitly opt-out.",
          PP25:" We may disclose personal information to third parties. This disclosure may be required for us to provide you access to our Services, to comply with our legal obligations, to enforce our User Agreement, to facilitate our marketing and advertising activities, or to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our Services. We do not disclose your personal information to third parties for their marketing and advertising purposes without your explicit consent.",
          PP26:" We may disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal information to law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.",
          PP27:" We and our affiliates will share / sell some or all of your personal information with another business entity should we (or our assets) plan to merge with, or be acquired by that business entity, or re-organization, amalgamation, restructuring of business. Should such a transaction occur that other business entity (or the new combined entity) will be required to follow this privacy policy with respect to your personal information.",
          PP28:"LINKS TO OTHER SITES",
          PP29:" Our Website links to other websites that may collect personally identifiable information about you. Instadent.in is not responsible for the privacy practices or the content of those linked websites.",
          PP30:" SECURITY PRECAUTIONS",
          PP31:" Our Website has stringent security measures in place to protect the loss, misuse, and alteration of the information under our control. Whenever you change or access your account information, we offer the use of a secure server. Once your information is in our possession we adhere to strict security guidelines, protecting it against unauthorized access.",
          PP32:"CHOICE/OPT-OUT",
          PP33:" We provide all users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications from us on behalf of our partners, and from us in general, after setting up an account.",
          PP34:" If you want to remove your contact information from all Instadent.in lists and newsletters, please visit to our customer support",
          PP35:"ADVERTISEMENTS ON Instadent.in",
          PP36:" We use third-party advertising companies to serve ads when you visit our Website. These companies may use information (not including your name, address, email address, or telephone number) about your visits to this and other websites in order to provide advertisements about goods and services of interest to you.",
          PP37:"YOUR CONSENT",
          PP39:"If we decide to change our privacy policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it.",
          Cookies:" Cookies:",
          orderplaced:"Your order has been placed",
          PP38:" By using the Website and/ or by providing your information, you consent to the collection and use of the information you disclose on the Website in accordance with this Privacy Policy, including but not limited to Your consent for sharing your information as per this privacy policy.",
           PP40:"QUESTIONS?",
           PP41:" Please Contact Us with any questions regarding this statement.",
           rr1:"RETURN, REFUND, CANCELLATION, AND SHIPPING CHARGES:",
           rr2:"  We offer returns and refund on the products and services ordered by You on the Website which are subject to further terms and conditions as detailed in the cancellation, shipping charges, returns, and refund policy (“Return and Refund Policy”). The&nbsp;Return and Refund Policy&nbsp;forms an integral part of these Terms and the Users are requested to carefully read the same.",
           rr3:"Cancellation Policy",
           rr4:"How can I cancel an order?",
           rr5:"Equipment",
           rr6:" Order for healthcare products can be canceled from the “Order Page” screen on the Instadent website/ app (‘Platform’) or by contacting our Customer Support before it is marked as “Accepted”. Else, you can refuse it at the time of delivery and a refund will be processed as per the Refund Policy.",
           rr7:" In any case of exceptions, the company can cancel the order, the company will not be answerable for it.",
           rr8:"Return Policy",
           rr9:"What is the time period for the return of orders?",
           rr10:" We have a product-specific return policy, return window may differ for product wise. The return window is 10 days from the date of delivery. The information regarding the return policy can be found on the sign-in page. Please check the return policy before placing an order.",
           rr11:"[Note- Currently, the Company is not charging any shipping charges for returns from you.]",
           rr12:"Refund Policy",
           rr13:"Additional Note",
           rr14:"Items whose return window has expired cannot be returned.",
           rr15:"When can I expect the amount to be refunded?",
           rr16:"Please note that the refund amount mentioned at the time of return on the Platform is an estimate and will be finalized after completion of verification. Once the pick-up is completed and the product is verified by the enterprise, a refund of the total amount paid for the products returned by you will be initiated for eligible returns as mentioned above.",
           rr17:"Refund will be initiated only post successful verification of the products by the Enterprise. Please note that the verification process may take:",
           rr18:"For Local Cities*: 48 hours from the time of pick up from your location.",
           rr19:"Method of Payment",
           rr20:"Refund Timeframe (after successful verification from third party pharmacy/ vendor)",
           rr21:"Cash on Delivery [Note: For this type of payment type refund will be initiated via RazorpayX COD link to fill your account details",
           rr22:"business day",
           rr23:"Online refund ",
           rr24:"business days subject to the bank turnaround time and RBI Guidelines.",
           rr25:"You understand and acknowledge that we reserve the right to claim back any refund that has been processed by us as a result of fraudulent activities such as the return of wrong products or the wrong quantity of the products.",
           tc:"Terms and Conditions",
           tc1:"This agreement is entered into and considered effective as of the earlier of the date you, or, if you represent an entity or other organization, that entity or organization (in either case “You”) first access or use Instadent or when You create an account with Instadent.",
           tc2:"Instadent is the owner of this website, Instadent applications, and all other Instadent services. Throughout the Terms of Service, Instadent may be referred to as “us” or “we” or “our”.",
           tc3:" A “Member” is someone who has registered with Instadent. A Member can create an “Account”, which is an online representation of the Member, which may contain Personally Identifiable Information (as defined in the Privacy Policy) regarding that Member.",
           tc4:"What Is The Instadent Service?",
           tc5:"The Instadent Service enables You to check product prices and create shopping lists, search for products as well as use other features related to creating a dentistry list on multiple computer devices and mobile applications. In exchange for being enabled to use the Service, You agree to abide by these",
           tc6:"Terms.",
           tc7:"Who May Use The Instadent Platform?",
           tc8:"The Platform is designed and offered for use by adults only. The Platform is not intended for individuals under the age of 18 nor is the Platform intended for anyone who does not meet any of the other qualifications included in these Terms.",
           tc9:"Will These Terms Of Service Ever Change?",
           tc10:" Changes in these Terms are almost certain to happen, due to changes in our Platform and the laws that apply to Instadent and You. If we make a change, we’ll make reasonable efforts to provide You with advance notice. Any update to these Terms will become effective 30 days after the changes are enacted. We’ll announce changes here at our site, and we also may elect to notify You of changes by sending an email to the address You have provided to us.<br />If we do update these Terms, You are free to decide whether to accept the updated terms or to stop using our Platform; Your continued use of the Platform after the effectiveness of that update will be deemed to represent Your agreement with, and consent to be bound by, the new Terms. If You do not agree with the new Terms, You may cancel Your service at any time.",
           tc11:"What Do I Have To Do To Use the Instadent Service?",
           tc12:"First, You need to create a Instadent Account. You create an Account by providing us with an mobile number which already associated with the WhatsApp and required to fill the “OTP” with same number also you fill the Name, email id, address (collectively “Account Information”).You are responsible for maintaining the accuracy, completeness and confidentiality of Your Account Information, and You will be responsible for all activities that occur under Your account, including activities of others to whom You have provided Your Account Information. We will not be liable for any loss or damage arising from Your failure to provide us with accurate information or to keep Your Account Information secure",
           tc13:" Second, You will need to access Your account through a web browser. Obtaining a mobile device and paying for their connectivity and data plans is Your responsibility. Instadent has no responsibility for the availability of the Internet and other telecommunication services necessary to access the Service.",
           tc14:"Can I Share My Account?",
           tc15:"Sharing of Instadent Accounts is prohibited. If You share Your Account Information with anyone, that other person may be able to take control of the account, and we may not be able to determine who is the proper Account holder. We will not have any liability to You (or anyone You share Your Account Information with) as a result of Your or their actions under those circumstances. Since You may use a free Service account, and since we provide a number of mechanisms to allow You to share Your account Content with others, we strongly urge You not to share Your Account Information with anyone.",
           tc16:"Once I Have An Account, What Are My Rights with Instadent?",
           tc17:"Once Your account is created and You accept these Terms, we grant You a limited, non-exclusive license to use the Platform subject to these Terms, for so long as You are not barred from receiving the Service or using the Applications under the laws applicable to You, until You close Your account voluntarily or until we close Your account pursuant to these Terms. In addition, we grant You a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the Instadent Platform provided to You by or on behalf of Instadent, for the sole purpose of enabling You to use the Instadent Platform and enjoy the benefit of the Service, subject to any applicable license terms provided with the Instadent Platform and these Terms, until Your rights are terminated in accordance with such license and/or these Terms. You do not obtain any other right or interest in Instadent or any part of the Platform.",
           tc18:" Finally, You understand and agree that Instadent, in performing the required technical steps to provide the Service and Applications to our users, may make any changes Instadent deems necessary to conform and adapt that Content to the technical requirements of connecting networks, devices, services or media.",
           tc19:"Are There Rules About Content I Submit to Instadent?",
           tc20:"Yes. Your use of the Platform must be in accordance with these Terms. When it comes to Your use of the Platform, You agree that You are responsible for Your own conduct and all conduct under Your account. This means all content – including price updates, text, images, software, videos and anything else You can think of, no matter what the form or technical structure (collectively, “Content”) – created, transmitted, stored or displayed in Your account, is Your sole responsibility as the person who created the Content or introduced it into the Platform. This applies whether the Content is kept private, shared or transmitted using the Platform or any third party application or services. If we find that any shared Content in Your account violates our Terms of Service (including by violating another person’s intellectual property rights), we reserve the right to remove such content.<br />You agree that You will not upload, post or otherwise transmit any Content that:",
           tc21:"violates any local, state, federal, or international laws;",
           tc22:"infringes on any patent, trademark, trade secret, copyright or other proprietary rights of any party;",
           tc23:"harms, threatens, defames, promotes violence or illegal activities, or is otherwise vulgar, obscene, abusive, harassing, tortuous, libelous, invasive of another’s privacy, hateful, or racially, ethically or otherwise objectionable;",
           tc24:"links directly or indirectly to any materials to which You do not have a right to link;",
           tc25:"contains any private information of any third party, including, without limitation, addresses, phone numbers, email addresses, government issued tax or identification numbers and credit card numbers;",
           tc26:"contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment, or to extract information from Instadent;",
           tc27:"contains any unsolicited or unauthorized advertising, solicitations, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other form of solicitation;",
           tc28:"You do not have a right to transmit under any law (e.g. intellectual property laws) or under contractual or fiduciary relationships (e.g. non-disclosure Agreements); or<br />in the sole judgment of Instadent, is objectionable or which restricts or inhibits any other person from using or enjoying Instadent, or which may expose Instadent, our affiliates, or our Users to any harm or liability of any type.",
           tc29:"You are responsible for complying with all applicable laws, including trademark and copyright laws with respect to all Content created, transmitted, stored, or displayed in Your account. By using Instadent and agreeing to these Terms You warrant that You will not use Instadent to infringe the intellectual property rights of others in any way. In accordance with the DMCA and other applicable laws, we may, in our sole discretion, remove or disable Content claimed to be the subject of infringement or other activity, or we may terminate Your account.",
           tc30:"What are the Terms of Service?",
           tc31:"The Terms of Service constitutes a contract between Instadent and You and governs Your use of Instadent mobile and online applications (“Applications”), the services provided through the Applications or our website (the “Service”), or any other part of our business (collectively the “Platform”). Instadent is a dba of Vasa Denticity Pvt Ltd. The provisions set forth in this document and other terms or conditions that may be presented by us and accepted by You from time to time in connection with use of the Instadent Platform (all of which we collectively refer to as the “Terms of Service” or “Terms”) shall constitute the contract between You and us. If You do not agree to these Terms, You do not have the right to access, use or purchase our Applications or Services. If You do register for or otherwise use the Platform, or purchase any Service from Instadent, You shall be deemed to have accepted the Terms and to have agreed to be a party to this binding contract.<br />By using any part of the Instadent Platform You acknowledge, accept and agree to all provisions of the Privacy Policy, including, without limitation, the use and treatment of Your Account Information and Your Content in accordance with such Privacy Policy.",
          tc32:"Are There Other Restriction To My Use?",
           tc33:" You may not use Instadent to:",
           tc34:"develop a competing website or mobile application or help anyone else to do the same;",
          tc35:"use bots, spiders, crawlers or other scraping techniques to steal Instadent content;",
           tc36:"re-distribute it in any manner, including, but not limited to, sale, license, lease, rental, subscription, or any other distribution mechanism;",
          tc37:"disassemble or reverse engineer Instadent; or",
           tc38:"use Instadent in any manner that violates our Terms or any local, state, federal, or international laws.",
          tc39:"Intellectual Property Rights.",
           tc40:"In agreeing to these Terms, You also agree that the rights in the Instadent Platform, including all intellectual property rights, such as trademarks, patents, industrial designs, logos, symbols and copyrights, are protected by one or more of copyright, trademark, patent, trade secret and other laws, regulations and treaties, in addition to these Terms and any separate agreement. In particular, You agree to not modify, create derivative works of, decompile or otherwise attempt to extract source code from any Instadent software, unless You are expressly permitted to do so under an open source license or we give You express written permission. The Instadent trademark is licenced from Instadent IP, LLC. All Instadent trademarks are registered to and property of Instadent IP, LLC. You may not use the Instadent name or trademarks without the express written consent of Instadent IP, LLC.",
          tc41:"Right to Modify the Platform.",
          tc42:" We retain the right, in our sole discretion, to implement new elements as part of and/or ancillary to the Instadent Platform, including changes that may affect the previous modes of operation of the Platform. We expect that any such modifications will enhance the overall Service, but it is possible that You may not agree with us. We also reserve the right to establish limits to the nature or size of storage available to You, the number of items on Your shopping list or Your continued ability to access or share Your Content and other data, and impose other limitations at any time, with or without notice.",
          tc43:"Right to Engage Third Parties.",
          tc44:"Instadent may from time to time engage certain affiliates or other third parties to provide technical or other services relating to all or part of the Platform, or perform certain functions associated with the operation of the Instadent features and You hereby agree that such third party involvement is acceptable.<br />Right to Update Our Software.<br />In connection with any modification of any part of the Platform, Instadent may automatically download software updates on Your computers and devices from time to time with the intention of improving, enhancing, repairing and/or further developing the Platform. Instadent will attempt to provide You with the option of whether or not to install the update; however, in certain circumstances (e.g., security risks), Instadent may require You to install the update to continue accessing the Platform. In all cases, You agree to permit Instadent to deliver these updates to You (and for You to receive them) as part of Your use of the Platform.",
          tc45:"How Does Instadent Respond To Copyright Or Other Intellectual Property Violations?",
          tc46:" We respond to clear and complete notices of alleged infringement of copyright, trademark or other intellectual property laws. If You believe that Your intellectual property rights have been violated, please notify us and we will investigate. You may mail Your notification to: Email- Contact@instadent.in<br />Khasra No. 714, Village, P.O.- Chattarpur, Opp. DLF Gate No.-2, Near Geetanjali Salon New Delhi, India, 110074",
         tc47:" Please provide the following information to Instadent in Your notification regarding suspected violation of intellectual property rights:<br />1. The identity of the infringed work, and of the allegedly infringing work;<br />2. Your name, address, daytime phone number, and email address, if available;<br />3. A statement that You have a good-faith belief that the use of the copyrighted work is not authorized by the owner, his or her agent, or the law;<br />4. A statement that the information in the notification is accurate and, under penalty of perjury, that You are authorized to act on behalf of the owner; and<br />5. Your electronic or physical signature.",
         tc48:"Note that each owner of intellectual property is responsible for protecting their rights and taking any legal or other action they determine to be appropriate to do so, and Instadent does not accept any obligation to take any particular action to enforce or protect any party’s intellectual property rights.",
         tc49:"Does Instadent Serve Ads?",
         tc50:" We may display advertisements and promotions on or in connection with any part of the Instadent Platform, some of which may be paid for by third parties. We also provide announcements on the Instadent Platform. These messages may promote other Instadent products and services (including premium Instadent features) notify You of events, demonstrate various uses of our Platform and promote certain third party applications and services that work with Instadent. For more information, please see our Privacy Policy page.",
         tc51:" In as much as some advertising or other messaging content we provide will be based upon information provided by third parties, we shall not be responsible or liable for any loss or damage of any sort incurred by You as a result of any advertisements or other messages. Furthermore, Your interactions with advertisers found on or through the Platform, including, without limitation, all reliance upon advertising, all commercial transactions and legal obligations associated therewith, are solely between You and such advertisers.<br />Third-Party Links, Content and Programming.",
         tc52:" We may include or recommend third party resources, materials and developers and/or links to third party websites, content and applications as part of, or in connection with, the Service. We may have little or no control over such sites or developers and, accordingly, You acknowledge and agree that (i) we are not responsible for the availability of such external sites, content or applications; (ii) we are not responsible or liable for any content or other materials or performance available from such sites or applications; and (iii) we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, materials or applications.",
         tc53:"Indemnity.",
         tc54:"You agree to indemnify and hold Instadent, its subsidiaries, affiliates, officers, agents, employees, advertisers and partners harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including legal and other professional fees) arising from or in any way related to any third party claims relating to Your use of any of the Platform, any violation of these Terms of Service or any other actions connected with Your use of the Platform (including all actions taken under Your Account). In the event of such claim, we will provide notice of the claim, suit or action to the contact information we have for the account, provided that any failure to deliver such notice to You shall not eliminate or reduce Your indemnification obligation hereunder.",
         tc55:"Limitation of Liability.",
         tc56p1:" To the maximum extent permitted by law, the Platform is available As Is.YOU EXPRESSLY UNDERSTAND AND AGREE THAT:",
         tc56p2:"(a) YOUR USE OF THE PLATFORM AND THE PURCHASE AND USE OF ANY PRODUCTS OR SERVICES ARE ALL AT YOUR SOLE RISK. THE PLATFORM IS PROVIDED AND PRODUCTS ARE SOLD ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE MAXIMUM EXTENT PERMITTED BY LAW, Instadent EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.",
         tc56p3:"(b) Instadent DOES NOT WARRANT THAT (i) THE PLATFORM WILL MEET ALL OF YOUR REQUIREMENTS;(ii) THE PLATFORM WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; OR (iii) ALL ERRORS IN THE PLATFORM WILL BE CORRECTED.",
         tc56p4:"(c) ANY MATERIAL DOWNLOADED, PRODUCTS AND SERVICES PURCHASED FROM THE Instadent STORE OR OTHERWISE OBTAINED THROUGH THE USE OF THE PLATFORM IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER OR OTHER DEVICE OR LOSS OF DATA RESULTING FROM THE DOWNLOAD OR USE OF ANY SUCH MATERIAL.",
         tc56p5:"(d) NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED  BY YOU FROM Instadent OR THROUGH OR FROM THE PLATFORM SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS OF SERVICE.",
         tc56p6:"YOU EXPRESSLY UNDERSTAND AND AGREE THAT Instadent,ITS SUBSIDIARIES, AFFILIATES AND LICENSORS, AND OUR AND THEIR RESPECTIVE OFFICERS, EMPLOYEES, AGENTS AND SUCCESSORS SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS,GOODWILL, USE, DATA, COVER OR OTHER INTANGIBLE LOSSES (EVEN IF Instadent HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) RESULTING FROM:",
        tc56p7:" (i) THE USE OR THE INABILITY TO USE THE PLATFORM; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROMANY GOODS, PRODUCTS, DATA, INFORMATION OR SERVICE PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE PLATFORM; (iii)",
         tc56p8:" THE LOSS, CORRUPTION OR ALTERATION OF YOUR TRANSMISSIONS, CONTENT OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON OR USING THE PLATFORM, OR PROVIDING ANY SERVICES RELATED TO THE OPERATION OF THE PLATFORM OR RELATED TO THE Instadent PREMIUM FEATURES; (v)",
       tc56p9:"Instadent’S ACTIONS OR OMISSIONS IN RELIANCE UPON YOUR ACCOUNT INFORMATION AND ANY CHANGES THERETO OR NOTICES RECEIVED THEREFROM; (vi) YOUR FAILURE TO PROTECT THE CONFIDENTIALITY OF ANY PASSWORDS OR ACCESS RIGHTS TO YOUR ACCOUNT INFORMATION; (vii)",
         tc56p10:"THE ACTS OR OMISSIONS OF ANY THIRD PARTY USING OR INTEGRATING WITH THE PLATFORM OR OFFERING PRODUCTS IN THE Instadent STORE; (viii) ANY ADVERTISING CONTENT OR YOUR PURCHASE OR USE OF ANY ADVERTISED OR OTHER THIRD-PARTY PRODUCT OR SERVICE; (ix) ",
        tc56p11:"THE TERMINATION OF YOUR ACCOUNT IN ACCORDANCE WITH THE TERMS OF THESE TERMS OF SERVICE; OR (x) ANY OTHER MATTER RELATING TO THE Instadent PLATFORM.",
         tc56p12:"WITHOUT LIMITING ANY OTHER PROVISION IN THE TERMS, YOU ACKNOWLEDGE THAT NOTHING CONTAINED IN Instadent SHALL CONSTITUTE A WARRANTY OR GUARANTEE WITH REGARD TO THE PRICING INFORMATION WE PROVIDE. NO PROFESSIONAL RELATIONSHIP OF ANY KIND IS CREATED BETWEEN YOU AND Instadent OR ITS MEMBERS. Instadent IS FOR INFORMATIONAL PURPOSES ONLY.",
        tc57:"Exclusions and Limitations.",
        tc58:"NOTHING IN THESE TERMS OF SERVICE (INCLUDING THE LIMITATION OF LIABILITY PROVISIONS) IS INTENDED TO EXCLUDE OR LIMIT ANY CONDITION, WARRANTY, RIGHT OR LIABILITY WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR CONDITIONS OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR LOSS OR DAMAGE CAUSED BY WILLFUL ACTS, NEGLIGENCE, BREACH OF CONTRACT OR BREACH OF IMPLIED TERMS, OR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, ONLY THOSE LIABILITY AND OTHER LIMITATIONS WHICH ARE LAWFUL IN YOUR JURISDICTION (IF ANY) WILL APPLY TO YOU AND OUR LIABILITY IS LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.",
         tc59:"Claims Are Time-Barred.",
         tc60:" You agree that regardless of any statute or law to the contrary or the applicable dispute resolution process, any claim or cause of action You may have arising out of or related to use of the Platform or otherwise under these Terms must be filed within one (1) year after such claim or cause of action arose or You hereby agree to be forever barred from bringing such claim.",
        tc61:" THE PROVISIONS OF THIS SECTION, ENTITLED “CLAIMS ARE TIME-BARRED” SHALL BE DEEMED TO CONSTITUTE A SEPARATE WRITTEN LEGALLY BINDING AGREEMENT BY AND BETWEEN YOU AND Instadent.",
        tc62:"Termination of Your Account.",
        tc63:" If You violate any of the provisions of these Terms, we reserve the right to terminate Your Account with or without notice to You. You may also voluntarily terminate Your Account and cancel the free Service by deleting the app from Your mobile device.",
       tc64:"We do our best to provide accurate, timely information about products sales, prices and coupons. If You find an error in a store sale price or base pricing, please contact us so we can correct it. Due to the nature of the sales and fluctuations in base pricing, we aim to provide You with the most up to date and accurate data, however we do not represent or otherwise warrant that anything available on or through our Platform will be 100% correct, accurate, timely or otherwise reliable. We may make changes to the Instadent Platform at any time. We reserve the right in our sole discretion to edit or remove any documents, information or other content appearing on the Instadent website, Application, or anywhere else on the Instadent Platform.",
        tc65:" Content Disclaimer.",
        tc66:"Instadent is not responsible or liable in any manner for any Content posted on the Instadent website, Application, or anywhere else on the Platform whether posted or caused by Members or by Instadent. Although we provide rules for Member conduct and postings, we do not control and are not responsible for what Members post, transmit or share on our Platform, and are not responsible for any offensive, inappropriate, obscene, unlawful or otherwise objectionable Content You may encounter while using Instadent. Instadent is not responsible for the conduct, whether online or offline, of any user of Instadent.",
        tc67:"Reference to any products, service, processes or other information, by trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof, or any affiliation therewith, by Instadent, by third parties or by any of the equipment or programming associated with or utilized by Instadent. All trade names, trademarks and service marks that appear on the Service are the property of their respective owners.",
        tc68:"Unlawful Activity.",
        tc69:" We reserve the right to investigate complaints or reported violations of our Terms and to take any action we deem appropriate, including but not limited to terminating Your Member Account, reporting any suspected unlawful activity to law enforcement officials, regulators, or other third parties and disclosing any information necessary or appropriate to such persons or entities relating to Your profile, email addresses, usage history, posted materials, IP addresses and traffic information.",
        tc70:"Links to Other Websites.",
        tc71:" Our website and Applications may, from time to time, contain links to third party websites. These links are provided solely as a convenience to You. By linking to these websites, we do not create or have an affiliation with, or sponsor such third party websites. Inclusion of links for any website on our Platform does not mean that we endorse, guarantee, warrant, or recommend the services, information, content and/or data of such third party websites. Instadent has no control over the legal documents and privacy practices of third party websites; as such, You access any such third party websites at Your own risk.",
        tc72:" Arbitration",
        tc73:"Any legal controversy or legal claim arising out of or relating to our Terms or the Instadent Platform, excluding legal action taken by us to collect or recover damages for or obtain any injunction relating to Your violation of our Terms, shall be settled solely by binding arbitration in accordance with the commercial arbitration rules of the American Arbitration Association. To the extent permitted by law, any such controversy or claim shall be arbitrated on an individual basis and not as part of any purported class or representative action or proceeding, and shall not be consolidated in any arbitration with any claim or controversy of any other party. The arbitration shall be conducted in Denver, Colorado, USA, and judgment on the arbitration award may be entered into any court having competent jurisdiction thereof. Either You or we may seek any interim or preliminary relief from a court of competent jurisdiction in the State of Colorado, United States, necessary to protect the rights or property of You and Instadent pending the completion of arbitration. Each party shall bear one-half of the arbitration fees and costs.",
        tc74:"Notices.",
        tc75:"  Khasra No. 714, Village, P.O.- Chattarpur, Opp. DLF Gate No.-2, Near Geetanjali Salon New Delhi, India, 110074",
        tc76:"USE OF MATERIALS",
        tc77:"Except as expressly indicated to the contrary in any applicable Additional Terms, Company hereby grants You a personal, non-exclusive, freely revocable (upon notice from Company), non-transferable access to view, download and print product catalogues or any other materials available on the Website, subject to the following condition:",
        tc78:"You may access and use the materials solely for personal, informational, and internal purposes, in accordance with the Terms; You may not modify or alter Product catalogues or any other materials available on the Website;",
        tc79:"You may not distribute or sell, rent, lease, license or otherwise make the Product catalogues or any other materials available on the Website available to others; and",
        tc80:"You may not remove any text, copyright or other proprietary notices contained in the Product catalogues or any other materials available on the Website.</li></ul><p>The limited rights granted to You in the Product catalogues, or any other materials as specified above do not confer upon You any rights to the design, layout or look and feel of the Website. Such elements of the Website are protected by intellectual property rights and may not be copied or imitated in whole or in part. The Product catalogues or any other materials available on the Website shall not be copied or retransmitted unless expressly permitted by Company. Any software that is available on the Website is the property of Company or third parties. You may not use, download or install any software available at the Website, unless otherwise expressly permitted by these Terms or by the express written permission of Company.<br />Any purchase of the merchandise or Services from the Website will be strictly for personal use of the User. The User hereby expressly agrees that any merchandize, or Services purchased by the User will not be sold, resold, bartered or in any way be used for any commercial purposes or for profit. The User hereby acknowledges that the Services or merchandize purchased are not transferrable to any third party for profit",
        abt:"Us",
        abt3:"Our",
        abt4:"Story",
        abt6:"Instadent is the shopping destination for Dentists across the country, Currently delivering to Haryana And Delhi NCR in India. Online Shopping – A Boon The trend of online shopping is becoming a household name and so is Instadent.",
        abt5:"In February 2022, Vasa denticity Pvt Ltd started a pilot project of Instadent to gather immediate demand of dental products by dentists of delhi, with the widest assortment of 500+ plus products across 80 plus diverse categories from Delhi NCR and Haryana Region, national, and international brands.",
        abt1:"India’s Ultimate Online dental Shopping Destination. Instadent’s vision is to create India’s most reliable and frictionless marketplace ecosystem that creates life-changing experiences for buyers and sellers.",
        abt7:"What we",
        abt8:"offers",
        abt10:"Once you have zeroed in on your favourite products, simply place the order by filling in the details; the products will be delivered right at your doorstep. Shop on the Go – Install Instadent WebApp Today! You can shop for your favourite products at Instadent.in.",
        abt11:"Delhi NCR and Haryana+",
        abt12:"City",
        abt13:"Top Brands",
        abt14:"Products",
        abt15:"Our Core",
        abt16:"Values",
        abt17:"ETHICS",
        abt19:"TEAM AND DEDICATION",
        abt20:"Dedication to team achieves best-in-class solutions in products, processes and people",
        abt21:"CUSTOMER FOCUS",
        abt22:" Customer satisfaction is at the center of what we do everyday. We are a trusted partner ofour customers.",
        abt23:"EMPOWER AND DELIBRATE",
        abt24:" Employees are encouraged to take action and ensure timely delivery of expectations.",
        abt25:"SAFETY, QUALITY",
        abt26:"WELL BEING",
        first:"Please login first.",
        sec:"Added to Wishlist successfully.",
        pp1:"Enter the Quantity here",
        Update:"Update",
        ord1:"Data Is Not Available",
        ord2:"Something Went Wrong",
        geo:"geolocation is not supported",
        cont:"Please login to continue",
        cartempty:"Your Cart Is Empty",
        paymentmode:"Select Payment Mode",
        paymentmethod1:"Some of cart items are out of stock",
        notthere:"This much quantity is not in stock.",
        banktranfer:"Bank Transfer",
        PlaceOrder:"Place Order",
        OutofStock:"OUT OF STOCK",
        MinimumCartValue:"Minimum Cart Value",
        Minimumordervalueis:"Minimum order value is",
        EntertheQuantityhere:" Enter the Quantity here",
        CancellationPolicy:"Cancellation Policy",
        Item :"Items ",
        TotalAmount:"Total Amount",
        Placedon:"Placed on",
        ReviewRating:"Review & Rating",
        Reorder:" Reorder",
        Apply:"Apply",
        Remove:"Remove",
        sidecart:"Order can not be cancelled once packed for delivery. In case of unexpected delays, a refund will be provided, if applicable.",
        abt27:"Exceed Industry standards for safety and performance by leveraging our core values.",
        abt18:"We own our behaviour, keep our promises, value honesty, honor the community environment in which we operate and embrace diversity",
        abt9:"Shopping online particularly at Instadent is a child’s play; all you need is a mobile phone or laptop or tablet with Internet connection to get started. Simply log into Instadent.in and browse through the wide assortment of products across categories.",
        abt2:" Instadent is the preferred choice of hundreds of thousands of Dentists given its mammoth assortment of 500+ products, quick delivery even to theremotest corners of the Delhi NCR and Haryana.",
        nodel:"No Delivery charges are applicable on your order,minimum order value will be 500 for the 2nd order of an individual customer",
        banner:" Enjoy Pay On Delivery & Bank Transfer & Get Upto 30% Off! HURRY UP! Use 📦DCODE5  & Get 5% Extra Off. "
        },
      },
      "pt-BR": {
        translation: {
          Account:"Conta",
          Reorder:"Reordenar",
          Placedon:"Colocado sobre",
          Remove:"Remover",
          Apply:"Aplicar",
          PlaceOrder:"Faça a encomenda",
          paymentmode:"Selecione o modo de pagamento",
          banktransfer:"Transferência bancária",
          EntertheQuantityhere :"Insira a Quantidade aqui",
          CancellationPolicy:"Política de cancelamento",
          ReviewRating:"Revisão e classificação",
          TotalAmount:"Montante total",
          Item:"Unid",
          sidecart:"O pedido não pode ser cancelado depois de embalado para entrega. Em caso de atrasos inesperados, será fornecido um reembolso, se aplicável.",
          paymentmethod1:"Alguns itens do carrinho estão fora de estoque",
          My:"Meu",
          Minimumordervalueis:"O valor mínimo do pedido é",
          MinimumCartValue:"Valor mínimo do carrinho",
          OutofStock:"Fora de estoque",
          ord2:"Algo deu errado",
          ord1:"Os dados não estão disponíveis",
          geo:"a geolocalização não é suportada",
          cont:"Por favor faça o login para continuar",
          cartempty:"Seu carrinho está vazio",
          notthere:"Essa quantidade não está em estoque.",
          first:"Faça login primeiro.",
          pp1:"Insira a Quantidade aqui",
          Update:"Atualizar",
          abt27:"Exceda os padrões da indústria em termos de segurança e desempenho, alavancando nossos valores fundamentais.",
          abt26:"BEM ESTAR",
          abt25:"SEGURANÇA, QUALIDADE",
          abt24:"Os funcionários são incentivados a agir e garantir a entrega oportuna das expectativas.",
          abt23:"CAPACITAR E DELIBRAR",
          abt22:"A satisfação do cliente está no centro do que fazemos todos os dias. Somos um parceiro confiável de nossos clientes.",
          abt21:"FOCO NO CLIENTE",
          abt20:"A dedicação à equipe alcança as melhores soluções em produtos, processos e pessoas",
          abt19:"EQUIPE E DEDICAÇÃO",
          abt18:"Assumimos o nosso comportamento, cumprimos as nossas promessas, valorizamos a honestidade, honramos o ambiente comunitário em que operamos e abraçamos a diversidade",
          abt17:"ÉTICA",
          abt16:"Valores",
          abt15:"Nosso Núcleo",
          abt14:"Produtos",
          abt13:"Marcas de topo",
          abt12:"Cidade",
          abt11:"Deli NCR e Haryana+",
          abt10:"Depois de definir seus produtos favoritos, basta fazer o pedido preenchendo os dados; os produtos serão entregues na sua porta. Compre em qualquer lugar – Instale o Instadent WebApp hoje mesmo! Você pode comprar seus produtos favoritos em Instadent.in.",
          abt9:"Comprar online, especialmente na Instadent, é uma brincadeira de criança; tudo que você precisa é de um telefone celular, laptop ou tablet com conexão à Internet para começar. Basta fazer login em Instadent.in e navegar pela ampla variedade de produtos em todas as categorias.",
          abt8:"ofertas",
          abt7:"O que nós",
          abt4:"História",
          abt6:"Instadent é o destino de compras para dentistas em todo o país, atualmente entregando em Haryana e Delhi NCR, na Índia. Compras on-line – uma vantagem A tendência das compras on-line está se tornando um nome familiar, assim como a Instadent.",
          abt:"Nós",
          abt3:"Nossa",
          abt5:"Em fevereiro de 2022, a Vasa denticity Pvt Ltd iniciou um projeto piloto da Instadent para atender à demanda imediata de produtos odontológicos por dentistas de Delhi, com a mais ampla variedade de mais de 500 produtos em mais de 80 categorias diversas de Delhi NCR e região de Haryana, nacional, e marcas internacionais.",
          abt2:"Instadent é a escolha preferida de centenas de milhares de dentistas devido à sua enorme variedade de mais de 500 produtos, entrega rápida até mesmo nos cantos mais remotos de Delhi NCR e Haryana.",
          abt1:"O melhor destino de compras odontológicas on-line da Índia. A visão da Instadent é criar o ecossistema de mercado mais confiável e sem atritos da Índia, que crie experiências de mudança de vida para compradores e vendedores.",
          tc80:"Você não pode remover nenhum texto, direitos autorais ou outros avisos de propriedade contidos nos catálogos de Produtos ou quaisquer outros materiais disponíveis no Site.</li></ul><p>Os direitos limitados concedidos a Você nos catálogos de Produtos ou quaisquer outros materiais especificados acima não conferem a Você quaisquer direitos sobre o design, layout ou aparência do Site. Tais elementos do Site estão protegidos por direitos de propriedade intelectual e não podem ser copiados ou imitados no todo ou em parte. Os catálogos de Produtos ou quaisquer outros materiais disponíveis no Site não serão copiados ou retransmitidos, a menos que expressamente permitido pela Empresa. Qualquer software disponível no Site é propriedade da Empresa ou de terceiros. Você não pode usar, baixar ou instalar qualquer software disponível no Site, a menos que expressamente permitido por estes Termos ou pela permissão expressa por escrito da Empresa.<br />Qualquer compra de mercadorias ou Serviços do Site será estritamente para uso pessoal. uso do Usuário. O Usuário concorda expressamente que qualquer mercadoria ou Serviços adquiridos pelo Usuário não serão vendidos, revendidos, trocados ou de qualquer forma usados ​​para quaisquer fins comerciais ou com fins lucrativos. O Usuário reconhece que os Serviços ou mercadorias adquiridas não são transferíveis a terceiros com fins lucrativos",
          tc79:"Você não pode distribuir ou vender, alugar, arrendar, licenciar ou de outra forma disponibilizar os catálogos de Produtos ou quaisquer outros materiais disponíveis no Site para terceiros; e",
          tc78:"Você poderá acessar e usar os materiais exclusivamente para fins pessoais, informativos e internos, de acordo com os Termos; Você não pode modificar ou alterar catálogos de Produtos ou quaisquer outros materiais disponíveis no Site;",
          tc77:"Exceto quando expressamente indicado em contrário em quaisquer Termos Adicionais aplicáveis, a Empresa concede a Você acesso pessoal, não exclusivo, livremente revogável (mediante notificação da Empresa) e intransferível para visualizar, baixar e imprimir catálogos de produtos ou quaisquer outros materiais disponíveis. no Site, sujeito às seguintes condições:",
          tc76:"USO DE MATERIAIS",
          tc75:"Khasra No. 714, Village, PO- Chattarpur, Opp. DLF Gate No.-2, perto do Geetanjali Salon New Delhi, Índia, 110074",
          tc74:"Avisos.",
          tc73:"Qualquer controvérsia legal ou reclamação legal decorrente ou relacionada aos nossos Termos ou à Plataforma Instadent, excluindo ações legais tomadas por nós para cobrar ou recuperar danos ou obter qualquer liminar relacionada à Sua violação de nossos Termos, será resolvida exclusivamente por arbitragem vinculativa. de acordo com as regras de arbitragem comercial da American Arbitration Association. Na medida permitida por lei, qualquer controvérsia ou reivindicação será arbitrada individualmente e não como parte de qualquer suposta ação ou processo coletivo ou representativo, e não será consolidada em qualquer arbitragem com qualquer reivindicação ou controvérsia de qualquer outra parte. . A arbitragem será conduzida em Denver, Colorado, EUA, e o julgamento da sentença arbitral poderá ser proferido em qualquer tribunal com jurisdição competente. Você ou nós podemos buscar qualquer medida provisória ou preliminar de um tribunal de jurisdição competente no Estado do Colorado, Estados Unidos, necessária para proteger os direitos ou propriedade de Você e da Instadent enquanto se aguarda a conclusão da arbitragem. Cada parte arcará com metade das taxas e custos da arbitragem.",
          tc72:"Arbitragem",
          tc71:"Nosso site e Aplicativos podem, ocasionalmente, conter links para sites de terceiros. Esses links são fornecidos apenas para sua conveniência. Ao criar links para esses sites, não criamos, não temos afiliação nem patrocinamos tais sites de terceiros. A inclusão de links para qualquer site em nossa Plataforma não significa que endossamos, garantimos ou recomendamos os serviços, informações, conteúdo e/ou dados de tais sites de terceiros. A Instadent não tem controle sobre os documentos legais e práticas de privacidade de sites de terceiros; como tal, você acessa quaisquer sites de terceiros por sua própria conta e risco.",
          tc70:"Links para outros sites.",
          tc69:"Reservamo-nos o direito de investigar reclamações ou violações relatadas de nossos Termos e de tomar qualquer ação que considerarmos apropriada, incluindo, entre outras, encerrar sua conta de membro, relatar qualquer suspeita de atividade ilegal a autoridades policiais, reguladores ou outros terceiros e divulgar quaisquer informações necessárias ou apropriadas a tais pessoas ou entidades relacionadas ao Seu perfil, endereços de e-mail, histórico de uso, materiais postados, endereços IP e informações de tráfego.",
          tc68:"Atividade ilegal.",
          tc67:"A referência a quaisquer produtos, serviços, processos ou outras informações, por nome comercial, marca registrada, fabricante, fornecedor ou de outra forma, não constitui ou implica endosso, patrocínio ou recomendação dos mesmos, ou qualquer afiliação com os mesmos, pela Instadent, por terceiros ou por qualquer um dos o equipamento ou programação associada ou utilizada pela Instadent. Todos os nomes comerciais, marcas comerciais e marcas de serviço que aparecem no Serviço são propriedade de seus respectivos proprietários.",
          tc66:"A Instadent não é responsável de forma alguma por qualquer Conteúdo publicado no site da Instadent, no Aplicativo ou em qualquer outro lugar da Plataforma, seja publicado ou causado por Membros ou pela Instadent. Embora forneçamos regras para a conduta e publicações dos Membros, não controlamos e não somos responsáveis ​​pelo que os Membros publicam, transmitem ou compartilham em nossa Plataforma, e não somos responsáveis ​​por qualquer Conteúdo ofensivo, impróprio, obsceno, ilegal ou de outra forma censurável que você possa encontrar. ao usar Instadent. A Instadent não é responsável pela conduta, seja online ou offline, de qualquer usuário da Instadent.",
          tc65:"Isenção de responsabilidade de conteúdo.",
          tc64:"Fazemos o possível para fornecer informações precisas e oportunas sobre vendas, preços e cupons de produtos. Se você encontrar um erro no preço de venda da loja ou no preço base, entre em contato conosco para que possamos corrigi-lo. Devido à natureza das vendas e às flutuações no preço base, pretendemos fornecer-lhe os dados mais atualizados e precisos, no entanto, não representamos nem garantimos que qualquer coisa disponível na ou através da nossa Plataforma será 100% correta, preciso, oportuno ou confiável. Poderemos fazer alterações na Plataforma Instadent a qualquer momento. Reservamo-nos o direito, a nosso exclusivo critério, de editar ou remover quaisquer documentos, informações ou outro conteúdo que apareça no site Instadent, Aplicativo ou em qualquer outro lugar da Plataforma Instadent.",
          tc63:"Se você violar qualquer uma das disposições destes Termos, nos reservamos o direito de encerrar sua conta com ou sem aviso prévio. Você também pode encerrar voluntariamente sua conta e cancelar o serviço gratuito excluindo o aplicativo do seu dispositivo móvel.",
          tc62:"Encerramento da sua conta.",
          tc61:"AS DISPOSIÇÕES DESTA SEÇÃO, entitulada “REIVINDICAÇÕES PRESCRITAS” SERÃO CONSIDERADAS COMO CONSTITUIR UM ACORDO LEGALMENTE VINCULANTE ESCRITO SEPARADO POR E ENTRE VOCÊ E A Instadent.",
          tc60:"Você concorda que, independentemente de qualquer estatuto ou lei em contrário ou do processo de resolução de disputa aplicável, qualquer reclamação ou causa de ação que Você possa ter decorrente ou relacionada ao uso da Plataforma ou de outra forma sob estes Termos deve ser apresentada dentro de um (1 ) um ano após o surgimento de tal reclamação ou causa de ação ou Você concorda em ficar permanentemente impedido de apresentar tal reclamação.",
          tc59:"As reivindicações prescrevem.",
          tc58:"NADA NESTES TERMOS DE SERVIÇO (INCLUINDO A LIMITAÇÃO DE DISPOSIÇÕES DE RESPONSABILIDADE) PRETENDE EXCLUIR OU LIMITAR QUALQUER CONDIÇÃO, GARANTIA, DIREITO OU RESPONSABILIDADE QUE NÃO POSSA SER LEGALMENTE EXCLUÍDO OU LIMITADO. ALGUMAS JURISDIÇÕES NÃO PERMITEM A EXCLUSÃO DE CERTAS GARANTIAS OU CONDIÇÕES OU A LIMITAÇÃO OU EXCLUSÃO DE RESPONSABILIDADE POR PERDAS OU DANOS CAUSADOS POR ATOS INTENCIONAIS, NEGLIGÊNCIA, QUEBRA DE CONTRATO OU QUEBRA DE TERMOS IMPLÍCITOS, OU DANOS INCIDENTAIS OU CONSEQUENCIAIS. ASSIM, SOMENTE A RESPONSABILIDADE E OUTRAS LIMITAÇÕES LEGAIS NA SUA JURISDIÇÃO (SE HOUVER) SERÃO APLICÁVEIS A VOCÊ E NOSSA RESPONSABILIDADE ESTÁ LIMITADA À EXTENSÃO MÁXIMA PERMITIDA POR LEI.",
          tc57:"Exclusões e Limitações.",
          tc56p12:"SEM LIMITAR QUALQUER OUTRA DISPOSIÇÃO NOS TERMOS, VOCÊ RECONHECE QUE NADA CONTIDO NO Instadent CONSTITUIRÁ UMA GARANTIA COM RELAÇÃO ÀS INFORMAÇÕES DE PREÇOS QUE FORNECEMOS. NENHUMA RELAÇÃO PROFISSIONAL DE QUALQUER TIPO É CRIADA ENTRE VOCÊSE Instadent OU SEUS MEMBROS. Instadent É APENAS PARA FINS INFORMATIVOS.",
          tc56p11:"O ENCERRAMENTO DA SUA CONTA DE ACORDO COM OS TERMOS DESTES TERMOS DE SERVIÇO; OU (x) QUALQUER OUTRO ASSUNTO RELACIONADO À PLATAFORMA Instadent.",
          tc56p10:"OS ATOS OU OMISSÕES DE QUALQUER TERCEIRO QUE UTILIZE OU INTEGRE-SE À PLATAFORMA OU OFERECE PRODUTOS NA LOJA Instadent; (viii) QUALQUER CONTEÚDO PUBLICITÁRIO OU SUA COMPRA OU USO DE QUALQUER PRODUTO OU SERVIÇO ANUNCIADO OU DE TERCEIROS; (ix)",
          tc56p9:"AÇÕES OU OMISSÕES DA Instadent COM BASE NAS INFORMAÇÕES DA SUA CONTA E QUALQUER ALTERAÇÃO OU AVISOS RECEBIDOS DELA; (vi) SUA FALHA EM PROTEGER A CONFIDENCIALIDADE DE QUAISQUER SENHAS OU DIREITOS DE ACESSO ÀS INFORMAÇÕES DA SUA CONTA; (vii)",
          tc56p8:"A PERDA, CORRUPÇÃO OU ALTERAÇÃO DE SUAS TRANSMISSÕES, CONTEÚDO OU DADOS; (iv) DECLARAÇÕES OU CONDUTA DE TERCEIROS SOBRE OU UTILIZANDO A PLATAFORMA, OU FORNECENDO QUAISQUER SERVIÇOS RELACIONADOS À OPERAÇÃO DA PLATAFORMA OU RELACIONADOS AOS RECURSOS PREMIUM Instadent; (v)",
          tc56p7:"(i) A UTILIZAÇÃO OU A INCAPACIDADE DE UTILIZAÇÃO DA PLATAFORMA; (ii) O CUSTO DE AQUISIÇÃO DE BENS E SERVIÇOS DE SUBSTITUIÇÃO RESULTANTES DE QUAISQUER BENS, PRODUTOS, DADOS, INFORMAÇÕES OU SERVIÇO COMPRADOS OU OBTIDOS OU MENSAGENS RECEBIDAS OU TRANSAÇÕES REALIZADAS ATRAVÉS OU A PARTIR DA PLATAFORMA; (iii)",
          tc56p6:"VOCÊ ENTENDE E CONCORDA EXPRESSAMENTE QUE A Instadent, SUAS SUBSIDIÁRIAS, AFILIADAS E LICENCIADORES, E NOSSOS E SEUS RESPECTIVOS DIRETORES, FUNCIONÁRIOS,AGENTES E SUCESSORES NÃO SERÃO RESPONSÁVEIS PERANTE VOCÊ POR QUALQUER QUAISQUER DIRETO, INDIRETO, INCIDENTAL, ESPECIAL,DANOS CONSEQUENCIAIS OU EXEMPLARE, INLUINDO, MAS NÃO SE LIMITANDO A, DANOS POR PERDA DE LUCROS, BEM-VINDO, USO, DADOS, COBERTURA OU OUTRAS PERDAS INTANGÍVEIS (MESMO QUE A Instadent TENHA SIDO AVISADA DA POSSIBILIDADE DE TAIS DANOS) RESULTANTES DE:",
          tc56p5:"(d) NENHUM CONSELHO OU INFORMAÇÃO, SEJA ORAL OU ESCRITO, OBTIDO POR VOCÊ DA Instadent OU ATRAVÉS DA PLATAFORMA CRIARÁ QUALQUER GARANTIA NÃO EXPRESSAMENTE DECLARADA NESTES TERMOS DE SERVIÇO.",
          tc56p4:"(c) QUALQUER MATERIAL BAIXADO, PRODUTOS E SERVIÇOS ADQUIRIDOS NA LOJA Instadent OU OBTIDOS DE OUTRA FORMA ATRAVÉS DO USO DA PLATAFORMA É FEITO POR SUA PRÓPRIA CONTA E RISCO E VOCÊ É O ÚNICO RESPONSÁVEL POR QUALQUER DANO AO SEU COMPUTADOR OU OUTRO DISPOSITIVO OU PERDA DE DADOS RESULTANTES DO DOWNLOAD OU USO DE QUALQUER MATERIAL.",
          tc56p3:"(b) A Instadent NÃO GARANTE QUE (i) A PLATAFORMA ATENDERÁ TODAS AS SUAS NECESSIDADES;(ii) A PLATAFORMA SERÁ ININTERRUPTA, OPORTUNA, SEGURA OU LIVRE DE ERROS; OU (iii) TODOS OS ERROS NA PLATAFORMA SERÃO CORRIGIDOS.",
          tc56p2:"(a) SEU USO DA PLATAFORMA E A COMPRA E USO DE QUAISQUER PRODUTOS OU SERVIÇOS SÃO POR SUA CONTA E RISCO. A PLATAFORMA É FORNECIDA E OS PRODUTOS SÃO VENDIDOS “COMO ESTÃO” E “CONFORME DISPONÍVEIS”. NA MEDIDA MÁXIMA PERMITIDA POR LEI, A Instadent ISENTA-SE EXPRESSAMENTE DE TODAS AS GARANTIAS E CONDIÇÕES DE QUALQUER TIPO, EXPRESSAS OU IMPLÍCITAS, INCLUINDO, MAS NÃO SE LIMITANDO ÀS GARANTIAS E CONDIÇÕES IMPLÍCITAS DE COMERCIALIZAÇÃO, ADEQUAÇÃO A UM DETERMINADO FIM E NÃO VIOLAÇÃO.",
          tc56p1:"Na extensão máxima permitida por lei, a Plataforma está disponível “No estado em que se encontra”. VOCÊ ENTENDE E CONCORDA EXPRESSAMENTE QUE:",
          tc55:"Limitação de responsabilidade.",
          tc54:"Você concorda em indenizar e isentar a Instadent, suas subsidiárias, afiliadas, executivos, agentes, funcionários, anunciantes e parceiros de e contra todas e quaisquer reivindicações, responsabilidades, danos (reais e consequentes), perdas e despesas (incluindo honorários legais e outros honorários profissionais). ) decorrentes ou de alguma forma relacionadas a quaisquer reclamações de terceiros relacionadas ao Seu uso de qualquer parte da Plataforma, qualquer violação destes Termos de Serviço ou quaisquer outras ações relacionadas ao Seu uso da Plataforma (incluindo todas as ações tomadas em Sua Conta) . No caso de tal reclamação, forneceremos notificação da reclamação, ação ou ação através das informações de contato que temos para a conta, desde que qualquer falha na entrega de tal notificação a Você não elimine ou reduza Sua obrigação de indenização nos termos deste documento.",
          tc53:"Indenização.",
          tc52:"Poderemos incluir ou recomendar recursos, materiais e desenvolvedores de terceiros e/ou links para sites, conteúdos e aplicativos de terceiros como parte ou em conexão com o Serviço. Podemos ter pouco ou nenhum controle sobre esses sites ou desenvolvedores e, portanto, Você reconhece e concorda que (i) não somos responsáveis ​​pela disponibilidade de tais sites, conteúdos ou aplicativos externos; (ii) não somos responsáveis ​​por qualquer conteúdo ou outros materiais ou desempenho disponíveis em tais sites ou aplicativos; e (iii) não seremos responsáveis, direta ou indiretamente, por qualquer dano ou perda causado ou alegadamente causado por ou em conexão com o uso ou confiança em tais conteúdos, materiais ou aplicativos.",
          tc51:"Na medida em que alguns anúncios ou outros conteúdos de mensagens que fornecemos sejam baseados em informações fornecidas por terceiros, não seremos responsáveis ​​por qualquer perda ou dano de qualquer tipo incorrido por Você como resultado de quaisquer anúncios ou outras mensagens. Além disso, Suas interações com anunciantes encontrados na Plataforma ou através dela, incluindo, sem limitação, toda confiança em publicidade, todas as transações comerciais e obrigações legais associadas a ela, são exclusivamente entre Você e esses anunciantes.<br />Links de Terceiros, Conteúdo e Programação.",
          tc50:"Poderemos exibir anúncios e promoções em ou em conexão com qualquer parte da Plataforma Instadent, alguns dos quais podem ser pagos por terceiros. Também fornecemos anúncios na plataforma Instadent. Essas mensagens podem promover outros produtos e serviços da Instadent (incluindo recursos premium da Instadent), notificá-lo sobre eventos, demonstrar vários usos de nossa Plataforma e promover determinados aplicativos e serviços de terceiros que funcionam com a Instadent. Para obter mais informações, consulte nossa página de Política de Privacidade.",
          tc49:"O Instadent veicula anúncios?",
          tc48:"Observe que cada proprietário de propriedade intelectual é responsável por proteger seus direitos e tomar qualquer ação legal ou outra que considere apropriada para fazê-lo, e a Instadent não aceita qualquer obrigação de tomar qualquer ação específica para fazer cumprir ou proteger os direitos de propriedade intelectual de qualquer parte. ",
          tc47:"Forneça as seguintes informações à Instadent em sua notificação sobre suspeita de violação de direitos de propriedade intelectual:<br />1. A identidade da obra violada e da obra supostamente infratora;<br />2. Seu nome, endereço, número de telefone diurno e endereço de e-mail, se disponível;<br />3. Uma declaração de que você acredita de boa-fé que o uso do trabalho protegido por direitos autorais não é autorizado pelo proprietário, seu agente ou pela lei;<br />4. Uma declaração de que as informações contidas na notificação são precisas e, sob pena de perjúrio, de que Você está autorizado a agir em nome do proprietário; e<br />5. Sua assinatura eletrônica ou física.",
          tc46:"Respondemos a notificações claras e completas de supostas violações de direitos autorais, marcas registradas ou outras leis de propriedade intelectual. Se você acredita que seus direitos de propriedade intelectual foram violados, notifique-nos e investigaremos. Você pode enviar sua notificação para: Email- Contact@instadent.in<br />Khasra No. 714, Village, P.O.- Chattarpur, Opp. DLF Gate No.-2, perto do Geetanjali Salon New Delhi, Índia, 110074",
          tc45:"Como a Instadent responde a direitos autorais ou outras violações de propriedade intelectual?",
          tc44:"A Instadent pode, de tempos em tempos, contratar certas afiliadas ou outros terceiros para fornecer serviços técnicos ou outros serviços relacionados a toda ou parte da Plataforma, ou executar certas funções associadas à operação dos recursos da Instadent e Você concorda que tal envolvimento de terceiros é aceitável.<br />Direito de atualizar nosso software.<br />Em conexão com qualquer modificação de qualquer parte da Plataforma, a Instadent pode baixar automaticamente atualizações de software em seus computadores e dispositivos de tempos em tempos com a intenção de melhorar, aprimorar , reparando e/ou desenvolvendo ainda mais a Plataforma. A Instadent tentará fornecer a Você a opção de instalar ou não a atualização; no entanto, em certas circunstâncias (por exemplo, riscos de segurança), a Instadent pode exigir que você instale a atualização para continuar acessando a Plataforma. Em todos os casos, Você concorda em permitir que a Instadent forneça essas atualizações a Você (e que Você as receba) como parte do Seu uso da Plataforma.",
          tc43:"Direito de contratar terceiros.",
          tc42:"Mantemos o direito, a nosso exclusivo critério, de implementar novos elementos como parte e/ou auxiliares da Plataforma Instadent, incluindo alterações que possam afetar os modos anteriores de operação da Plataforma. Esperamos que tais modificações melhorem o Serviço em geral, mas é possível que Você não concorde conosco. Também nos reservamos o direito de estabelecer limites à natureza ou tamanho do armazenamento disponível para Você, ao número de itens em Sua lista de compras ou à Sua capacidade contínua de acessar ou compartilhar Seu Conteúdo e outros dados, e impor outras limitações a qualquer momento, com ou sem aviso prévio.",
          tc41:"Direito de Modificar a Plataforma.",
          tc40:"Ao concordar com estes Termos, você também concorda que os direitos da Plataforma Instadent, incluindo todos os direitos de propriedade intelectual, como marcas registradas, patentes, desenhos industriais, logotipos, símbolos e direitos autorais, são protegidos por um ou mais direitos autorais, marcas registradas, patentes , segredo comercial e outras leis, regulamentos e tratados, além destes Termos e de qualquer acordo separado. Em particular, você concorda em não modificar, criar trabalhos derivados, descompilar ou de outra forma tentar extrair o código-fonte de qualquer software Instadent, a menos que você esteja expressamente autorizado a fazê-lo sob uma licença de código aberto ou nós lhe demos permissão expressa por escrito. A marca registrada Instadent é licenciada pela Instadent IP, LLC. Todas as marcas registradas da Instadent são registradas e propriedade da Instadent IP, LLC. Você não pode usar o nome ou marcas registradas da Instadent sem o consentimento expresso por escrito da Instadent IP, LLC.",
          tc39:"Direito de propriedade intelectual.",
          tc38:"usar o Instadent de qualquer maneira que viole nossos Termos ou quaisquer leis locais, estaduais, federais ou internacionais.",
          tc37:"desmontar ou fazer engenharia reversa do Instadent; ou",
          tc36:"redistribuí-lo de qualquer maneira, incluindo, entre outros, venda, licença, arrendamento, aluguel, assinatura ou qualquer outro mecanismo de distribuição;",
          tc35:"usar bots, spiders, crawlers ou outras técnicas de scraping para roubar conteúdo do Instadent;",
          tc34:"desenvolver um site ou aplicativo móvel concorrente ou ajudar alguém a fazer o mesmo;",
          tc33:"Você não pode usar o Instadent para:",
          tc32:"Há outras restrições ao meu uso?",
          tc31:"Os Termos de Serviço constituem um contrato entre a Instadent e Você e regem o uso dos aplicativos móveis e on-line da Instadent (“Aplicativos”), os serviços fornecidos por meio dos Aplicativos ou de nosso site (o “Serviço”) ou qualquer outra parte de nossos negócios. (coletivamente a “Plataforma”). Instadent é um dba da Vasa Denticity Pvt Ltd. As disposições estabelecidas neste documento e outros termos ou condições que podem ser apresentados por nós e aceitos por Você de tempos em tempos em conexão com o uso da Plataforma Instadent (todos os quais nós coletivamente referido como “Termos de Serviço” ou “Termos”) constituirá o contrato entre Você e nós. Se Você não concordar com estes Termos, Você não terá o direito de acessar, usar ou comprar nossos Aplicativos ou Serviços. Se você se registrar ou usar a Plataforma, ou adquirir qualquer Serviço da Instadent, será considerado que você aceitou os Termos e concordou em ser parte deste contrato vinculativo.<br />Ao usar qualquer parte do Plataforma Instadent Você reconhece, aceita e concorda com todas as disposições da Política de Privacidade, incluindo, sem limitação, o uso e tratamento das Informações da Sua Conta e do Seu Conteúdo de acordo com tal Política de Privacidade.",
          tc30:"Quais são os Termos de Serviço?",
          tc29:"Você é responsável por cumprir todas as leis aplicáveis, incluindo leis de marcas registradas e direitos autorais com relação a todo o Conteúdo criado, transmitido, armazenado ou exibido em Sua conta. Ao usar o Instadent e concordar com estes Termos, você garante que não usará o Instadent para infringir os direitos de propriedade intelectual de terceiros de forma alguma. De acordo com a DMCA e outras leis aplicáveis, poderemos, a nosso exclusivo critério, remover ou desativar Conteúdo alegadamente sujeito a infração ou outra atividade, ou poderemos encerrar Sua conta.",
          tc28:"Você não tem o direito de transmitir sob nenhuma lei (por exemplo, leis de propriedade intelectual) ou sob relações contratuais ou fiduciárias (por exemplo, acordos de não divulgação); ou<br />a critério exclusivo da Instadent, seja questionável ou que restrinja ou iniba qualquer outra pessoa de usar ou desfrutar da Instadent, ou que possa expor a Instadent, nossas afiliadas ou nossos Usuários a qualquer dano ou responsabilidade de qualquer tipo.",
          tc27:"contenha qualquer publicidade, solicitação, material promocional não solicitado ou não autorizado, “junk mail”, “spam”, “correntes”, “esquemas de pirâmide” ou qualquer outra forma de solicitação;",
          tc26:"contenha vírus de software ou qualquer outro código de computador, arquivos ou programas projetados para interromper, destruir ou limitar a funcionalidade de qualquer software ou hardware de computador ou equipamento de telecomunicações, ou para extrair informações da Instadent;",
          tc25:"contém qualquer informação privada de terceiros, incluindo, sem limitação, endereços, números de telefone, endereços de e-mail, números fiscais ou de identificação emitidos pelo governo e números de cartão de crédito;",
          tc24:"links diretos ou indiretos para quaisquer materiais aos quais você não tenha o direito de vincular;",
          tc23:"prejudica, ameaça, difama, promove violência ou atividades ilegais, ou é de outra forma vulgar, obsceno, abusivo, assediante, tortuoso, calunioso, invasivo da privacidade de outra pessoa, odioso, ou racial, eticamente ou de outra forma censurável;",
          tc22:"viole qualquer patente, marca registrada, segredo comercial, direito autoral ou outros direitos de propriedade de qualquer parte;",
          tc21:"viole quaisquer leis locais, estaduais, federais ou internacionais;",
          tc20:"Sim. O uso da Plataforma deve estar de acordo com estes Termos. Quando se trata do uso da Plataforma, você concorda que é responsável por sua própria conduta e por toda conduta sob sua conta. Isso significa todo o conteúdo – incluindo atualizações de preços, textos, imagens, software, vídeos e qualquer outra coisa que Você possa imaginar, independentemente da forma ou estrutura técnica (coletivamente, “Conteúdo”) – criado, transmitido, armazenado ou exibido em Sua conta. , é de Sua exclusiva responsabilidade como pessoa que criou o Conteúdo ou o introduziu na Plataforma. Isto se aplica quer o Conteúdo seja mantido privado, compartilhado ou transmitido usando a Plataforma ou qualquer aplicativo ou serviço de terceiros. Se descobrirmos que qualquer Conteúdo compartilhado em Sua conta viola nossos Termos de Serviço (inclusive violando os direitos de propriedade intelectual de outra pessoa), nos reservamos o direito de remover tal conteúdo.<br />Você concorda que não fará upload, publicará ou de outra forma transmitir qualquer Conteúdo que:",
          tc19:"Existem regras sobre o conteúdo que envio para Instadent?",
          tc18:"Finalmente, você entende e concorda que a Instadent, ao executar as etapas técnicas necessárias para fornecer o Serviço e os Aplicativos aos nossos usuários, poderá fazer quaisquer alterações que a Instadent considere necessárias para conformar e adaptar esse Conteúdo aos requisitos técnicos de conexão de redes, dispositivos, serviços ou meios de comunicação.",
          tc17:"Assim que Sua conta for criada e Você aceitar estes Termos, concedemos a Você uma licença limitada e não exclusiva para usar a Plataforma sujeita a estes Termos, desde que Você não esteja impedido de receber o Serviço ou usar os Aplicativos sob as leis aplicáveis. para Você, até que Você feche Sua conta voluntariamente ou até que fechemos Sua conta de acordo com estes Termos. Além disso, concedemos a Você uma licença pessoal, mundial, isenta de royalties, intransferível e não exclusiva para usar a Plataforma Instadent fornecida a Você por ou em nome da Instadent, com o único propósito de permitir que Você use a Plataforma Instadent. e aproveitar o benefício do Serviço, sujeito a quaisquer termos de licença aplicáveis ​​fornecidos com a Plataforma Instadent e estes Termos, até que Seus direitos sejam rescindidos de acordo com tal licença e/ou estes Termos. Você não obtém nenhum outro direito ou interesse na Instadent ou em qualquer parte da Plataforma.",
          tc16:"Depois de ter uma conta, quais são os meus direitos com a Instadent?",
          tc15:"O compartilhamento de contas instantâneas é proibido. Se você compartilhar as informações da sua conta com alguém, essa outra pessoa poderá assumir o controle da conta e talvez não consigamos determinar quem é o titular adequado da conta. Não teremos qualquer responsabilidade perante você (ou qualquer pessoa com quem você compartilhe suas informações de conta) como resultado de suas ações ou de suas ações nessas circunstâncias. Como você pode usar uma conta de serviço gratuita e como fornecemos vários mecanismos para permitir que você compartilhe o conteúdo da sua conta com outras pessoas, recomendamos fortemente que você não compartilhe as informações da sua conta com ninguém.",
          tc14:"Posso compartilhar minha conta?",
          tc13:"Em segundo lugar, você precisará acessar sua conta por meio de um navegador da web. A obtenção de um dispositivo móvel e o pagamento de seus planos de conectividade e dados é de Sua responsabilidade. A Instadent não se responsabiliza pela disponibilidade da Internet e de outros serviços de telecomunicações necessários para acessar o Serviço.",
          tc12:"Primeiro, você precisa criar uma conta Instadent. Você cria uma conta fornecendo-nos um número de celular que já está associado ao WhatsApp e é necessário preencher o “OTP” com o mesmo número e também preenche o nome, ID de e-mail, endereço (coletivamente “Informações da conta”). mantendo a precisão, integridade e confidencialidade das informações da sua conta, e você será responsável por todas as atividades que ocorrerem em sua conta, incluindo atividades de outras pessoas a quem você forneceu as informações da sua conta. Não seremos responsáveis ​​por qualquer perda ou dano decorrente de sua falha em nos fornecer informações precisas ou em manter as informações de sua conta seguras.",
          tc11:"O que devo fazer para usar o serviço Instadent?",
          tc10:"É quase certo que alterações nestes Termos ocorrerão devido a alterações em nossa Plataforma e nas leis que se aplicam à Instadent e a você. Se fizermos alguma alteração, faremos esforços razoáveis ​​para notificá-lo com antecedência. Qualquer atualização destes Termos entrará em vigor 30 dias após a promulgação das alterações. Anunciaremos as alterações aqui em nosso site e também poderemos optar por notificá-lo sobre as alterações enviando um e-mail para o endereço que você nos forneceu.<br />Se atualizarmos estes Termos, você será livre para decidir se aceitar os termos atualizados ou parar de usar nossa Plataforma; Seu uso continuado da Plataforma após a entrada em vigor dessa atualização será considerado como representação de Sua concordância e consentimento em ficar vinculado aos novos Termos. Se Você não concordar com os novos Termos, Você poderá cancelar Seu serviço a qualquer momento.",
          tc9:"Estes Termos de Serviço serão alterados?",
          tc8:"A Plataforma foi projetada e oferecida para uso apenas por adultos. A Plataforma não se destina a indivíduos com menos de 18 anos nem a qualquer pessoa que não atenda a nenhuma das outras qualificações incluídas nestes Termos.",
          tc7:"Quem pode usar a plataforma Instadent?",
          tc6:"Termos.",
          tc5:"O Serviço Instadent permite que você verifique preços de produtos e crie listas de compras, pesquise produtos, bem como use outros recursos relacionados à criação de uma lista odontológica em vários dispositivos de computador e aplicativos móveis. Em troca de poder usar o Serviço, você concorda em cumprir estes",
          tc4:"O que é o serviço instantâneo?",
          tc2:"Instadent é o proprietário deste site, dos aplicativos Instadent e de todos os outros serviços Instadent. Ao longo dos Termos de Serviço, Instadent pode ser referido como “nós” ou “nós” ou “nosso”.",
          tc1:"Este acordo é celebrado e considerado em vigor a partir da data em que você, ou, se você representa uma entidade ou outra organização, essa entidade ou organização (em ambos os casos, “Você”) acessa ou usa o Instadent pela primeira vez ou quando Você cria um conta com Instadent.",
          tc3:"Um “Membro” é alguém que se registrou no Instadent. Um Membro pode criar uma “Conta”, que é uma representação online do Membro, que pode conter Informações de Identificação Pessoal (conforme definido na Política de Privacidade) relativas a esse Membro.",
          tc:"Termos e Condições",
          rr1:"DEVOLUÇÃO, REEMBOLSO, CANCELAMENTO E TAXAS DE ENVIO:",
          rr2:"Oferecemos devoluções e reembolsos dos produtos e serviços encomendados por Você no Site, que estão sujeitos a termos e condições adicionais, conforme detalhado na política de cancelamento, despesas de envio, devoluções e reembolso (“Política de Devolução e Reembolso”). A Política de Devolução e Reembolso é parte integrante destes Termos e os Usuários são solicitados a lê-los atentamente.",
          rr3:"Política de cancelamento",
          rr4:"Como posso cancelar um pedido?",
          rr5:"Equipamento",
          rr6:"O pedido de produtos de saúde pode ser cancelado na tela “Página de Pedido” no site/aplicativo Instadent ('Plataforma') ou entrando em contato com nosso Suporte ao Cliente antes de ser marcado como “Aceito”. Caso contrário, você pode recusar no momento da entrega e um reembolso será processado de acordo com a Política de Reembolso.",
          rr7:"Em qualquer caso de exceção, a empresa poderá cancelar o pedido, a empresa não será responsável por isso.",
          rr8:"Política de devolução",
          rr9:"Qual o prazo para devolução das encomendas?",
          rr10:"Temos uma política de devolução específica do produto. O período de devolução pode variar de acordo com o produto. O prazo de devolução é de 10 dias a partir da data de entrega. As informações sobre a política de devolução podem ser encontradas na página de login. Por favor, verifique a política de devolução antes de fazer um pedido.",
          rr11:"[Observação: atualmente, a Empresa não cobra nenhuma taxa de envio por devoluções suas.]",
          rr12:"Politica de reembolso",
          rr13:"Nota adicional",
          rr14:"Itens cujo período de devolução expirou não podem ser devolvidos.",
          rr15:"Quando posso esperar que o valor seja reembolsado?",
          rr16:"Observe que o valor do reembolso mencionado no momento da devolução na Plataforma é uma estimativa e será finalizado após a conclusão da verificação. Assim que a coleta for concluída e o produto verificado pela empresa, será iniciado um reembolso do valor total pago pelos produtos devolvidos por você para devoluções elegíveis conforme mencionado acima.",
          rr17:"O reembolso será iniciado somente após a verificação bem-sucedida dos produtos pela Empresa. Observe que o processo de verificação pode levar:",
          rr18:"Para cidades locais*: 48 horas a partir do momento da retirada em sua localidade.",
          rr19:"Método de pagamento",
          rr20:"Prazo de reembolso (após verificação bem-sucedida da farmácia/fornecedor terceirizado)",
          rr21:"Pagamento na entrega [Observação: para este tipo de pagamento, o reembolso será iniciado através do link RazorpayX COD para preencher os detalhes da sua conta",
          rr22:"dia de negócios",
          rr23:"Reembolso on-line",
          rr24:"dias úteis, sujeitos ao tempo de resposta do banco e às Diretrizes RBI.",
          rr25:"Você entende e reconhece que nos reservamos o direito de reclamar qualquer reembolso que tenha sido processado por nós como resultado de atividades fraudulentas, como a devolução de produtos errados ou a quantidade errada de produtos.",
          PP40:"QUESTÕES?",
          PP41:"Entre em contato conosco se tiver alguma dúvida sobre esta declaração.",
          PP37:"SUA PERMISSÃO",
          PP39:"Se decidirmos alterar a nossa política de privacidade, publicaremos essas alterações nesta página para que você esteja sempre ciente de quais informações coletamos, como as utilizamos e em que circunstâncias as divulgamos.",
          PP38:"Ao usar o Site e/ou fornecer suas informações, você concorda com a coleta e uso das informações divulgadas no Site de acordo com esta Política de Privacidade, incluindo, mas não se limitando ao Seu consentimento para compartilhar suas informações de acordo com esta política de privacidade. .",
          qty:"quantidade",
          No:"Não",
          Yes:"Sim",
          ohno:"Oh não!",
          EACH:"CADA",
          Sizes:"Tamanhos",
          Cookies:"Biscoitos:",
          PRIVACYPOLICY:"POLÍTICA DE PRIVACIDADE",
          PP1:"Valorizamos a confiança que você deposita em nós. É por isso que insistimos nos mais altos padrões detransações seguras e privacidade das informações do cliente.Por favor, leia a seguinte declaração para saber mais sobre nossa coleta de informaçõese práticas de divulgação.",
          PP2:"Nossa política de privacidade está sujeita a alterações a qualquer momento sem aviso prévio. Para ter certeza de que você está ciente de quaisquer alterações, revise esta política periodicamente.",
          Note:"Observação",
          PP4:"Pelo simples uso do Site, você concorda expressamente com o uso e divulgação de suas informações pessoais de acordo com esta Política de Privacidade. Esta Política de Privacidade está incorporada e sujeita aos Termos de Uso.",
          PP3:"Ao visitar este site você concorda em ficar vinculado aos termos e condições desta Política de Privacidade. Se você não concordar, por favor não use ou acesse nosso site.",
          PP5:"COLETA DE INFORMAÇÕES PESSOALMENTE IDENTIFICÁVEIS E OUTRAS INFORMAÇÕES",
          PP6:"Quando você usa nosso site, coletamos e armazenamos suas informações pessoais que são fornecidas por você de tempos em tempos. Nosso principal objetivo ao fazer isso é fornecer a você uma experiência segura, eficiente, tranquila e personalizada. Isso nos permite fornecer serviços e recursos que provavelmente atendam às suas necessidades e personalizar nosso site para tornar sua experiência mais segura e fácil. Mais importante ainda, ao fazer isso, coletamos suas informações pessoais que consideramos necessárias para atingir esse objetivo.",
          PP7:"Em geral, você pode navegar no Site sem nos informar quem você é ou revelar qualquer informação pessoal sobre você. Depois de nos fornecer suas informações pessoais, você não será anônimo para nós. Sempre que possível, indicamos quais campos são obrigatórios e quais campos são opcionais. Você sempre tem a opção de não fornecer informações, optando por não usar um determinado serviço ou recurso do Site. Podemos rastrear automaticamente certas informações sobre você com base no seu comportamento em nosso site. Usamos essas informações para fazer pesquisas internas sobre a demografia, interesses e comportamento de nossos usuários para melhor compreender, proteger e servir nossos usuários. Esta informação é compilada e analisada de forma agregada. Essas informações podem incluir o URL de onde você acabou de chegar (este URL está em nosso site ou não), qual URL você irá em seguida (este URL está em nosso site ou não), as informações do navegador do seu computador e seu endereço IP .",
          PP8:"Utilizamos dispositivos de coleta de dados, como cookies, em determinadas páginas do Site para ajudar a analisar o fluxo da nossa página web, medir a eficácia promocional e promover confiança e segurança. Cookies são pequenos arquivos colocados em seu disco rígido que nos auxiliam na prestação de nossos serviços. Oferecemos determinados recursos que só estão disponíveis através do uso de um cookie.",
          PP9:"Também usamos cookies para permitir que você insira sua senha com menos frequência durante uma sessão. Os cookies também podem nos ajudar a fornecer informações direcionadas aos seus interesses. A maioria dos cookies são “cookies de sessão”, o que significa que são automaticamente excluídos do seu disco rígido no final de uma sessão. Você é sempre livre para recusar nossos cookies se seu navegador permitir, embora nesse caso você não consiga usar determinados recursos do Site e possa ser solicitado a digitar novamente sua senha com mais frequência durante uma sessão.",
          PP10:"Também usamos a localização para oferecer melhores serviços de entrega por meio da entrega hiperlocal",
          PP11:"Além disso, você poderá encontrar “cookies” ou outros dispositivos semelhantes em determinadas páginas do Site que são colocadas por terceiros. Não controlamos o uso de cookies por terceiros.",
          PP12:"Se você optar por comprar no Site, coletaremos informações sobre seu comportamento de compra.",
          PP13:"Se você realizar transações conosco, coletaremos algumas informações adicionais, como endereço de cobrança, número de cartão de crédito/débito e data de validade do cartão de crédito/débito e/ou outros detalhes de instrumentos de pagamento e informações de rastreamento de cheques ou ordens de pagamento.",
          PP14:"Se você optar por postar mensagens em nossos quadros de mensagens, salas de bate-papo ou outras áreas de mensagens ou deixar comentários, coletaremos as informações que você nos fornecer. Retemos essas informações conforme necessário para resolver disputas, fornecer suporte ao cliente e solucionar problemas conforme permitido por lei.",
         PP15:"Se você nos enviar correspondência pessoal, como e-mails ou cartas, ou se outros usuários ou terceiros nos enviarem correspondência sobre suas atividades ou postagens no Site, poderemos coletar tais informações em um arquivo específico para você.",
          PP16:"Coletamos informações de identificação pessoal (endereço de e-mail, nome, número de telefone, detalhes de cartão de crédito/débito/outros instrumentos de pagamento, etc.) quando você cria uma conta gratuita conosco. Embora você possa navegar em algumas seções do nosso site sem ser um membro registrado, certas atividades (como fazer um pedido) exigem registro. Usamos suas informações de contato para enviar ofertas com base em seus pedidos anteriores e em seus interesses.",
          PP17:"USO DE DADOS DEMOGRÁFICOS / DE PERFIL / SUAS INFORMAÇÕES",
          PP18:"Usamos informações pessoais para fornecer os serviços que você solicita. Na medida em que usarmos suas informações pessoais para fazer marketing para você, forneceremos a você a capacidade de cancelar tais usos. Usamos suas informações pessoais para resolver disputas; solucionar problemas; ajudar a promover um serviço seguro; coletar dinheiro; medir o interesse do consumidor em nossos produtos e serviços, informá-lo sobre ofertas, produtos, serviços e atualizações online e offline; personalize sua experiência; detectar e proteger-nos contra erros, fraudes e outras atividades criminosas; fazer cumprir nossos termos e condições; e conforme descrito a você no momento da coleta.",
         PP19:"Em nossos esforços para melhorar continuamente nossas ofertas de produtos e serviços, coletamos e analisamos dados demográficos e de perfil sobre a atividade de nossos usuários em nosso Site.",
          PP20:"Identificamos e utilizamos o seu endereço IP para ajudar a diagnosticar problemas com o nosso servidor e para administrar o nosso Site. Seu endereço IP também é usado para ajudar a identificá-lo e coletar informações demográficas amplas.",
         PP21:"Ocasionalmente, solicitaremos que você preencha pesquisas on-line opcionais. Essas pesquisas podem solicitar informações de contato e informações demográficas (como CEP, idade ou nível de renda). Utilizamos estes dados para personalizar a sua experiência no nosso Website, fornecendo-lhe conteúdos que pensamos que lhe possam interessar e para exibir conteúdos de acordo com as suas preferências.",
          PP22:"Um cookie é um pequeno pedaço de informação armazenado por um servidor web em um navegador web para que possa ser lido posteriormente a partir desse navegador. Os cookies são úteis para permitir que o navegador se lembre de informações específicas de um determinado usuário. Colocamos dados permanentes e cookies temporários no disco rígido do seu computador. Os cookies não contêm nenhuma informação de identificação pessoal.",
          PP23:"COMPARTILHAMENTO DE INFORMAÇÕES PESSOAIS",
          PP24:"Poderemos compartilhar informações pessoais com outras entidades corporativas e afiliadas. Essas entidades e afiliadas poderão comercializar para você como resultado de tal compartilhamento, a menos que você opte explicitamente por não participar.",
          PP25:"Poderemos divulgar informações pessoais a terceiros. Esta divulgação pode ser necessária para fornecermos a você acesso aos nossos Serviços, para cumprir nossas obrigações legais, para fazer cumprir nosso Contrato de Usuário, para facilitar nossas atividades de marketing e publicidade ou para prevenir, detectar, mitigar e investigar atividades fraudulentas ou ilegais. relacionados aos nossos serviços. Não divulgamos suas informações pessoais a terceiros para fins de marketing e publicidade sem o seu consentimento explícito.",
          PP26:"Poderemos divulgar informações pessoais se formos obrigados a fazê-lo por lei ou se acreditarmos de boa fé que tal divulgação é razoavelmente necessária para responder a intimações, ordens judiciais ou outros processos legais. Poderemos divulgar informações pessoais a autoridades policiais, terceiros proprietários de direitos ou outros, na crença de boa-fé de que tal divulgação é razoavelmente necessária para: fazer cumprir nossos Termos ou Política de Privacidade; responder a reclamações de que um anúncio, postagem ou outro conteúdo viola os direitos de terceiros; ou proteger os direitos, propriedade ou segurança pessoal de nossos usuários ou do público em geral.",
          PP27:"Nós e nossas afiliadas compartilharemos/venderemos algumas ou todas as suas informações pessoais com outra entidade empresarial caso nós (ou nossos ativos) planejemos uma fusão ou aquisição por essa entidade empresarial, ou reorganização, fusão, reestruturação de negócios. Caso tal transação ocorra, outra entidade comercial (ou a nova entidade combinada) será obrigada a seguir esta política de privacidade com relação às suas informações pessoais.",
          PP28:"LINKS PARA OUTROS SITES",
          PP29:"Nosso site tem links para outros sites que podem coletar informações de identificação pessoal sobre você. Instadent.in não é responsável pelas práticas de privacidade ou pelo conteúdo desses sites vinculados.",
          PP30:"PRECAUÇÕES DE SEGURANÇA",
          PP31:"Nosso site possui medidas de segurança rigorosas para proteger a perda, uso indevido e alteração das informações sob nosso controle. Sempre que você altera ou acessa as informações da sua conta, oferecemos o uso de um servidor seguro. Uma vez que suas informações estejam em nossa posse, aderimos a rígidas diretrizes de segurança, protegendo-as contra acesso não autorizado.",
          PP32:"ESCOLHA/CANCELAMENTO",
          PP33:" Oferecemos a todos os usuários a oportunidade de optar por não receber comunicações não essenciais (promocionais, relacionadas a marketing) nossas em nome de nossos parceiros e de nós em geral, após a criação de uma conta. ",
          PP34:"Se você deseja remover suas informações de contato de todas as listas e boletins informativos do Instadent.in, visite nosso suporte ao cliente",
          PP35:"ANÚNCIOS NO Instadent.in",
          PP36:"Utilizamos empresas de publicidade terceirizadas para veicular anúncios quando você visita nosso site. Essas empresas podem usar informações (não incluindo seu nome, endereço, endereço de e-mail ou número de telefone) sobre suas visitas a este e outros sites para fornecer anúncios sobre produtos e serviços de seu interesse.",
          orderplaced:"Seu pedido foi feito",
          nodelivery:"Não estamos entregando no local informado.",
          defaultaddress:"Torne-o o endereço padrão.",
          Submit:"Enviar",
          entername:"Digite seu nome",
          entermail:"Digite seu e-mail",
          enterphone:"Digite seu telefone",
          expectedtime:"Qual é o tempo estimado de entrega?",
          query:"Para qualquer dúvida preencha o formulário abaixo.",
          thanksforcontacting:"Obrigado por nos contatar!",
          assist:"Alguém irá ajudá-lo em breve",
          contacttext:"AngoCart é a maior e mais conveniente empresa de entrega hiperlocal de Angola que permite aos clientes fazer pedidos de alimentos, higiene e cuidados pessoais, bebidas, Baby Care e muitos mais produtos que os clientes precisam todos os dias, diretamente por meio de seu celular ou navegador da web.",
          ShippingPolicy:"Política de Envio",
          sp1:"A entrega é feita em até 3 horas do mesmo dia para pedidos feitos antes das 15h.",
          sp2:"Pedidos após as 15h são entregues no dia seguinte até às 11h.",
          sp3:"Como será feita a entrega?",
          sp4:"Processamos todas as entregas através de nossa própria frota de passageiros. Em alguns casos, onde o seu código PIN está um pouco fora da nossa zona de entrega principal, contamos com o suporte dos nossos parceiros de entrega Dunzo, Borzo, Porter etc.",
          sp5:"Como os itens são embalados?",
          sp6:"Todos os itens estão em suas embalagens naturais. Os itens são enviados para você em um saco de papel orgânico reciclado, capaz de suportar tanto a temperatura quanto o peso dos produtos.",
          suggest:"Sugira algo e nós analisaremos.",
          suggest1:"Sugira um produto",
          cart1:"Seu carrinho contém itens do endereço anterior. Deseja descartar a seleção e usar este endereço?",
          removecart:"Remover itens do carrinho?",
          findproduct:"não encontrou o que procurava?",
          shippingcharges:"Quais são os custos de envio?",
          writequery:"Escreva sua consulta",
          phone:"Telefone",
          Downloadsummary:"Baixar resumo",
          BillDetails:"Detalhes da fatura",
          subtotal:"Subtotal (incluindo impostos)",
          BillTotal:"Total da fatura",
          support:"O suporte está sempre disponível",
          chatsupport:"Converse com o executivo de suporte",
          issuerelated:"Sobre quaisquer problemas relacionados ao seu pedido",
          whycancel:"Por que você deseja cancelar este pedido?",
          nodel:"Nenhuma taxa de entrega é aplicável ao seu pedido, o valor mínimo do pedido será 500 para o 2º pedido de um cliente individual",
          order1:"resumo do pedido",
          itemorder:"Item nesta ordem",
          expectingdelivery:"Esperando entrega em",
          rr:"Devolução e substituição",
          DeliverTo:"Entregar para",
          OrderedAt:"Encomendado em",
          CancelOrder:"Cancelar pedido",
          PaymentType:"Tipo de pagamento",
          Save:"Salvar",
          detect:"Detectar minha localização",
          newad4:"A dicionar endereço",
          per:"As permissões de localização não são fornecidas",
          myloc:"Detectar minha localização",
          mypin:"Insira o código PIN de entrega",
          needhelp:"Precisa de ajuda com o seu pedido ?",
          Title:"Título",
          Mr:"Senhor",
          Mrs:"Sra",
          newad:"Adicionar novo endereço de entrega",
          newad3:"As permissões de localização não são fornecidas",
          newad2:"Por favor, insira o endereço correto, isso nos ajudará a atendê-lo melhor",
          Miss:"Perder",
          Other:"Outro",
          Continue:"Continuar",
          Pincode:"Código PIN",
          add1:"Apartamento / Casa / Escritório Não",
          add2:"Rua/Sociedade/Nome do Escritório",
          Office:"Escritório",
          tamount:"Montante total:",
          tpa:"Pode pagar em dinheiro ou por TPA",
          cashod:"Pagamento na entrega",
          ViewCart:"Ver carrinho",
          headtext:"Não encontrou o que procura? Por favor, sugira o produto.",
          Thanks:"Obrigado pela visita",
          Send:"Enviar",
          Brand:"Marca",
          Productname:"Nome do Produto",
          Quantity:"Quantidade",
          confirm:"Por favor confirme se você deseja",
          wish1:"Não há nenhum produto na lista de desejos.",
          GSTNo:"Nº do ICMS",
          Profile:"Perfil",
          Orders:"Pedidos",
          Delete:"Excluir",
          Addresses:"Endereços",
          Name:"Nome",
          MobileNo:"Número do celular",
          Wishlist:"Lista de Desejos",
          Cancel:"Cancelar",
          SavedAddress:"Endereço salvo",
          OrderDetails:"detalhes do pedido",
          DeliveredOrders:"Pedidos entregues",
          OrderID:"ID do pedido",
          DeliveryCharges:"Taxas de entrega",
          didfind:"Não encontrei seu produto clique para sugerir.",
          addressadd:"Clique aqui para definir o código PIN/endereço",
          address1:"Você não tem endereços salvos",
          address2:"Diga-nos onde você deseja que seus pedidos sejam entregues",
          AddNewAddress:"Adicionar novo endereço",
          SelectDeliveryAddress:"Selecione o endereço de entrega",
          Discount:"Desconto",
          Edit:"Editar",
          title: "Applicazione multilingue",
          contactUs: "Contate-nos",
          searchPlaceholder: "Você também pode gostar",
          about: "Su di me",
          home: "Casa",
          usefulLink:"Links Úteis",
          Categories:"Categorias",
          SeeAll:"Ver tudo",
          DownloadApp:"Baixar aplicativo",
          Subscribe:"Inscreva-se em nosso canal e receba as últimas atualizações.",
          Subscribebtn:"Se inscrever",
          scam:"Não se preocupe, não enviamos spam.",
          mail:"Digite seu e-mail",
          cart:"Meu carrinho",
          delivery:"entrega",
          Items:"Unid",
          EmptyCart:"Carrinho Vazio",
          ADD:"ADICIONAR",
          AddtoWishlist:"Adicionar a lista de desejos",
          Inclusiveofalltaxes:"inclusão de todos os impostos",
          ProductFeatures:"Características do produto",
          ProductSpecifications:"especificações do produto",
          ProductDescription:"Descrição do produto",
          ProductDetails:"Detalhes do produto",
          buynow:"COMPRAR AGORA",
          OpenOrders:"Pedidos em aberto",
          CartIsEmpty:"Carrinho esta vazio",
          Promocode:"Código promocional/cupom",
          pleaseselect:"Selecione os itens para fazer seu pedido.",
          promoadded:"Código promocional adicionado",
          Coupon:"Cupom",
          Change:"Mudar",
          Proceed:"Continuar",
          ASSOCIATED:"NOSSOS PARCEIROS ASSOCIADOS",
          Login:"Conecte-se",
          LogOut:"Sair",
          Number: "Verificação de número de telefone",
          Enterlogin: "Digite seu número de telefone para fazer login/cadastrar-se",
          otp:"Digite o código de 4 dígitos enviado para o seu telefone",
          Resend:"Reenviar",
          Next:"Próxima",
          var1:"Ao continuar você concorda com nossos",
          var2:"termos de serviço",
          var3:"e",
          var4:"política de Privacidade",
          off:"desligada",
          WhyAngocart:"Por que escolher a Angocart",
          h1:"Entrega extremamente rápida",
          description1:"Receba qualquer produto à sua porta com nossos parceiros de entrega rápida.",
          h2:"Preços mais baixos e melhores ofertas",
          description2:"Produtos de qualidade genuína a preços mais econômicos, juntamente com ofertas e pontos de recompensa.",
          h3:"Amplo",
          description3:"Escolha produtos de diferentes categorias de eletrodomésticos em uma interface amigável.",
          h4:"Devolução/reembolso sem complicações",
          description4:"Em caso de qualquer problema, devolução ou reembolso fácil para uma experiência agradável após a compra.",
          Youmayalsolike:"você pode gostar",
          TrendingProducts:"Produtos em alta",
          banner:"Aproveite o pagamento na entrega e transferência bancária e ganhe até 30% de desconto! SE APRESSE! Use 📦DCODE5 e ganhe 5% de desconto extra."
        },
      },
    },
  });

export default i18n;
