import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import userImage from "../../../assests/user_img.png";
import editIcon from "../../../assests/edit_icon_img.png"
import "./commonSideTabs.css"
import LogoutPopup from "../logoutPopup/LogoutPopup";
import { toast } from "react-toastify"
import { editProfileImage, editProfile } from "../../../utils/apiUrlsSetup"
import { useTranslation } from "react-i18next";
const CommonSideTabs = ({ activeTab, userDetails }) => {

    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    const [activeSideTabs, setActiveSideTabs] = useState(activeTab ? activeTab : "profile");
    const [showLogoutModal, setShowLogoutModal] = useState(false)


    const userLogOut = () => {
        localStorage.removeItem("loginNumber");
        localStorage.removeItem("authToken");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("pincode");
        localStorage.removeItem("address");
        navigate("/")
    }


    const editUserProfileImage = async (event) => {

        const formData = new FormData()
        formData.append("profile_image", event.target.files[0])
        const response = await editProfileImage(formData)
        if (response.ErrorCode === 0) {

            editUserProfile()
        }
    }

    const editUserProfile = async () => {

        const formData = new FormData()
        formData.append("doctor_name", userDetails.username)
        formData.append("clinic_name", userDetails.clinic_name)
        formData.append("gstin", userDetails.gstin)
        formData.append("email", userDetails.email)

        const response = await editProfile(formData)
        if (response.ErrorCode === 0) {

            localStorage.removeItem("userDetails")
            localStorage.setItem("userDetails", JSON.stringify(response.Response))
            toast.success("Profile Updated Successfully")
            window.location.reload()
        }
    }

    const handleLogoutClick = () =>{
        setActiveSideTabs("logout")
        setShowLogoutModal(true)
    }

    return (
        <>
            <div className="common_side_bar position-relative">
                <div className="edit_icon">
                    <input type="file" id="profileImageEdit"                               
                        accept="image/*"    
                    className="d-none" onChange={editUserProfileImage} />
                    <label htmlFor="profileImageEdit">
                        <img src={editIcon} alt="" className=" rounded-circle cursor-pointer" />
                    </label>
                </div>

                <img src={userDetails && userDetails.profile_image ? userDetails.profile_image : userImage} alt="" className="userprofile_image w-100 px-5 mb-3 rounded-circle" />
                <p className="m-0 text-center text-secondary fs-6 mb-4 font_sub_heading">{userDetails.username ? userDetails.username : "Username"}</p>
                <ul className="list-unstyled">
                    <li className={`p-2 border-bottom border-top ${activeSideTabs === "profile" ? "active" : null}`} onClick={() => { setActiveSideTabs("profile"); navigate("/account/profile") }}>
                        <p className="m-0">
                            <i class="fa-solid fa-user mx-2 text-secondary"></i>
                            <span className="ms-1 text-secondary font_basic">{t("Profile")}</span>
                        </p>
                    </li>
                    <li className={`p-2 border-bottom ${activeSideTabs === "address" ? "active" : null}`} onClick={() => { setActiveSideTabs("address"); navigate("/account/addresses") }}>
                        <p className="m-0">
                            <i class="fa-solid fa-location-dot mx-2 text-secondary"></i>
                            <span className="ms-1 text-secondary font_basic">{t("Addresses")}</span>
                        </p>
                    </li>
                    <li className={`p-2 border-bottom ${activeSideTabs === "order" ? "active" : null}`} onClick={() => { setActiveSideTabs("order"); navigate("/account/order") }}>
                        <p className="m-0">
                            <i class="fa-solid fa-cart-shopping mx-2 text-secondary"></i>
                            <span className="text-secondary font_basic">{t("Orders")}</span>
                        </p>
                    </li>
                    <li className={`p-2 border-bottom ${activeSideTabs === "logout" ? "active" : null}`} onClick={handleLogoutClick}>
                        <p className="m-0">
                            <i class="fa-solid fa-user mx-2 text-secondary"></i>
                            <span className="text-secondary font_basic">{t("LogOut")}</span>
                        </p>
                    </li>
                </ul>
            </div>

            <LogoutPopup
                showLogoutModal={showLogoutModal}
                setShowLogoutModal={setShowLogoutModal}
                userLogOut={userLogOut}
            />
        </>
    )
}

export default CommonSideTabs