import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { featureBoxList } from "../../../utils/apiUrlsSetup";

const FooterTopCard = () => {
  const [cardData, setCardData] = useState([]);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    getCardData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const getCardData = async () => {
    try {
      const response = await featureBoxList({});

      if (isMounted.current) {
        if (response && response.Response){
          setCardData(response.Response); 
          // console.log(response.Response, "//////////////");
        } else {
          console.error("Invalid response data.");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <h4 className="title pt-4 pb-3 font_heading">
        <span className="carousel_heading">{t("WhyAngocart")}</span>
      </h4>
      {cardData.map((value, index) => (
        <div className="col-12 col-md-6 col-lg-3 footer_card_height mt-1" key={index}>
          <div className="p-3 border">
            <div className="d-flex justify-content-center">
              <div className="footer_features_icon">
                <img
                  alt={value.name}
                  src={value.image}
                  loading="lazy"
                  className="icon-image cUAuNp"
                />
              </div>
            </div>
            <div size="14" color="#000000" className="iZMVhO font_basic">
              {value.name}
            </div>
            <div size="12" color="#666666" className="text-center font_paragraph lh-sm" dangerouslySetInnerHTML={{ __html: value.desc }}>

              
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
export default FooterTopCard;
