import React, { useState, useRef } from "react";
import ArrowLeft from "../../../assests/arw_left.png"
import { 
    cashOnDelivery, 
    bankTransfer, 
    createOrder,
    payUOrder,
    muticaixaOrder,
    transactionUpdate 
} from "../../../utils/apiUrlsSetup"
import { toast } from "react-toastify"
import COD_img from "../../../assests/cod_image.jpg"
import BT_img from "../../../assests/bank-transfer.png"
import UPI_img from "../../../assests/upi_img.png"
import multicaixa from "../../../assests/multicaixa.png"
import "./selectPaymentMode.css"
import 'reactjs-popup/dist/index.css';
import Loader from "../loader/loader";
import { useCallback } from "react";
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SelectPaymentMode = ({ selectPaymentMode, setOrderId,setOrderInstruction, setSelectPaymentMode, setShowCart, cartListData, getCartListData }) => {

    const[t,i18n]=useTranslation();
    const [currencyCode, setCurrencyCode] = useState('Kz')
    const authToken = localStorage.getItem("authToken") || "";
    const Razorpay = useRazorpay();
    const [selectedImg, setSelectedImg] = useState("");
    const [showLoader, setShowLoader] = useState(false)
    
    const [orderBtnDisabled, setOrderBtnDisabled] = useState(false);
    
    const [set_key, setKey] = useState("");
    const [set_txnid, setTxnId] = useState("");
    const [set_amount, setAmount] = useState("");
    const [set_productinfo, setProductInfo] = useState("");
    const [set_firstname, setFirstName] = useState("");
    const [set_lastname, setLastname] = useState("");
    const [set_email, setEmail] = useState("");
    const [set_surl, setsUrl] = useState("");
    const [set_furl, setfUrl] = useState("");
    const [set_phone, setPhone] = useState("");
    const [set_returnlocation, setReturnLocation] = useState("");
    const [set_hash, setHash] = useState("");
    const navigate = useNavigate();


    
    const placeOrderCOD = async () => {
        setOrderBtnDisabled(true);
        setShowLoader(true)
        const response = await cashOnDelivery({})
        if (response.ErrorCode === 0) {
            setOrderId(response.Response.order_id)
            setShowLoader(false)
            setOrderBtnDisabled(false)
            setSelectedImg(false)
            setSelectPaymentMode(false)
            // toast.success("Order Placed Successfully.")
            // setTimeout(()=>{window.location.reload()}, 3000)

        }
        else if (response.ErrorCode === -100) {
            toast.error(`${t("cartempty")}`)
            setOrderBtnDisabled(false)
            setSelectedImg(false)
        }
        else if (response.ErrorCode === -111) {
            toast.error(`${t("paymentmethod1")}`)
            setShowLoader(false)
            setOrderBtnDisabled(false)
            setSelectedImg(false)
            setSelectPaymentMode(false)
        }
    }

    const placeOrderBankTransfer = async () => {
        setOrderBtnDisabled(true);
        setShowLoader(true)
        const response = await bankTransfer({})
        if (response.ErrorCode === 0) {
            setOrderId(response.Response.order_id)
            setOrderInstruction(response.Response.bank_details)
            setShowLoader(false)
            setOrderBtnDisabled(false)
            setSelectedImg(false)
            setSelectPaymentMode(false)
            // toast.success("Order Placed Successfully.")
            // setTimeout(()=>{window.location.reload()}, 3000)

        }
        else if (response.ErrorCode === -100) {
            toast.error(`${t("cartempty")}`)
            setOrderBtnDisabled(false)
            setSelectedImg(false)
        }
        else if (response.ErrorCode === -111) {
            toast.error(`${t("paymentmethod1")}`)
            setShowLoader(false)
            setOrderBtnDisabled(false)
            setSelectedImg(false)
            setSelectPaymentMode(false)
        }
    }

    const backToCartView = () => {
        setSelectPaymentMode(false)
        setShowCart(true)
    }

    const placeMultiCaixaOrder = async () => {
        setOrderBtnDisabled(true);
        setShowLoader(true)
        const formData = new FormData();
        formData.append("platform", "Web")
        formData.append("token", authToken)
        const response = await muticaixaOrder(formData)
        if (response.ErrorCode === 0) {
            let redirectUrl = "https://pagamentonline.emis.co.ao/online-payment-gateway/portal/frame?token="+response.Response.token.id
            window.location.href = redirectUrl
            // navigate({ pathname: redirectUrl });
        }
        else if (response.ErrorCode === -100) {
            toast.error(`${t("cartempty")}`)
            setOrderBtnDisabled(false)
            setSelectedImg(false)
        }
        else if (response.ErrorCode === -111) {
            toast.error(`${t("paymentmethod1")}`)
            setShowLoader(false)
            setOrderBtnDisabled(false)
            setSelectedImg(false)
            setSelectPaymentMode(false)
        }
    }



    const placePayUOrder = async () => {
        setOrderBtnDisabled(true);
        setShowLoader(true)
        const response = await payUOrder({"location":window.location.href})
        if (response.ErrorCode === 0) {
            // setOrderId(response.Response.order_id)
            setKey(response.Response.param.key)
            setTxnId(response.Response.param.txn_id)
            setAmount(response.Response.param.amount)
            setProductInfo(response.Response.param.productinfo)
            setFirstName(response.Response.param.firstname)
            setLastname(response.Response.param.lastname)
            setEmail(response.Response.param.email)
            setPhone(response.Response.param.phone)
            setsUrl(response.Response.param.surl)
            setfUrl(response.Response.param.furl)
            setReturnLocation(response.Response.param.udf1)
            setHash(response.Response.hash)
            if(response.Response.param.amount)
            {
                document.myForm.submit()
            }
            

            // setShowLoader(false)
            // setOrderBtnDisabled(false)
            // setSelectedImg(false)
            // setSelectPaymentMode(false)
            // toast.success("Order Placed Successfully.")
            // setTimeout(()=>{window.location.reload()}, 3000)

        }
        else if (response.ErrorCode === -100) {
            toast.error(`${t("cartempty")}`)
            setOrderBtnDisabled(false)
            setSelectedImg(false)
        }
        else if (response.ErrorCode === -111) {
            toast.error(`${t("paymentmethod1")}`)
            setShowLoader(false)
            setOrderBtnDisabled(false)
            setSelectedImg(false)
            setSelectPaymentMode(false)
        }
    }





    const handlePaymentOnline = useCallback(async () => {
        setOrderBtnDisabled(true)
        setShowLoader(true)
        const formData = new FormData();
        formData.append("token", authToken)

        const order = await createOrder(formData);
        if(order.ErrorCode === 0){
            setShowLoader(false)
            const options = {
                key: "rzp_live_bXcp9inxQER7yv",
                amount: order.Response.razorpay_order.amount,
                currency: order.Response.razorpay_order.currency,
                name: order.Response.razorpay_order.name,
                description: order.Response.razorpay_order.description,
                image: order.Response.razorpay_order.image,
                order_id: order.Response.razorpay_order.id,
                handler: (res) => {
                    // console.log(res);
                    if(res){
                        updateTransactionByIds(res)
                    }
                },
                prefill: {
                    name: "Instadent",
                    email: "test123@gmail.com",
                    contact: "9999999999",
                },
                notes: {
                    address: "Instadent Office",
                },
                theme: {
                    color: "#3399cc",
                },
            };
    
            const rzpay = new Razorpay(options);
            rzpay.open();
        }
        else{
            toast.error(`{t("paymentmethod1")}`)
            setShowLoader(false)
            setOrderBtnDisabled(false)
            setSelectedImg(false)
            setSelectPaymentMode(false)
        }

    },[Razorpay]);

    const updateTransactionByIds = async(data) =>{
        const formData = new FormData()
        formData.append("razorpay_order_id", data.razorpay_order_id)
        formData.append("razorpay_payment_id", data.razorpay_payment_id)
        // console.log(data)
        // console.log(formData)
        const response = await transactionUpdate(formData)
        if(response.ErrorCode === 0){
            setShowCart(false)
            setSelectPaymentMode(false)
            setOrderId(response.Response.idc_order_id)
        }
    }

    return (
        <>
            {selectPaymentMode ? <div className="overlay"></div> : null}
            <div
                className={`offcanvas offcanvas-end right_cart_list ${selectPaymentMode ? "show visible" : null}`}
                tabindex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
            >
                <div className="offcanvas-header mb-1 d-flex align-items-center">
                    <img
                        src={ArrowLeft}
                        alt=""
                        height="20px"
                        width="22px"
                        className="cursor-pointer mb-2"
                        onClick={backToCartView}
                    />
                    <p className="m-0 fs-5 font_500 pb-2">{t("BillTotal")} : <span className="fs-6">  {currencyCode} {cartListData?.discount ?  cartListData.total && Math.floor(cartListData.total)-Math.floor(cartListData?.discount?.discount): cartListData.total && cartListData.total ? Math.floor(cartListData.total) : 0 }</span></p>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={() => { setSelectPaymentMode(false) }}
                    >
                    </button>
                </div>
                <div className="offcanvas-body p-0">
                    <div className="top_div border-bottom px-3 pb-1">
                        <span className="d-flex justify-content-between align-items-center">
                            <h6 className="m-0 fw-bold py-1 lh-sm">{t("paymentmode")}</h6>
                        </span>
                    </div>

                    <div className="cart_container_select_payment">
                        <div className="total_bill_amount px-3 rounded">
                            {/* <div className='py-3'>
                                <div className="d-flex justify-content-between align-items-center mb-4 payment_methods_heading">
                                    <p className="m-0 fs-5 ps-3 font_500">Razor Pay</p>
                                    <i class="fa-solid fa-angle-right pe-3 fs-5"></i>
                                </div>
                                <div className="row m-0">
                                    <div className={`col-4 pe-0 payment_methods ${selectedImg === "Online" ? "img_select" : ""} `}>
                                        <img
                                            src={UPI_img}
                                            alt=""
                                            className='cursor-pointer'
                                            onClick={() => setSelectedImg("Online")}
                                        />
                                    </div>
                                </div>
                            </div> */}

                            <div className='py-3'>
                                <div className="d-flex justify-content-between align-items-center mb-4 payment_methods_heading">
                                    <p className="m-0 fs-5 ps-3 font_500">Multicaixa Online</p>
                                    <i class="fa-solid fa-angle-right pe-3 fs-5"></i>
                                </div>
                                <div className="row m-0">
                                    <div className={`col-4 pe-0 payment_methods ${selectedImg === "multiCaixaPayment" ? "img_select" : ""} `}>
                                        <img
                                            src={multicaixa}
                                            alt=""
                                            className='cursor-pointer'
                                            onClick={() => setSelectedImg("multiCaixaPayment")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='py-3'>
                                <div className="d-flex justify-content-between align-items-center mb-4 payment_methods_heading">
                                    <p className="m-0 fs-5 ps-3 font_500">{t("banktransfer")}</p>
                                    <i class="fa-solid fa-angle-right pe-3 fs-5"></i>
                                </div>
                                <div className="row m-0">
                                    <div className={`col-4 pe-0 payment_methods ${selectedImg === "BankTransfer" ? "img_select" : ""} `}>
                                        <img
                                            src={BT_img}
                                            alt=""
                                            className='cursor-pointer'
                                            onClick={() => setSelectedImg("BankTransfer")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='py-3'>
                                <div className="d-flex justify-content-between align-items-center mb-4 payment_methods_heading">
                                    <p className="m-0 fs-5 ps-3 font_500">{t("cashod")}</p>
                                    <i class="fa-solid fa-angle-right pe-3 fs-5"></i>
                                </div>
                                <div className="row m-0">
                                    <div className={`col-4 pe-0 payment_methods ${selectedImg === "Cod" ? "img_select" : ""} `}>
                                        <img
                                            src={COD_img}
                                            alt=""
                                            className='cursor-pointer'
                                            onClick={() => setSelectedImg("Cod")}
                                        />
                                    </div>
                                </div>
                                <div className=" total_amount position-absolute bg-white pt-2">
                                    <button
                                        type='button'
                                        className='px-3 py-2 ms-3 bg_green mt-2 rounded text-white d-block place_order_btn'
                                        onClick={selectedImg === "Cod" ? placeOrderCOD : selectedImg === "multiCaixaPayment" ? placeMultiCaixaOrder : placeOrderBankTransfer}
                                        disabled={orderBtnDisabled || !selectedImg}
                                    >
                                        {t("PlaceOrder")}
                                    </button>

                                </div>

                                {showLoader ? <Loader bottom="50%" right="47%" /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            <form action='https://secure.payu.in/_payment' name="myForm" className="ppform" method='post'>
                <input type="hidden" name="key" value={set_key} />
                <input type="hidden" name="txnid" value={set_txnid} />
                <input type="hidden" name="productinfo" value={set_productinfo} />
                <input type="hidden" name="amount" value={set_amount} />
                <input type="hidden" name="email" value={set_email} />
                <input type="hidden" name="firstname" value={set_firstname} />
                <input type="hidden" name="lastname" value={set_lastname} />
                <input type="hidden" name="surl" value={set_surl} />
                <input type="hidden" name="furl" value={set_furl}/>
                <input type="hidden" name="phone" value={set_phone} />
                <input type="hidden" name="hash" value={set_hash} />
                <input type="hidden" name="udf1" value={set_returnlocation} />
                <input type="submit" value="submit"/> 
            </form>
            </div>

            <style jsx>
                {`
                
                body{
                    overflow: ${selectPaymentMode ? "hidden" : ''}
                }
                .ppform{
                    height:0;
                    visibility:hidden;
                }
                `}
            </style>
        </>
    )
}

export default SelectPaymentMode;