import React from "react";
import Header from "../shared-components/header/header"
import Footer from "../shared-components/footer/footer"
import { useTranslation } from "react-i18next";
const RefundAndReplace = () => {
     const { t, i18n } = useTranslation();
    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};

    return <>
        <Header
            userDetails={userDetails}
        />
        <div className="row m-0 refund_page">
            <div className="col-0 col-md-1"></div>
            <div className="col-12 col-md-10">
                <div class="">
                    <p>
                        <strong>{t("rr1")}</strong>
                    </p>
                    <p>
                      {t("rr2")}
                    </p>
                    <p>
                        &nbsp;
                    </p>
                    <p>
                        <strong>{t("rr3")}</strong>
                    </p><ol><li><strong> {t("rr4")}</strong></li></ol>
                    <p>
                        1.1&nbsp;<u>{t("rr5")}</u>
                    </p>
                    <p>
                       {t("rr6")}
                    </p>
                    <p>
                    {t("rr7")}
                    </p>
                    <p>
                        <strong><u>  {t("rr8")}</u></strong>
                    </p><ol aria-start="2"><li><strong>   {t("rr9")}</strong></li></ol>
                    <p>
                        1.1&nbsp;<u>  {t("rr5")}</u>
                    </p>
                    <p>
                    {t("rr10")}  
                    </p>
                    <p>
                        <em>  {t("rr11")}</em>
                    </p>
                    <p>
                        &nbsp;
                    </p>
                    <p>
                        <strong>  {t("rr13")} –</strong>
                    </p><ol aria-start="3"><li><strong>  {t("rr14")} </strong></li></ol>
                    <p>
                        &nbsp;
                    </p>
                    <p>
                        &nbsp;
                    </p>
                    <p>
                        <strong><u>  {t("rr12")}</u></strong>
                    </p><ol><li><strong>  {t("rr15")} </strong></li></ol>
                    <p>
                        1.1   {t("rr16")}</p>
                    <p>
                        1.2   {t("rr17")}
                    </p>
                    <p>
                    {t("rr18")} 
                    </p>
                    <p>
                        &nbsp;
                    </p><table width="796"><tbody><tr><td width="308">
                        <p>
                            <strong>  {t("rr19")}</strong>
                        </p></td><td>
                            <p>
                                <strong>{t("rr20")}</strong>
                            </p></td></tr><tr><td width="308">
                                <p>
                                {t("rr21")} 
                                </p></td><td>
                                <p>
                                    1   {t("rr22")}
                                </p></td></tr><tr><td width="308"></td></tr><tr><td width="308">
                                    <p>
                                       {t("rr23")}
                                    </p></td><td>
                                <p>
                                7-10  {t("rr24")} 
                                </p></td></tr><tr><td width="308"></td><td></td></tr></tbody></table>
                    <p>
                     1.3 {t("rr25")} 
                    </p></div>
            </div>
            <div className="col-0 col-md-1"></div>
        </div>
        <Footer />

        <style jsx>
            {`
        
        .refund_page{
            margin-top: 120px !important;
        }

        `}
        </style>
    </>

}

export default RefundAndReplace;