import React from "react";
import "./categoriesCard.css";
import { useNavigate } from "react-router-dom";
import productImage from "../../../assests/product_img.png";

const CategoriesCard = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{ flexWrap: "wrap" }}
        className="category_list category_parent d-flex m-0 mt-10 lg:justify-center flex-row overflow-auto"
      >
        {props.categoryList.map((val, index) => {
          return (
            <div
              className="category_card cursor-pointer p-3 px-0 px-md-3 my-4 "
              onClick={() => {
                navigate({ pathname: `/product-category/${val.category_slug}/:id` });
              }}
              key={index}
            >
              <div className="product">
                <img src={val.icon ? val.icon : productImage} alt="product_image" />
              </div>
              <div className="category_name">
                <p className="m-0 mt-1 text-center lh-sm font_basic">
                  {val.category_name}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CategoriesCard;
