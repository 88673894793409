import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Header, Footer } from './../shared-components';
import { contactUsRequest, categoryData } from './../../utils/apiUrlsSetup';
import Loader from '../shared-components/loader/loader';
import emailIcon from './../../assests/email.png';
import logo from './../..//assests/logo.png'
import addressIcon from './../../assests/address.png';
import GoogleMap from './Googlemap';
import { useTranslation } from "react-i18next";
const ContactUs = (props) => {
  const { t, i18n } = useTranslation();
  const pincode = localStorage.getItem('pincode') || '';
  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || {};
  const [badgeCount, setBadgeCount] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  const authToken = localStorage.getItem('authToken') || '';
  const [successMessage, setSuccessMessage] = useState(false);
  const [detail, setDetail] = React.useState({
    name: props?.location?.state?.profileDetails?.name || '',
    email: props?.location?.state?.profileDetails?.email || '',
    phone: localStorage.getItem('phone') || '',
    query: props?.location?.state?.profileDetails?.query || '',
  });

  const handleChange = (e) => {
    setDetail({ ...detail, [e?.target?.name]: e?.target?.value });
  };

  const handleSubmit = () => {
    setShowLoader(true);
    let requestForOpenContactUs = {
      name: detail.name,
      mobile_no: detail.phone,
      email: detail.email,
      subject: detail.query,
      query: detail.query,
    };
    let requestForContactUs = {
      name: detail.name,
      mobile_no: localStorage.getItem('phone') || detail.phone,
      email: detail.email,
      subject: detail.query,
      query: detail.query,
    };
    let contactusRequest = localStorage.getItem('token')
      ? requestForContactUs
      : requestForOpenContactUs;

    const getSocialLinkList = async () => {
      let response = await contactUsRequest(contactusRequest);
      console.log(response);
      if (response && response.ErrorCode === 0) {
        setShowLoader(false);
        setSuccessMessage(true);
      }
    };
    getSocialLinkList(contactusRequest);
  };

  return (
    <>
      {showLoader ? <Loader bottom="50%" right="47%" /> : null}
      <Header userDetails={userDetails} badgeCount={badgeCount} />
      <div className="container px-3 mt-12 mb-5 pb-3 mb-lg-0 pb-lg-0">
        <div className="row m-0 contact_us_page pt-20 ">
        <p className='contactushead'><strong>{t("contactUs")}</strong></p>

          <div className="col-lg-6 col-12 order-2 order-lg-1">
            <div className="bg-white lg:p-4">
              <h3 className="text-xl font-bold py-4 flex-1">
              HEAD OFFICE (ANGOLA)
              </h3>
              <strong>ANGO-ZARA COMÉRCIO E INDÚSTRIA, LDA</strong>
              <p>
              Centro De Logistica E Distribuicao,<br/>
              KM-27, Estrada de Catete,Viana<br/>
              Luanda-Angola.            
              </p>                            
              
            </div>
       
            <div className="bg-white lg:p-4">

           
              {/* <h3 className="text-xl font-bold py-4 flex-1">
                ANGO-ZARA COMÉRCIO E INDÚSTRIA, LDA
              </h3> */}
              {/* <p className="flex">
                <img className="mr-2" src={emailIcon} width="20px" />
                <a href="mailto:info@angocart.com" className="text-sm font-bold">
                  info@angocart.com
                </a>
              </p> */}
              {/* <p className="text-sm font-bold flex items-start mt-2">
                <img className="mr-1" width="25px" height="25px" src={addressIcon} />
                Estrada de Catete,
                <br />
                KM 27 Entrada Principal de Metro
                <br />
                Luanda – Angola
                <br />
                <a href="tel:+244933888888" className="contents">
                  Helpline: +244 933 888 888
                </a>
                
              </p> */}
              
       
              {!successMessage ? (
                <div className="form">
                    
                  <h3 className="text-xl font-bold py-4 flex-1">
                   {t("query")}
                  </h3>
                  <div className="order-id flex flex-col">
                    <label>{t("Name")}*</label>
                    <input
                      placeholder= {`${t("entername")}`}
                      value={detail.name}
                      onChange={handleChange}
                      name="name"
                      className="my-3 w-full p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none text-xs"
                    />
                  </div>
                  <div className="order-id flex flex-col">
                    <label>Email*</label>
                    <input
                      placeholder={`${t("entermail")}`}
                      value={detail.email}
                      onChange={handleChange}
                      name="email"
                      className="my-3 w-full p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none text-xs"
                    />
                  </div>
                  <div className="order-id flex flex-col">
                    <label>{t("phone")}*</label>
                    <input
                      placeholder={`${t("enterphone")}`}
                      name="phone"
                      value={localStorage.getItem('phone') || detail.phone}
                      onChange={handleChange}
                      className="my-3 w-full p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none text-xs"
                    />
                  </div>

                  <div className="order-id flex flex-col">
                    <label>{t("writequery")}*</label>
                    <textarea
                      name="query"
                      onChange={handleChange}
                      className="my-3 w-full p-4 bg-white rounded-md shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none text-xs"
                    ></textarea>
                  </div>
                  <button
                    disabled={Object.values(detail).includes('')}
                    className={` ${
                      true ? 'disabled:opacity-50' : ''
                    } w-full my-5 rounded-full bg-primary text-md h-12 text-white cursor-pointer`}
                    onClick={handleSubmit}
                  >
                  {t("Submit")}
                  </button>
                </div>
              ) : (
                <div className="contact-us-response">
                  <div className="text-center">
                    <h3 className="">{t("thanksforcontacting")}</h3>
                    <p>{t("assist")}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          
          <div className="col-lg-6 col-12 order-1 order-lg-2">
            <div className="row m-0 d-flex ps-1 ms-1">
            <br/>
        <br/>
        <br/>
              <img src={logo} alt="Logo" className="logo-container "/>
              <p className="para">{t("contacttext")}</p>
            </div>
            <GoogleMap />
          </div>
          <div className="col-12">
    
          
          </div>
        </div>
        <Footer />
      </div>
      <style jsx>{`
        .contact_us_page {
          margin-top: 125px !important;
          box;
        }
        .contactushead{
            color: black;
            text-align:center;
            font-size:30px;
        }
        .para{
            color:grey;
        }
        .logo-container {
            max-width: 250px;
            display: block;
            margin: 0 auto;
            margin-top: 35px;
          }
        .contact-us-response {
          width: 100%;
          height: 500px;
          align-items: center;
          display: flex;
          justify-content: center;
          background: #eafefa;
          color: green;
        }
      `}</style>
    </>
  );
};

export default ContactUs;
