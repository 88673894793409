import React from "react";
import "./orderDetailTabs.css"
import { useTranslation } from "react-i18next";
const OrderDetailTabs = ({ selectedTab,reloadOrders }) => {
  

    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="row m-0 mx-1 mx-md-3 order_tab_bar rounded">
                <div className="col-6 col-md-6 p-0 px-md-2">
                    <p
                        className={`m-0 text-center py-1 font_basic cursor-pointer ${selectedTab === "open" ? "selected_tab_border" : ''}`}
                        onClick={()=>{reloadOrders("open")}}
                    >
                       {t("OpenOrders")}
                    </p>
                </div>
                <div className="col-6 col-md-6 p-0 px-md-2">
                    <p
                        className={`m-0 text-center py-1 font_basic cursor-pointer ${selectedTab === "completed" ? "selected_tab_border" : ''}`}
                        onClick={()=>{reloadOrders("completed")} }
                    >
                        {t("DeliveredOrders")}
                    </p>
                </div>
                {/* <div className="col-6 col-md-3 p-0 px-md-2">
                    <p
                        className={`m-0 text-center py-1 font_basic cursor-pointer ${selectedTab === "return" ? "selected_tab_border" : ''}`}
                        onClick={() => { reloadOrders("return") }}
                    >
                        Return
                    </p>
                </div>
                <div className="col-6 col-md-3 p-0 px-md-2">
                    <p
                        className={`m-0 text-center py-1 font_basic cursor-pointer ${selectedTab === "replacement" ? "selected_tab_border" : ''}`}
                        onClick={() => { reloadOrders("replacement") }}
                    >
                        Replacement
                    </p>
                </div> */}
            </div>
        </>
    )
}

export default OrderDetailTabs;