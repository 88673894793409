import "./profilePage.css"
import React, { useState, useEffect, useRef } from "react"
import { Header } from "../shared-components"
import CommonSideTabs from "../shared-components/commonSideTabs/commonSideTabs"
import editIcon from "../../assests/edit_icon_img.png"
import userImage from "../../assests/user_img.png"
import { editProfile, editProfileImage } from "../../utils/apiUrlsSetup"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next";
const ProfilePage = () => {

    const userProfileDetails = JSON.parse(localStorage.getItem("userDetails")) || {};
    const { t, i18n } = useTranslation();
    const [isDisabled, setIsDisabled] = useState(true)
    const [userDetails, setUserDetails] = useState(userProfileDetails)
    const uploadBtnRef = useRef();

    const editUserProfileImage = async (event) => {
        
        const formData = new FormData()
        formData.append("profile_image", event.target.files[0])
        const response = await editProfileImage(formData)
        if(response.ErrorCode === 0){
            
            editUserProfile()
        }
    }

    const setEditData = (name, e) => {
        const data = { ...userDetails }
        data[name] = e.target.value
        // console.log(data)
        setUserDetails(data)
    }

    const enableEditData = (e) => {
        e?.preventDefault();
        if (isDisabled) {
            setIsDisabled(false)
        }
    }



    const editUserProfile = async (e) => {
        e?.preventDefault();
        const formData = new FormData()
        formData.append("doctor_name", userDetails.username)
        formData.append("clinic_name", userDetails.clinic_name)
        formData.append("gstin", userDetails.gstin)
        formData.append("email", userDetails.email)
        formData.append("registration_no", userDetails.registration_no)

        const response = await editProfile(formData)
        if (response.ErrorCode === 0) {
            setUserDetails(response.Response)
            // console.log(response.Response)
            setIsDisabled(true)
            toast.success("Profile Updated Successfully")
            localStorage.removeItem("userDetails")
            localStorage.setItem("userDetails", JSON.stringify(response.Response))
        }

    }

    return (
        <>
            <Header 
                noShadow 
                userDetails={userDetails} 
            />
            <div className="row m-0 px-3 p-lg-0">
                <div className="col-0 col-lg-2 p-0"></div>
                <div className="col-12 col-lg-8 profile_page_inner profile_page_mobile p-0">
                    <div className="row m-0">
                        <div className="col-3 p-0 left_addresss_bar d-none d-md-block">
                            <CommonSideTabs
                                activeTab={"profile"} 
                                userDetails={userDetails} 
                            />
                        </div>
                        <form onSubmit={
                            isDisabled ? enableEditData : editUserProfile
                        } className="col-12 col-md-9 py-5 px-md-4 profile_custom_width_right">
                            <div className="profile_details ">
                                <div className="profile_image_main position-relative mx-auto">
                                    <div className="edit_icon">
                                        <input 
                                            ref={uploadBtnRef}
                                            type="file"
                                            id="profileImageEdit"
                                            accept="image/*"    
                                            onChange={editUserProfileImage}
                                            className="upload-btn" 
                                        />
                                        <img 
                                            onClick={()=>uploadBtnRef.current.click()}
                                            src={editIcon} 
                                            alt="" 
                                            className=" rounded-circle cursor-pointer upload-btn-edit-icon" 
                                            />
                                    </div>

                                    <img 
                                        src={userDetails.profile_image ? userDetails.profile_image : userImage} 
                                        alt="" 
                                        className="userprofile_image w-100 px-5 mb-3 rounded-circle" 
                                    />
                                </div>
                                <div className="user_profile_details px-md-5">
                                    <div className="mb-2">
                                        <label
                                            htmlFor="#"
                                            className="form-label font_13 text-secondary mb-1 required"
                                        >
                                           {t("Name")}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control font_family_common"
                                            placeholder=""
                                            disabled={isDisabled}
                                            value={userDetails.username}
                                            name="username"
                                            onChange={(e) => { setEditData("username", e) }}
                                            required
                                        />
                                    </div>

                                    {/* <div className="mb-2">
                                        <label
                                            htmlFor="#"
                                            className="form-label font_13 text-secondary mb-1 required"
                                        >
                                            Shop Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control font_family_common"
                                            placeholder=""
                                            disabled={isDisabled}
                                            value={userDetails.clinic_name}
                                            name="clinic_name"
                                            onChange={(e) => { setEditData("clinic_name", e) }}
                                            required
                                        />
                                    </div> */}

                                    {/* <div className="mb-2">
                                        <label
                                            htmlFor="#"
                                            className="form-label font_13 text-secondary mb-1"
                                        >
                                            Dental Council Registraion Number (DNC Number)
                                        </label>
                                        <input
                                            type="text"
                                            required
                                            className="form-control font_family_common"
                                            placeholder=""
                                            disabled={isDisabled}
                                            value={userDetails.registration_no}
                                            name="registration_no"
                                            onChange={(e) => { setEditData("registration_no", e) }}
                                        />
                                    </div> */}

                                    <div className="mb-2">
                                        <label
                                            htmlFor="#"
                                            className="form-label font_13 text-secondary mb-1 required"
                                        >
                                            {t("MobileNo")}
                                        </label>
                                        <input
                                            type="number"
                                            required
                                            className="form-control font_family_common"
                                            placeholder=""
                                            disabled={true}
                                            value={userDetails.mobile}
                                            name="mobile_number"
                                        />
                                    </div>

                                    <div className="mb-2">
                                        <label
                                            htmlFor="#"
                                            className="form-label font_13 text-secondary mb-1"
                                        >
                                           {t("GSTNo")}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control font_family_common"
                                            placeholder=""
                                            disabled={isDisabled}
                                            value={userDetails.gstin}
                                            name="gstin"
                                            onChange={(e) => { setEditData("gstin", e) }}
                                        />
                                    </div>

                                    <div className="mb-2">
                                        <label
                                            htmlFor="#"
                                            className="form-label font_13 text-secondary mb-1 required"
                                        >
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            required
                                            className="form-control font_family_common"
                                            placeholder=""
                                            disabled={isDisabled}
                                            value={userDetails.email}
                                            name="email"
                                            onChange={(e) => { setEditData("email", e) }}
                                        />
                                    </div>

                                    <button 
                                        type="submit" 
                                        className="bg_green px-4 py-2 mt-4 rounded text-white font_basic" 
                                    >
                                        {isDisabled ? `${t("Edit")}`: "Save Changes"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </>
    )
}

export default ProfilePage;