import React,{useState} from "react";
import productImage from "../../../assests/product_img.png"
import { useNavigate } from "react-router-dom"
import "./itemCard.css"

const ItemCard = ({ItemsList , returnBtn, orderNumber, combineReturnedItems}) =>{
    const [currencyCode, setCurrencyCode] = useState('Kz')

    const navigate = useNavigate()

    return(
        <>
            {ItemsList && ItemsList.map((data, index)=>{
                let isReturnable = true;
                if(returnBtn){
                    const returnItem = (combineReturnedItems?.find((item)=>item?.id===data?.id));
                    if(returnItem){
                     isReturnable = returnItem?.return_qty < data?.quantity;
                    }
                }
                     return <div className="row m-0 py-2 rounded items_list border" key={index}>
                        <div 
                            className="col-3 pe-0 pe-lg-2 col-lg-1 d-flex align-items-center cursor-pointer"
                            onClick={()=>{navigate(`/product/${data.product_name}/${data.id}`)}}
                        >
                            <div className="item_image border p-2 rounded">
                                <img src={data.product_image ? data.product_image : productImage} alt="itemImage" />
                            </div>
                        </div>
                        <div className={`${!returnBtn ? "col-9" : 'col-6'} col-lg-9`}>
                            <div className="item_name">
                                <p className="m-0 text-secondary">{data.product_name}</p>
                                <p className="m-0 text-secondary">Qty : {data.quantity || data.return_qty}</p>
                                <p className="m-0 d-flex">
                                    <span>{currencyCode}{Math.floor(data.offer_price) || Math.floor(data.discount_price)}</span>
                                    <span className="text-secondary text-decoration-line-through ms-3">{currencyCode}{Math.floor(data.price) || Math.floor(data.item_price)}</span>
                                </p>
                            </div>
                        </div>
                        <div className={`${!returnBtn ? "col-0" : 'col-3'} col-lg-2 d-flex justify-content-end align-items-center`}>
                            <div className="item_image d-flex justify-content-center align-items-center">
                                {returnBtn && isReturnable ?
                                <button 
                                    type="button"
                                    className="bg_green text-white rounded py-2 px-3 cursor-pointer" 
                                    onClick={()=>{navigate(`/account/order/return/${orderNumber}/${data.id}`, { state: orderNumber })}}
                                >
                                Return
                                </button> : null}
                            </div>
                        </div>
                    </div>
                    })  }
        </>
    )
}

export default ItemCard ;