import { pincodeBrand } from "../../../utils/apiUrlsSetup"
import React, { useState, useEffect } from "react";
import "./footer.css"
import productImage from "../../../assests/product_img.png"
import { useNavigate } from "react-router-dom";
import Brand from "../../../assests/brand-image.png"
import WhatsappIcon from "../../../assests/whatsapp-icon.png"
import FooterTopCard from "./footerTopCard"
import FooterPaymentPartner from "./footerPaymentPartner"
import FooterCategories from "./footerCategories"
import FooterUsefulLinks from "./footerUsefulLinks"
import FooterBottomContent from "./footerBottomContent"
import { useTranslation } from "react-i18next";
import {postEmailToAPI} from "../../../utils/apiUrlsSetup";
const Footer = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()
  const [brandList, setBrandList] = useState([])
  const [topTenBrands, setTopTenBrands] = useState([])
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [res, setres] = useState("");

  useEffect(() => {
    getBrandList()
  }, [])

  const getBrandList = async () => {
    const data = await pincodeBrand({})
    if (data.ErrorCode === 0) {
      setBrandList(data.Response)
    
      const tenBrands = data.Response
      setTopTenBrands(tenBrands)
    }
  }
  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
   

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(emailValue)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.trim() === "") {
      setError("Email is required");
      return;
    }
    if (emailError) {
      return;
    }
  
    try {
      const response = await postEmailToAPI({ email });
      console.log("API Response:", response);
      setres(response.Response);
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  return (
    <>
      <footer className="Footer__Wrapper-sc-14h3h0g-2 bJfrQB">

        <a href="https://wa.me/244933888888?text=hi" target="_blank" className="float">
          <img className="myfloat" src={WhatsappIcon}/>
        </a>
        <div className="row m-0">

          <FooterTopCard />
        </div>
        <div className="ms-md-3">
 
        </div> 
        <section className=" m-1 sc-bZkfAO dcFWhu FooterLinks__Grid-sc-12rhzht-0 kormfg d-block d-md-flex ">
          <div className=" m-1 col-12 col-xxl-3 col-lg-3">
            <FooterUsefulLinks />
          </div>
          <div className=" m-1 col-12 col-xxl-5 col-lg-5">
            <FooterCategories />

          </div>
          <div className="col-12 col-xxl-4  col-lg-4">

            <div class="subscription-box">
            
              <div class="bottom">
                <div class="info">
                {t("Subscribe")}
                </div>
                <form action="#" onSubmit={handleSubmit} >
                  <div class="input-box">
                    <input type="email" name="email"   value={email}
                onChange={handleEmailChange} placeholder={t("mail")}  required />

                    <input type="submit" name="subscribe" value={t("Subscribebtn")} />
                  </div>
                </form>
                {res&& <div className="res">{res}!!!!</div>}
                {emailError && <div className="error-message">{emailError}</div>}

                <div class="footer">{t("scam")}</div>
              </div>
            </div>
          </div>


        </section>
        {/* <div className="PaymentsRow__ListHeading-sc-p5takb-4 dtHCZH font_heading">
          Payment partners
        </div>
        <div className="PaymentsRow__Row-sc-p5takb-0 etmxvz">
          <FooterPaymentPartner />
          <div className="PaymentsRow__Partner-sc-p5takb-3 cqMUOv">
            <div className="PaymentsRow__Font-sc-p5takb-1 bALhSz font_basic">Net Banking</div>
          </div>
          <div className="PaymentsRow__Partner-sc-p5takb-3 cqMUOv">
            <div className="PaymentsRow__Font-sc-p5takb-1 bALhSz font_basic">Cash on Delivery</div>
          </div>
          <div className="PaymentsRow__Partner-sc-p5takb-3 cqMUOv">
            <div className="PaymentsRow__Font-sc-p5takb-1 bALhSz font_basic">Instadent cash</div>
          </div>
        </div> */}
      </footer>

      <FooterBottomContent />
      <style jsx>
        {`

        .float{
          position:fixed;
          width:60px;
          height:60px;
          bottom:40px;
          right:40px;
          background-color:#FFF;
          color:#FFF;
          border-radius:50px;
          text-align:center;
          box-shadow: 2px 2px 3px #999;
        }

        .my-float{
          margin-top:22px;
        }
        .res{
          // background-color:red;
          color :red;
        }

        .brand_image{
          width:80px;
          height:80px;
          padding:2px ;
          border: 1px solid gray ;
        }

        .brand_card_ui{
          width:80px !important;
        }

        .brand_image img{
          width:100%;
          height:100%;
        }
        .footer_card_height{
        } 
        
        .footer_card_height > div{
          min-height:220px;
          border-radius: 12px ;
        }

        .bJfrQB {
            width: auto;
            max-width: 100%;
            margin: 20px auto 40px;
        }
        @media screen and (max-width: 1980px){
          .bJfrQB {
            max-width: 1280px;
          }
        }

        .hIMhHn {
            gap: 40px;
            padding-top: 40px;
        }
        .flJZea {
            display: grid;
            max-width: 90rem;
            align-items: flex-start;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        .dFvtUi {
            border-radius: 16px;
            border: 0.5px solid rgb(221, 221, 221);
            padding: 31px;
            display: flex;
            flex-direction: column;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
        }
        .footer_features_icon {
            // background: rgb(249, 249, 249);
            width: 60px;
            height: 60px;
            border-radius: 50px;
            margin: 18px 0;
            // margin-bottom: 24px;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
        }
        .iNeJeA {
            width: 100%;
            height: 100%;
        }
        .iZMVhO {
            font-size: 14px;
            text-align: center;
            color: rgb(0, 0, 0);
            font-family: Okra;
            margin-bottom: 8px;
            font-weight: 600;
        }
        .ggXHCa {
            font-size: 12px;
            text-align: center;
            color: rgb(102, 102, 102);
            font-family: Okra;
            margin-bottom: 8px;
            font-weight: 400;
        }
        .eAeyYs {
            font-size: 14px;
            color: rgb(102, 102, 102);
            padding: 40px 0px;
            border-bottom: 1px solid rgb(238, 238, 238);
            font-family: OKRA-REGULAR;
            line-height: 18px;
        }
        .dcFWhu {
            display: grid;
            align-items: flex-start;
            grid-template-columns: 1fr 1fr;
        }
        .bZlgle {
            display: flex;
            flex-direction: row;
        }
        .aonOx {
            font-family: Okra;
            font-weight: 600;
            color: rgb(0, 0, 0);
            font-size: 18px;
            margin: 24px 0px;
        }
        .flTNGy {
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            display: flex;
            padding-left: 16px;
            color: rgb(12, 131, 31);
            font-size: 16px;
        }
        .jPIBOn {
            list-style: none;
            padding: 0px;
            display: grid;
            grid-template-columns: auto auto;
            gap: 5px 10px;
        }
        .BvTiN {
            display: inline-block;
            font-size: 14px;
        }
        
        .hOxyYr {
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            width: auto;
            background-color: rgb(252, 252, 252);
            max-width: 100%;
        }
        .ewIa-Dc {
            list-style: none;
            padding: 0px;
            display: flex;
            flex-wrap: wrap;
            gap: 12px 30.5px;
        }
        .jqmuYk {
            list-style: none;
            padding: 0px;
            display: grid;
            grid-template-columns: auto;
            gap: 5px 10px;
        }
        .BvTiN {
            display: inline-block;
            font-size: 14px;
        }
        .jqmuYk {
            list-style: none;
            padding: 0px;
            display: grid;
            grid-template-columns: auto;
            gap: 5px 10px;
        }
      
        .dtHCZH {
            font-family: Okra;
            font-weight: 600;
            color: rgb(0, 0, 0);
            font-size: 18px;
            margin: 24px 0px;
        }

        .etmxvz {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            gap: 4px 19px;
            width: 100%;
        }
        .cqMUOv {
            width: 96px;
            height: 64px;
            border-radius: 4px;
            text-align: center;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            border: 1px solid rgb(242, 242, 242);
        }
        .hKlCIk {
            width: 60px;
            height: 52px;
        }
        .bALhSz {
            font-weight: 500;
        }
        .fBgyNM {
            list-style: none;
            padding: 0px;
            display: grid;
            grid-template-columns: auto auto auto;
            gap: 12px 32px;
        }        
        .eGalFF {
            padding: 16px 0px;
            width: auto;
            max-width: 1280px !important;
        }
        .aeTYn {
            display: grid;
            max-width: 90rem;
            align-items: flex-start;
            grid-template-columns: 1fr 1fr 1fr;
        }
        .bLeZQJ {
            display: flex;
            -webkit-box-pack: start;
            justify-content: flex-start;
            -webkit-box-align: center;
            align-items: center;
            flex-wrap: wrap;
            
            height: 100%;
            color: #666;
            font-size: 12px;
            font-family: 'Okra', Helvetica;
        }
        .cPxShR {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            height: 100%;
        }
        .hnVuxl {
            font-family: Okra;
            font-weight: 600;
            font-size: 14px;
            margin: 16px;
        }
        .gyBsbb {
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            align-items: center;
        }
        .kMTqFF {
            width: 92px;
            height: 30px;
            border-radius: 4px;
            margin: 8px;
            cursor: pointer;
        }
        
        .hIHTNb {
            width: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            background: linear-gradient(to right, rgb(248, 248, 248) 0%, rgb(255, 255, 255) 10%, rgb(248, 248, 248) 40%, rgb(248, 248, 248) 100%) no-repeat rgb(248, 248, 248);
            opacity: 0;
            transition: opacity 0.25s ease-out 0s;
            will-change: opacity;
            border-radius: inherit;
            animation: 1.5s linear 0s infinite normal forwards running cypEgR;
        }
        .app-download-icons {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: none;
            opacity: 1;
            will-change: transform, opacity;
            border-radius: inherit;
            filter: brightness(0.95);
            transition: transform 0.4s ease-in-out 0s, filter 0.4s ease 0s, opacity 0.25s ease 0s;
        }
        .jgvGfq {
            display: flex;
            -webkit-box-pack: end;
            justify-content: flex-end;
            -webkit-box-align: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            height: 100%;
        }
        .gyBsbb {
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            align-items: center;
        }
        
        .iSSvsj {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            height: 100%;
        }

        .kfNqsf {
            margin: 0px 12px;
            -webkit-box-pack: center;
            justify-content: center;
            cursor: pointer;
            -webkit-box-align: center;
            align-items: center;
        }

        @media screen and (max-width: 900px){
          .etmxvz {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            gap: 8px 42px;
            width: 100%;
            flex-wrap: wrap;
        }

        .kfNqsf {
            margin: 0px 0 0 12px;
            -webkit-box-pack: center;
            justify-content: center;
            cursor: pointer;
            -webkit-box-align: center;
            align-items: center;
        }
      }


        @media screen and (max-width: 600px){
          .etmxvz {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            flex-wrap: wrap;
        }

        .bJfrQB {
            width: auto;
            max-width: 1280px;
            margin: 0px auto 40px;
        }
        .cqMUOv {
              width: 86px;
              height: 64px;
              border-radius: 4px;
              text-align: center;
              display: flex;
              -webkit-box-pack: center;
              -webkit-box-align: center;
              align-items: center;
              border: 1px solid rgb(242, 242, 242);
          }
      }
        
        `}
      </style>
    </>
  );
};
export default Footer;
