
// // import React,{useState, useRef} from "react"
// // import "./categoryHeaderList.css"
// // import {Link} from "react-router-dom"
// // import { useNavigate } from "react-router-dom";
// // import arrow from "../../../assests/arrow_drop.png"
// // import UseOutsideClick from "../detectOutsideClick/useOutSideClick"


// // const CategoryHeaderList = ({categoryHeaderData, moreData, categoryId, setCategoryId}) =>{

// //     const navigate = useNavigate();

// //     const ref = useRef()
// //     const [showDropdown, setShowDropdown] = useState(false)
    
// //     const toggleDropdown = () => {
// //         setShowDropdown(!showDropdown)
    
// //     }

// //     UseOutsideClick(ref,()=>{
// //         if(showDropdown){
// //             setShowDropdown(false)
// //         }
// //     })

// //     return <>
// //         <div className="category_header row m-0 border-bottom d-lg-flex">
// //             <div className="col-0 col-lg-1 bg-white"></div>
// //             <div className="col-12 col-lg-10 pt-3 pb-1 d-flex justify-content-center bg-white">
// //                 <ul className="list-unstyled d-flex flex-wrap m-0 py-1">
// //                     {(categoryHeaderData || []).map((data, index) => {
// //                         return <li className="list-item" onClick={() =>{
// //                             navigate({ pathname: `/product-category/${data.category_slug}/:id` });
// //                         }} key={index}>
// //                             <Link to="#" className={`header_item_link text-decoration-none fs-6 px-3 py-2 font_basic text-secondary ${categoryId === data.category_slug ? "active_div" : ''}`}>{data.category_name ? data.category_name : null}</Link>
// //                         </li>
// //                     })}
// //                     {moreData.length>0?
// //                         <div class="_more_dropdown more_dropdown position-relative">
// //                             <span className=" text-secondary cursor-pointer d-flex font_basic" onClick={toggleDropdown}>
// //                                 More
// //                                 <img src={arrow} alt="" width="17px" className="mt-1 ms-1" />
// //                             </span>
// //                             {showDropdown ? <ul className="position-absolute bg-white dropdown_custom_menu" aria-labelledby="dropdownMenuButton1" ref={ref}>
// //                                 {moreData.map((val, index) => {
// //                                     return <li><a className={`dropdown-item py-2 px-3 text-secondary font_basic ${Number(categoryId) === val.id ? 'dropdown_active_border' : "border-bottom"}`} href="#" onClick={() => { setCategoryId(val.id) }}>{val.category_name}</a></li>
// //                                 })}
// //                             </ul> : null}
// //                         </div>
// //                     :null
// //                     }

// //                 </ul>
               
// //             </div>
// //             <div className="col-0 col-lg-1 bg-white"></div>
// //         </div>
// //     </>
// // }

// // export default CategoryHeaderList

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import Pagination from "@mui/material/Pagination";
// import "./categoryHeaderList.css";
// import UseOutsideClick from "../detectOutsideClick/useOutSideClick";

// const CategoryHeaderList = ({ categoryHeaderData, moreData, categoryId, setCategoryId }) => {
//   const itemsPerPage = 5; // Number of category headers to display per page
//   const [currentPage, setCurrentPage] = useState(1);

//   const handlePageChange = (event, page) => {
//     setCurrentPage(page);
//   };

//   const startIndex = (currentPage - 1) * itemsPerPage;
//   const endIndex = startIndex + itemsPerPage;
//   const displayedCategoryHeaders = categoryHeaderData.slice(startIndex, endIndex);

//   return (
//     <>
//       <div className="category_header row m-0 border-bottom d-lg-flex">
//         <div className="col-1 col-lg-1 bg-white">
//           {currentPage > 1 && (
//             <button onClick={() => setCurrentPage(currentPage - 1)}>
//                 <span className="pagination-symbol">&lt;</span>
//             </button>
//           )}
//         </div>
//         <div className="col-10 col-lg-10 pt-3 pb-1 justify-content-center bg-white">
//           <ul className="list-unstyled d-flex flex-wrap m-0 py-1">
//             {displayedCategoryHeaders.map((data, index) => {
//               return (
//                 <li
//                   className="list-item"
//                   onClick={() => {
//                     setCategoryId(data.category_slug);
//                   }}
//                   key={index}
//                 >
//                   <Link
//                     to={`/product-category/${data.category_slug}/:id`}
//                     className={`header_item_link text-decoration-none fs-6 mx-3 py-2 font_basic text-secondary ${
//                       categoryId === data.category_slug ? "active_div" : ""
//                     }`}
//                   >
//                     {data.category_name ? data.category_name : null}
//                   </Link>
//                 </li>
//               );
//             })}
//           </ul>
//         </div>
//         <div className="col-1 col-lg-1 bg-white">
//           {endIndex < categoryHeaderData.length && (
//             <button onClick={() => setCurrentPage(currentPage + 1)}>
//                 <span className="pagination-symbol">&gt;</span>
//             </button>
//           )}
//         </div>
//       </div>
//       <Pagination
//         count={Math.ceil(categoryHeaderData.length / itemsPerPage)}
//         page={currentPage}
//         onChange={handlePageChange}
//       />
//     </>
//   );
// };

// export default CategoryHeaderList;

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import Pagination from "@mui/material/Pagination";
// import "./categoryHeaderList.css";
// import useMediaQuery from "@mui/material/useMediaQuery";

// import productImage from "../../../assests/product_img.png";

// function formatCategoryName(text) {
//   if (text.includes("&")) {
//     return text
//       .split("&")
//       .map((part) =>
//         part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
//       )
//       .join("&");
//   } else {
//     return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
//   }
// }

// const CategoryHeaderList = ({ categoryHeaderData, categoryId, setCategoryId }) => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//   useEffect(() => {
//     const urlSearchParams = new URLSearchParams(window.location.search);
//     const pageParam = urlSearchParams.get("page");

//     if (pageParam) {
//       setCurrentPage(parseInt(pageParam, 10));
//     }

//     const handleResize = () => {
//       setWindowWidth(window.innerWidth);
//     };

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const handlePageChange = (event, page) => {
//     setCurrentPage(page);
//   };

//   const updateURL = (category, page) => {
//     const searchParams = new URLSearchParams();
//     searchParams.set("page", page.toString());
//     searchParams.set("category", category);
//     window.history.replaceState({}, "", `${window.location.pathname}?${searchParams.toString()}`);
//   };

//   const calculateItemsPerPage = (page, windowWidth) => {
//     if (windowWidth >= 900) {
//       if (page === 1) return 4;
//       if (page === 2 || page === 3) return 5;
//       if (page === 4) return 1;
//     } else if (windowWidth >= 600) {
//       if (page === 1) return 3;
//       if (page === 2 || page === 3) return 3;
//       if (page === 4) return 4;
//     } else {
//       if (page === 1) return 3;
//       if (page === 2 || page === 3) return 3;
//       if (page === 4) return 3;
//       if (page === 5) return 3;
//       if (page === 6) return 3;
//       if (page === 7) return 3;
//     }
//   };

//   const startIndex = (currentPage - 1) * calculateItemsPerPage(currentPage, windowWidth);
//   const endIndex = startIndex + calculateItemsPerPage(currentPage, windowWidth);
//   const displayedCategoryHeaders = categoryHeaderData.slice(startIndex, endIndex);

//   return (
//     <>
//       <div className="category_header row m-0 border-bottom d-lg-flex">
//         <div className="button-wrapper">
//           <div className="col-2 col-lg-1 bg-white btdiv">
//             {currentPage > 1 && (
//               <button
//                 onClick={() => {
//                   setCurrentPage(currentPage - 1);
//                   updateURL(categoryId, currentPage - 1);
//                 }}
//                 className="custom-button prev"
//               />
//             )}
//           </div>
//           <div className="col-8 col-lg-10 pt-3 pb-1 justify-content-center bg-white">
//             <ul className="list-unstyled d-flex flex-wrap m-0 py-1">
//               {displayedCategoryHeaders.map((data, index) => {
//                 return (
//                   <li
//                     className="list-item"
//                     onClick={() => {
//                       setCategoryId(data.category_slug);
//                     }}
//                     key={index}
//                   >
//                     <div className="category-item">
//                       <img
//                         src={data.icon ? data.icon : productImage}
//                         alt="product_image"
//                         className="category-image"
//                       />
//                       <Link
//                         to={`/product-category/${data.category_slug}/:id?page=${currentPage}`}
//                         className={`header_item_link text-decoration-none fs-6 mx-3 py-2 font_basic text-secondary ${
//                           categoryId === data.category_slug ? "active_div" : ""
//                         }`}
//                       >
//                         {data.category_name ? formatCategoryName(data.category_name) : null}
//                       </Link>
//                     </div>
//                   </li>
//                 );
//               })}
//             </ul>
//           </div>
//           <div className="col-2 col-lg-1 bg-white">
//             {endIndex < categoryHeaderData.length && (
//               <button
//                 onClick={() => {
//                   setCurrentPage(currentPage + 1);
//                   updateURL(categoryId, currentPage + 1);
//                 }}
//                 className="custom-button next"
//               ></button>
//             )}
//           </div>
//         </div>
//       </div>
//       <Pagination
//         count={Math.ceil(categoryHeaderData.length / calculateItemsPerPage(currentPage, windowWidth))}
//         page={currentPage}
//         onChange={handlePageChange}
//       />
//     </>
//   );
// };
// export default CategoryHeaderList;
// import React from "react";
// import { Link } from "react-router-dom";
// import { ScrollMenu } from "react-horizontal-scrolling-menu";
// import "./categoryHeaderList.css";

// function formatCategoryName(text) {
//   if (text.includes("&")) {
//     return text
//       .split("&")
//       .map((part) =>
//         part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
//       )
//       .join("&");
//   } else {
//     return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
//   }
// }

// const CategoryHeaderList = ({ categoryHeaderData, categoryId, setCategoryId }) => {
//   console.log("categoryHeaderData:", categoryHeaderData);

//   const MenuItem = ({ data }) => {
//     console.log("data:" , data)
//     return (
//       <div
//         className={`menu-item`}
//         onClick={() => setCategoryId(data.category_slug)}
//       >
//         <img
//           src={data.icon ? data.icon : ""}
//           alt="category_image"
//           className="category-image"
//         />
//         <Link
//           to={`/product-category/${data.category_slug}/:id`}
//           className={`header_item_link text-decoration-none fs-6 mx-3 py-2 font_basic text-secondary ${
//             categoryId === data.category_slug ? "active_div" : ""
//           }`}
//         >
//           {data.category_name ? formatCategoryName(data.category_name) : null}
//         </Link>
//       </div>
//     );
//   };

//   return (
//     <div className="category_header row m-0 border-bottom d-lg-flex">
//       <div className="col-0 col-lg-1 bg-white"></div>
//       <div className="col-12 col-lg-10 pt-3 pb-1 d-flex justify-content-center bg-white">
//         <div className="scroll-menu">
//           <ScrollMenu
//             data={categoryHeaderData}
//             selected={categoryId}
//             alignCenter={false}
//             Item={MenuItem}
//           />
//         </div>
//       </div>
//       <div className="col-0 col-lg-1 bg-white"></div>
//     </div>
//   );
// };
// export default CategoryHeaderList;
import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./categoryHeaderList.css";

function formatCategoryName(text) {
  if (text.includes("&")) {
    return text
      .split("&")
      .map((part) =>
        part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
      )
      .join("&");
  } else {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }
}

const CategoryHeaderList = ({ categoryHeaderData, categoryId, setCategoryId,  }) => {
  console.log("categoryHeaderData:", categoryHeaderData);

  const settings = {
    className: "slider variable-width",
    // infinite: false, 
    speed: 500,
    slidesToShow: 5, 
    slidesToScroll: 2,
    
    responsive: [
      {
        breakpoint:300, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
       
        },
      },
      {
        breakpoint: 600, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
      
        },
      },
      {
        breakpoint: 900, 
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          
        },
      },
      {
        breakpoint: 1200, 
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          
        },
      },
    ],
   
  };
  return (
    <div className="category_header row m-0 border-bottom d-lg-flex">
      {/* <div className="col-0 col-lg-1 bg-white"></div> */}
      <div className=" col-md-10 col-lg-10 col-xl-12 pt-0 pb-0 d-flex  bg-white">
        <Slider {...settings}>
          {categoryHeaderData.map((data, index) => (
            
            <div className="menu-item-content"  >
              <div
              key={index}
                className={`menu-item`}
                onClick={() => setCategoryId(data.category_slug)}
                style={{ width: 1000 }}
              >
                 <img
              src={data.icon ? data.icon : ""}
              alt="category_image"
              className="category-image "
            />
               
                  <Link
                    to={`/product-category/${data.category_slug}/:id`}
                    className={`header_item_link text-decoration-none fs-6 mx-0 py-0 font_basic text-secondary ${
                      categoryId === data.category_slug ? "active_div" : ""
                    }`}
                    style={{ padding: "1px", fontSize: "0.4rem" ,margin: "0",
                    '@media only screen and (max-width: 600px)': {
                      marginLeft: "5px", 
                    },
                   }} 
                  >
                    {data.category_name ? formatCategoryName(data.category_name) : null}
                  </Link>
                </div>
              </div>
           
          ))}
        </Slider>
      </div>
       {/* <div className="col-0 col-lg-1 bg-white"></div>  */}
    </div>
  );

};

export default CategoryHeaderList;
