import React from "react";
import Header from "../shared-components/header/header"
import Footer from "../shared-components/footer/footer"
import { useTranslation } from "react-i18next";
const TermAndCondition = () => {
     
    const { t, i18n } = useTranslation();
    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};

    return <>
        <Header
            userDetails={userDetails}
        />
        <div className="row m-0 term_and_condition_page">
            <div className="col-0 col-md-1"></div>
            <div className="col-12 col-md-10">
                <div class="">
                    <p>
                        <strong>{t("tc")}</strong>
                    </p>
                    <p>
                    {t("tc1")} 
                    </p>
                    <p>
                        {t("tc2")}
                    </p>
                    <p>
                       {t("tc3")}
                    </p>
                    <p>
                        <strong>{t("tc4")}</strong>
                    </p>
                    <p>
                      {t("tc5")}
                        &nbsp;{t("tc6")}
                    </p>
                    <p>
                        <strong>{t("tc7")}</strong>
                    </p>
                    <p>
                        {t("tc8")}
                    </p>
                    <p>
                        <strong>{t("tc9")}</strong>
                    </p>
                    <p>
                       {t("tc10")}
                    </p>
                    <p>
                        <strong>{t("tc11")}</strong>
                    </p>
                    <p>
                        {t("tc12")}
                    </p>
                    <p>
                       {t("tc13")}
                    </p>
                    <p>
                        <strong> {t("tc14")}</strong>
                    </p>
                    <p>
                       {t("tc15")}
                    </p>
                    <p>
                        <strong>  {t("tc16")}</strong>
                    </p>
                    <p>
                    {t("tc17")} 
                    </p>
                    <p>
                    {t("tc18")} 
                    </p>
                    <p>
                        <strong>   {t("tc19")}</strong>
                    </p>
                    <p>
                    {t("tc20")} </p>
                    <ul>
                        <li>  {t("tc21")}</li>
                        <li>  {t("tc22")}</li>
                        <li>  {t("tc23")}</li>
                        <li> {t("tc24")}</li>
                        <li> {t("tc25")}</li>
                        <li> {t("tc26")}</li>
                        <li>{t("tc27")}</li>
                        <li>{t("tc28")}</li></ul>
                    <p>
                    {t("tc29")} 
                    </p>
                    <p>
                        <strong> {t("tc30")}</strong>
                    </p>
                    <p>
                    {t("tc31")} 
                    </p>
                    <p>
                        <strong> {t("tc32")}</strong>
                    </p>
                    <p>
                    {t("tc33")}
                    </p><ul>
                        <li> {t("tc34")}</li>
                        <li> {t("tc35")}</li>
                        <li > {t("tc36")}</li>
                        <li> {t("tc37")}</li>
                        <li> {t("tc38")}</li></ul>
                    <p>
                        <strong> {t("tc39")}</strong>
                    </p>
                    <p>
                    {t("tc40")} 
                    </p>
                    <p>
                        <strong> {t("tc41")}</strong>
                    </p>
                    <p>
                    {t("tc42")}
                    </p>
                    <p>
                        <strong> {t("tc43")}</strong>
                    </p>
                    <p>
                    {t("tc44")} 
                    </p>
                    <p>
                        <strong> {t("tc45")}</strong>
                    </p>
                    <p>
                    {t("tc46")}
                    </p>
                    <p>
                    {t("tc47")}
                    </p>
                    <p>
                    {t("tc48")} 
                    </p>
                    <p>
                        <strong> {t("tc49")}</strong>
                    </p>
                    <p>
                    {t("tc50")}
                    </p>
                    <p>
                    {t("tc51")} 
                    </p>
                    <p>
                    {t("tc52")}
                    </p>
                    <p>
                        <strong> {t("tc53")}</strong>
                    </p>
                    <p>
                    {t("tc54")} 
                    </p>
                    <p>
                        <strong> {t("tc55")}</strong>
                    </p>
                    <p>
                    {t("tc56p1")}
                        <br />
                        {t("tc56p2")}
                         <br /> {t("tc56p3")}<br />  {t("tc56p4")}<br /> {t("tc56p5")}<br />  {t("tc56p6")} {t("tc56p7")}  {t("tc56p8")} UNAUTHORIZED ACCESS TO OR 
                                {t("tc56p9")}
                                   {t("tc56p10")}{t("tc56p11")} <br />
                                    {t("tc56p12")}  
                    </p>
                    <p>
                        <strong> {t("tc57")}</strong>
                    </p>
                    <p>
                    {t("tc58")} 
                    </p>
                    <p>
                    {t("tc59")} 
                    </p>
                    <p>
                    {t("tc60")}
                    </p>
                    <p>
                    {t("tc61")}
                    </p>
                    <p>
                        <strong>  {t("tc62")}</strong>
                    </p>
                    <p>
                    {t("tc63")}
                    </p>
                    <p>
                    {t("tc64")} 
                    </p>
                    <p>
                    {t("tc65")} 
                    </p>
                    <p>
                    {t("tc66")} 
                    </p>
                    <p>
                    {t("tc67")}  &nbsp;
                    </p>
                    <p>
                        <strong>  {t("tc68")}</strong>
                    </p>
                    <p>
                    {t("tc69")}</p>
                    <p>
                        <strong>  {t("tc70")}</strong>
                    </p>
                    <p>
                    {t("tc71")}
                    </p>
                    <p>
                        <strong> {t("tc72")}</strong>
                    </p>
                    <p>
                    {t("tc73")} 
                    </p>
                    <p>
                        <strong> {t("tc74")}</strong>
                    </p>
                    <p>
                    {t("tc75")}
                    </p><p>&nbsp;
                    </p><p><strong> {t("tc76")}</strong>
                    </p><p> {t("tc77")}
                    </p><ul>
                        <li> {t("tc78")}</li>
                        <li> {t("tc79")}</li>
                        <li> {t("tc80")}</li></ul><p> .<br />
                    </p></div>
            </div>
            <div className="col-0 col-md-1"></div>
        </div>

        <Footer />

        <style jsx>
            {`
        
        .term_and_condition_page{
            margin-top: 120px !important;
        }

        `}
        </style>
    </>

}

export default TermAndCondition;