import React,{useEffect, useState} from "react";
import {
  socialList
} from "../../../utils/apiUrlsSetup";
import "../../../components/footerAllCategoryDetails/footerAllCategoryDetails.css";
import { useTranslation } from "react-i18next";
const FooterBottomContent = () =>{
  const { t, i18n } = useTranslation();
  const authToken = localStorage.getItem("authToken") || '' ;
  const pincode = localStorage.getItem("pincode") || '' ;
  const [socialLink, setSocialLinkData] = useState([])
  const [appLink, setAppLinkData] = useState([])
  const getSocialLinkList = async () => {
  let response = await socialList({"pincode":pincode})
      if ( response && response.ErrorCode === 0 && response.Response!==null) {
          setSocialLinkData(response.Response.social_media)
          setAppLinkData(response.Response.app_links)
      }
  }

  useEffect(()=>{
    getSocialLinkList()
  },[])
return (
        <>
        <div className="row m-0 footer_bottom_parent">
        <div className="col-0 col-lg-1 px-0 footer_bottom_content_alignment_width"></div>
        <div className="col-12 col-lg-10 ps-lg-0 bottom_center_div">
        <div className="row m-0 pb-2 pb-md-0 d-flex align-items-center justify-content-between">
          <div className="font_family_common p-0 col-12 col-md-4 text-center text-md-start">
            © Angocart 2023-2024
          </div>
          <div className="col-12 col-md-4 p-0">
            <div className="font_basic text-center my-2">
            {t("DownloadApp")}
            </div>
            <div className="d-flex justify-content-center mb-2">
              <div
                height="100%"
                width="100%"
                className="sc-hKMtZM bRIJNO DownloadAndFollowRow__DownloadAppImage-sc-ixdkfr-6 kMTqFF"
              >
                 
                <a href={appLink?.ios_app ? appLink.ios_app : "https://apps.apple.com/us/app/angocart/id1658541323?platform=iphone"} className="">
                <img
                  alt="App Store"
                  src="https://blinkit.com/d61019073b700ca49d22.png"
                  loading="lazy"
                  className="icon-image app-download-icons"
                />
                </a>
              </div>
              <div
                height="100%"
                width="100%"
                className="sc-hKMtZM bRIJNO DownloadAndFollowRow__DownloadAppImage-sc-ixdkfr-6 kMTqFF"
              >
                <a href={appLink?.android_app ? appLink.android_app : "https://play.google.com/store/apps/details?id=com.ango.angocart&pli=1"} className="">
                 
                <img
                  alt="Play Store"
                  src="https://blinkit.com/8ed033800ea38f24c4f0.png"
                  loading="lazy"
                  className="icon-image app-download-icons"
                />
                </a>
              </div>
            </div>
          </div>
          <div className="d-flex p-0 col-12 col-md-4 justify-content-center justify-content-md-end mb-1">
            <div className=" d-flex justify-content-md-end">
              <div className="DownloadAndFollowRow__Item-sc-ixdkfr-3 iSSvsj">

                <ul type="col" className="social_links" >
                    {socialLink.map((link, index) => {
                        return <li className="FooterLinks__ListItem-sc-12rhzht-3 BvTiN font_paragraph" key={index}>
                            <a
                                href={link.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="FooterLinkshelpers__FooterLink-sc-f4rm2u-0 cmyjwV"
                            >
                              <img
                                    src={link.icon ? link.icon : ""}
                                    alt="product"
                                    loading="lazy"
                                    className="social-icon"
                                    />
                            </a>

                            
                        </li>
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="col-0 col-lg-1 pe-lg-0 footer_bottom_content_alignment_width"></div>
      </div>
        </>
    )
}

export default FooterBottomContent ;