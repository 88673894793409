
import "./commonModal.css"
import React, { useState, useRef, useEffect } from "react";
import PhoneIcon from "../../../assests/phone_icon.png"
import { submitOtpForLogin ,
        registerUser,
        userProfile,
        } from "../../../utils/apiUrlsSetup"
import { toast } from "react-toastify";
import Loader from "../loader/loader";
import UseOutsideClick from "../detectOutsideClick/useOutSideClick"
import { useTranslation } from "react-i18next";
const CommonModal = (props) => {
    const { t, i18n } = useTranslation();
    const authToken = localStorage.getItem("authToken") || "" ;
    const mobileNum = localStorage.getItem("loginNumber") || "" ;

    const ref = useRef()

    const [isDisable, setIsDisable] = useState(true)
    const [loginNumber, setLoginNumber] = useState('')
    const [showResgisterModal, setShowResgisterModal] = useState(false)
    const [otpValue, setOtpValue] = useState([]);
    const [registrationData, setRegistrationData] = useState({
        doctor_name :'',
        clinic_name :'',
        phone : mobileNum,
        gstin :'',
        address :'',
        pincode :'',
        landmark :'',
        email :'',
    })

    const getUserProfileData = async(token)=>{
        const response = await userProfile(token)
        if(response.ErrorCode === 0){
            localStorage.setItem("userDetails", JSON.stringify(response.Response))
            window.location.reload()
        }
    }

    const setNextButton = (e) => {
        setLoginNumber(e.target.value)

        if (e.target.value.length > 9) {
            const number = e.target.value.slice(0, 9);
            setLoginNumber(number)
        }

        if ((e.target.value).length >= 9) {
            setIsDisable(false)
        }
        else {
            setIsDisable(true)
        }
    }

    const gotoOtpVerification = () => {
        props.getOtp(loginNumber)
        setIsDisable(true)
    }

    const setOtpFunc = (e, index) => {
        let arr = [...otpValue];
        arr[index] = e.target.value;

        if(index > 0 && arr[index] === "" && e.keyCode == 8 ){
            document.getElementById(`otpValue${index - 1}`).select()
        }
        else{

            if(arr[index] !== "" && index < 3){
                document.getElementById(`otpValue${index + 1}`).select()
            }
            else if(arr[index] === "" && index > 0){
                    document.getElementById(`otpValue${index - 1}`).select()
            }
        }
        


        setOtpValue(arr);

    }

    const submitOtp = async () => {
        const obj = {
            otp: Number(otpValue.join("")),
            phone: loginNumber
        }

        const data = await submitOtpForLogin(obj)
        
        if(data.ErrorCode === 0 || data.ErrorCode === 101) {
             toast.success("OTP verified")
             localStorage.setItem("loginNumber", loginNumber)
             localStorage.setItem("authToken", data.token)
             if(data.token){
                 props.setShowLoginModal(false)
                 props.setIsOtpReceived(false);
                 props.setIsUserLogin(true)
                 getUserProfileData(data.token)
                }
                else{
                    setShowResgisterModal(true)
             }
         }
        else if(data.ErrorCode === -106) {
            toast.error(data.ErrorMessage)
        }
    }

    const handleRegistrationData = (name , value )=>{
        const copyRegistrationData = {...registrationData}
        copyRegistrationData[name] = value
        setRegistrationData(copyRegistrationData)
    }

    const handleRegistration = async(e) =>{
        e?.preventDefault();
        const formData = new FormData()
        formData.append("doctor_name", registrationData.doctor_name )
        formData.append("clinic_name",registrationData.clinic_name )
        formData.append("phone", mobileNum)
        formData.append("gstin", registrationData.gstin)
        formData.append("registration_no", registrationData.registration_no)
        formData.append("address", registrationData.address)
        formData.append("pincode", registrationData.pincode)
        formData.append("landmark", registrationData.landmark)
        formData.append("email", registrationData.email)

        const response = await registerUser(formData)
        if(response.ErrorCode === -500){
            toast.error(response.ErrorMessage)
        }
        else if(response.ErrorCode === 0){
            toast.success("Registered successfully")
            localStorage.setItem("authToken", response.token)
            props.setShowLoginModal(false)
            if(response.token){
                getUserProfileData(response.token)
            }
        }
    }

    return (
        <>
            <div className={`modal fade login_register_modal ${props.showLoginModal ? 'show' : ''}`} style={{ display: props.showLoginModal ? 'block' : 'none', background: props.showLoginModal ? 'rgba(0,0,0,0.5)' : '' }} id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0 py-2">
                            {props.isOtpReceived ?
                             <button type="button" 
                                className="back_btn text-decoration-underline text-success font_13"
                                onClick={()=>{props.setIsOtpReceived(false)}}
                                >
                             {'< Back'}
                             </button> 
                             : null}
                            <h5 className="common_modal_title m-0 font_basic">{showResgisterModal ? "Register" :`${t("Number")}`}</h5>
                            <button type="button" className="close" onClick={() => {
                                props.setIsOtpReceived(false);
                                props.setShowLoginModal(false);
                            }}>
                                <span aria-hidden="true" className='fs-3'>&times;</span>
                            </button>
                        </div>
                        <form onSubmit={handleRegistration} className="modal-body common_modal_body py-2 p-md-4 d-flex flex-column align-items-center justify-content-center pb-3 pb-md-5">
                            {showResgisterModal ? 
                                <div className="register_modal_body">
                                    <div className="mb-md-2">
                                        <label htmlFor="#" className="form-label font_13 text-secondary mb-1 required">Name</label>
                                        <input required type="text" className="form-control font_paragraph" placeholder="" name="doctor_name" onChange={(e)=>{handleRegistrationData("doctor_name", e.target.value)}} />
                                    </div>
                                    {/* <div className="mb-md-2">
                                        <label  htmlFor="#" className="form-label font_13 text-secondary mb-1 required">Clinic Name</label>
                                        <input required type="text" className="form-control font_paragraph" placeholder="" name="clinic_name" onChange={(e)=>{handleRegistrationData("clinic_name", e.target.value)}} />
                                    </div> */}
                                    <div className="mb-md-2">
                                        <label htmlFor="#" className="form-label font_13 text-secondary mb-1 required">Mobile Number</label>
                                        <input required type="number" className="form-control font_paragraph" placeholder={"+244- "+loginNumber} name="phone" disabled />
                                    </div>
                                    <div className="mb-md-2">
                                        <label htmlFor="#" className="form-label font_13 text-secondary mb-1 required">Email</label>
                                        <input required type="email" className="form-control font_paragraph" placeholder="" name="email" onChange={(e)=>{handleRegistrationData("email", e.target.value)}} />
                                    </div>
                                    {/* <div className="mb-md-2">
                                        <label htmlFor="#" className="form-label font_13 text-secondary mb-1 ">Dental Council Registraion Number (DNC Number)</label>
                                        <input type="text" className="form-control font_paragraph" placeholder="" name="registration_no" onChange={(e)=>{handleRegistrationData("registration_no", e.target.value)}} />
                                    </div> */}
                                    <div className="mb-md-2">
                                        <label htmlFor="#" className="form-label font_13 text-secondary mb-1 required">Address</label>
                                        <input required type="text" className="form-control font_paragraph" placeholder="" name="address" onChange={(e)=>{handleRegistrationData("address", e.target.value)}} />
                                    </div>
                                    <div className="mb-md-2">
                                        <label htmlFor="#" className="form-label font_13 text-secondary mb-1 required">Pincode</label>
                                        <input required type="number" className="form-control font_paragraph" placeholder="" name="pincode" onChange={(e)=>{handleRegistrationData("pincode", e.target.value)}} />
                                    </div>
                                    <div className="mb-md-2">
                                        <label htmlFor="#" className="form-label font_13 text-secondary mb-1 ">GSTIN</label>
                                        <input type="text" className="form-control font_paragraph" placeholder="" name="gstin" onChange={(e)=>{handleRegistrationData("gstin", e.target.value)}} />
                                    </div>
                                    <div className="mb-md-2">
                                        <label htmlFor="#" className="form-label font_13 text-secondary mb-1 required">Landmark</label>
                                        <input required type="text" className="form-control font_paragraph" placeholder="" name="landmark" onChange={(e)=>{handleRegistrationData("landmark", e.target.value)}} />
                                    </div>
                                    <button
                                        type="submite"
                                        className={`text-white px-4 py-2 rounded border mt-3 next_btn_color next_btn_hover_effect font_basic"}`}
                                    >
                                        <span className="me-2">Register</span>
                                    </button>
                                </div>
                                
                                : <>

                            <div className="p-0 flex justify-content-center mt-4 mb-3">
                                <p className="m-0 mx-md-5 px-md-5 font_basic">
                                    {props.isOtpReceived ?
                                        `${t("otp")} +244-${loginNumber}`
                                        :
                                        `${t("Enterlogin")}`}
                                </p>
                            </div>
                            {props.isOtpReceived ?
                                <form className="d-flex otp_form mb-3">
                                    <input 
                                        type="tel" 
                                        maxlength="1" 
                                        id="otpValue0" 
                                        className="otp_input input form-control mx-1 text-center font_basic" 
                                        onChange={(e) => setOtpFunc(e, 0)}
                                        // onKeyDown={(e) => setOtpFunc(e, 0)}      
                                            
                                    />
                                    <input 
                                        type="tel" 
                                        maxlength="1" 
                                        id="otpValue1" 
                                        className="otp_input input form-control mx-1 text-center font_basic" 
                                        onChange={(e) => setOtpFunc(e, 1)} 
                                        // onKeyDown={(e) => setOtpFunc(e, 1)}  
                                            
                                    />
                                    <input 
                                        type="tel" 
                                        maxlength="1" 
                                        id="otpValue2" 
                                        className="otp_input input form-control mx-1 text-center font_basic" 
                                        onChange={(e) => setOtpFunc(e, 2)}
                                        // onKeyDown={(e) => setOtpFunc(e, 2)}   
                                            
                                    />
                                    <input 
                                        type="tel" 
                                        maxlength="1" 
                                        id="otpValue3" 
                                        className="otp_input input form-control mx-1 text-center font_basic" 
                                        onChange={(e) => setOtpFunc(e, 3)}  
                                        // onKeyDown={(e) => setOtpFunc(e, 3)} 
                                    />
                                </form>
                                :
                                <div className="input-group common_modal_input my-3">
                                    <div className="">
                                        <span className="input-group-text h-10" id="basic-addon1">
                                            <img src={PhoneIcon} className="input_phone_icon" alt="" />
                                           <span className="font_basic"> +244{" "}-</span>
                                        </span>
                                    </div>
                                    <input
                                        type="number"
                                        className="form-control font_basic"
                                        aria-label="Username"
                                        value={loginNumber}
                                        maxlength={10}
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => { setNextButton(e) }}
                                    />
                                </div>}
                             {props.isOtpReceived && 
                             <div className="link d-flex justify-content-end mb-3" style={{width:'60%',marginTop:'-13px'}}
                                onClick={()=>{
                                props.getOtp(loginNumber)

                            }}
                            >{t("Resend")}</div>}
                            <button
                                type="button"
                                className={`next_btn font_basic border position-relative ${isDisable && otpValue.join("").length < 4 ? "bg_disable" : "next_btn_color next_btn_hover_effect"}`}
                                disabled={isDisable && otpValue.join("").length < 4}
                                onClick={props.isOtpReceived ? submitOtp : gotoOtpVerification}
                            >
                                <span className="me-2">{t("Next")}</span>
                            </button> </>}
                            <div className="d-flex mt-3 justify-content-center flex-wrap">
                                  {t("var1")}  &nbsp;<a target="_blank" className="link"
                                href="/pages/terms-conditions">{t("var2")}</a><span>&nbsp;{t("var3")}&nbsp;</span><a  target="_blank" className="link" href="/pages/privacy-policy">{t("var4")}</a>.
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CommonModal;